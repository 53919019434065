var i18n={
    en:{
        overtime_1:`Error code: `,
        overtime_2:`, plesase retry it`,
        isnetwork:`Internet error. Please check and retry`,
        email_placeholder:`Email`,
        email_empty:`Please input email`,
        email_not_valid:`Email is not valid`,
        email_not_valid_1:`Please input your email`,
        email_not_valid_2:`Email is not valid, please use a different address.`,
        email_not_valid_3:`No email input`,
        password_placeholder:`Password`,
        password_empty:`Please input password`,
        password_not_valid:`Incorrect account or password`,
        password_not_valid_1:`Password above 8 characters is required`,
        password_not_valid_2:`Please create a password`,
        password_placeholder_1:`Create your password`,
        password_placeholder_2:`Confirm your Password`,
        password_placeholder_3:`Create new password`,
        password_placeholder_4:`Confirm new password`,
        password_placeholder_5:`Input old password`,
        copy_password_empty:`Please confirm password`,
        copy_password_not_valid:`Please confirm your password`,
        copy_passwords_inconsistent:`Your password confirmation does not match`,
        code_empty:`Please input verification code`,
        code_not_valid:`Invalid verification code`,
        code_placeholder:`Verification Code`,
        not_received_code:`If your mailbox has not received the verification code for a long time, please get the verification code again.`,
        get_first_code:`Please get the verification code first.`,
        connect_your_email_placeholder:`Connect your email`,
        last_name_placeholder:`Please enter your last name`,
        first_name_placeholder:`Please enter your first name`,
        address_placeholder:`Please enter your address`,
        no_code_text:`We've sent a verification code. Please enter your code. <span class='tips'>Didn't receive a code?`,
        no_code_text_1:`1. Please make sure the email address is valid and it can receive emails.`,
        no_code_text_2:`2. For the email is sent by system automatically, it may be flagged as the spam or junk email. Please check whether the email is in the Trash folder.`,
        no_code_text_3:`3. Cannot solve your problem? `,
        no_code_text_3_span:`Then click here to contact us.`,
        order_no:`You have not purchased any product, <a href="https://www.eassiy.com/buy/background-remover.html">shop now</a>! If you have any question, please <a href="https://www.eassiy.com/support/">contact us</a>.`,
        error_24901:`Current account has no email linked, and cannot find the orders. Please connect an email.`,
        user_guide:`User Guide>>`,
        download:`Download`,
        order_number:`Order Number:`,
        Refund:`Refund`,
        Disabled:`Disabled`,
        Normal:`Normal`,
        Modify:`Modify`,
        Modify_1:`Modify>>`,
        Connect:`Connect`,
        unlink_success:`Unlink successfully`,
        connect_success:`Connect successfully`,
        feedback_title:`Thanks for your feedback. Please leave your problem and we will respond to you within 24 hours.`,
        feedback_thank_you:`Thank You!<br />Your Feedback has been submitted successfully.`,
        feedback_email:`Enter your email here!`,
        feedback_content:`Leave any problem or suggestion you encountered here.`,
        feedback_submit:`Submit`,
        form_contents:`You have not entered any description. Please enter it and submit again.`,
        old_password:`Please input old password`,
        new_password:`Please create a new password`,
        old_new_password:`The new password cannot be the same as the old one`,
        incorrect_password:`Incorrect password`,
        delete_no:`Delete Now`,
        Caps:`Caps lock is on`,
        Get:`Get`,
        Done:`Done`,
        error_20001:`Error: 20001. Please log in again.`,
        error_20002:`Error: 20002. Please log in again.`,
        error_20003:`Error: 20003. Please log in again.`,
        error_20004:`Request failed (error code: 20004). Please try it again.`,
        error_20005:`Login session has expired (Error: 20005). Please log in again.`,
        error_20006:`Request failed (error code: 20006). Please try it again.`,
        error_20007:`Login session has expired (Error: 20007). Please log in again.`,
        error_20008:`Login session has expired (Error: 20008). Please log in again.`,
        error_20009:`Login session has expired (Error: 20009). Please log in again.`,
        error_20101:`Please input your email (error code: 20101)`,
        error_20102:`Email is not valid (error code: 20102)`,
        error_20103:`Request failed (error code: 20103). Please try it again`,
        error_20104:`Email is already in use, please <a href="/login">log in</a> or register with a new one`,
        error_20105:`Request failed (error code: 20105). Please try it again`,
        error_20106:`Failed to send email, please retry it`,
        error_20201:`Please input your email (error code: 20201)`,
        error_20202:`Please input your password (error code: 20202)`,
        error_20203:`Please input verification code (error code: 20203)`,
        error_20204:`Email is not valid (error code: 20204)`,
        error_20205:`Password above 8 characters is required (error code: 20205)`,
        error_20206:`Request failed (error code: 20206). Please try it again`,
        error_20207:`Invalid verification code`,
        error_20208:`Request failed (error code: 20208). Please try it again`,
        error_20209:`Request failed (error code: 20209). Please try it again`,
        error_20301:`Please input your email (error code: 20301)`,
        error_20302:`Error: 20302. Please contact us`,
        error_20303:`Email is not valid (error code: 20303)`,
        error_20304:`Request failed (error code: 20304). Please try it again`,
        error_20305:`Account does not exist. Please re-enter or <a href="/register">Create it</a> first.`,
        error_20306:`No password yet. Use <a href="/passwordless-login">Passwordless login</a> or <a href="/create-password">set a password</a> and log in.`,
        error_20308:`Request failed (error code: 20308). Please try it again`,
        error_20401:`Failed to log out (error code: 20401). Please try it again`,
        error_20501:`Please input your email (error code: 20501)`,
        error_20502:`Email is not valid (error code: 20502)`,
        error_20503:`Request failed (error code: 20503). Please try it again`,
        error_20504:`Failed to send email. Please try it again.`,
        error_20601:`Please input your email (error code: 20601)`,
        error_20602:`Please input verification code (error code: 20602)`,
        error_20603:`Email is not valid (error code: 20603)`,
        error_20604:`Request failed (error code: 20604). Please try it again`,
        error_20606:`Request failed (error code: 20606). Please try it again`,
        error_20607:`Request failed (error code: 20607). Please try it again`,
        error_20608:`Request failed (error code: 20608). Please try it again`,
        error_20701:`Please input your email (error code: 20701)`,
        error_20702:`Email is not valid (error code: 20702)`,
        error_20703:`Request failed (error code: 20703). Please try it again`,
        error_20704:`Account does not exist. Please re-enter or <a href="/register">Create it</a> first.`,
        error_20705:`Request failed (error code: 20705). Please try it again`,
        error_20706:`Failed to send email. Please try it again`,
        error_20801:`Please input your email (error code: 20801)`,
        error_20802:`Error: 20802. Please contact us`,
        error_20803:`Please input verification code (error code: 20803)`,
        error_20804:`Email is not valid (error code: 20804)`,
        error_20805:`Password above 8 characters is required (error code: 20805)`,
        error_20806:`Request failed (error code: 20806). Please try it again`,
        error_20808:`Request failed (error code: 20808). Please try it again`,
        error_20901:`Request failed (error code: 20901). Please try it again`,
        error_20902:`Request failed (error code: 20902). Please try it again`,
        error_21000:`Changes are saved`,
        error_21001:`No information is submitted (error code: 21001)`,
        error_21002:`Request failed (error code: 21002). Please try it again`,
        error_21101:`Please input your email (error code: 21101)`,
        error_21102:`Email is not valid (error code: 21102)`,
        error_21103:`Request failed (error code: 21103), plesase retry it again`,
        error_21104:`Email is already connected, please use a new one`,
        error_21105:`Request failed (error code: 21105), plesase retry it again`,
        error_21106:`Failed to send email. Please try it again`,
        error_21201:`Please input your email (error code: 21201)`,
        error_21202:`Please input verification code (error code: 21202)`,
        error_21203:`Email is not valid (error code: 21203)`,
        error_21204:`Error: 21204. Please contact us`,
        error_21205:`Error: 21205. Please contact us`,
        error_21206:`Password above 8 characters is required (error code: 21206)`,
        error_21207:`Request failed (error code: 21207). Please try it again`,
        error_21209:`Request failed (error code: 21209). Please try it again`,
        error_21301:`Please input old password (error code: 21301)`,
        error_21302:`Please create a new password (error code: 21302)`,
        error_21303:`The new password cannot be the same as the old one. (Error: 21303)`,
        error_21304:`Password above 8 characters is required (error code: 21304)`,
        error_21306:`Request failed (error code: 21306). Please try it again`,
        error_21402:`Request failed (error code: 21402). Please try it again`,
        error_21403:`Failed to send verification code. Please resend it`,
        error_21500:`The account has been deleted`,
        error_21501:`Please input verification code (error code: 21501)`,
        error_21502:`Login session has expired (Error: 21502). Please log in again.`,
        error_21503:`Request failed (error code: 21503). Please try it again`,
        error_21505:`Request failed (error code: 21505), plesase retry it`,
        error_21601:`Error: 20601. Please contact us`,
        error_21602:`Invalid Verification (Error: 20602). Please try it again.`,
        error_21603:`Error: 20603. Please try it again`,
        error_21604:`Request failed (error code: 21604). Please try it again`,
        error_21606:`Request failed (error code: 21606). Please try it again`,
        error_21611:`Request failed (error code: 21611). Please try it again`,
        error_21801:`Error: 21801. Please contact us`,
        error_21802:`Request failed (Error: 21802). Please try it again`,
        error_21803:`Error: 21803. Please try it again`,
        error_21804:`Request failed (error code: 21804). Please try it again`,
        error_21806:`Error: 21806. Please try it again`,
        error_21807:`Error: 21807. Please contact us`,
        error_21808:`Error: 21808. Please contact us`,
        error_21809:`Error: 21809. Please contact us`,
        error_21810:`Error: 21810. Please contact us`,
        error_21811:`Error: 21811. Please contact us`,
        error_21812:`Error: 21812. Please contact us`,
        error_21813:`Request failed (error code: 21813). Please try it again`,
        error_21814:`Error: 21814. Please contact us`,
        error_21815:`Request failed (error code: 21815). Please try it again`,
        error_21816:`Error: 21816. Please contact us`,
        error_21817:`Error: 21817. Please contact us`,
        error_21818:`Error: 21818. Please contact us`,
        error_21819:`Request failed (error code: 21819). Please try it again`,
        error_21820:`Error: 21820. Please contact us`,
        error_21821:`Error: 21821. Please contact us`,
        error_21822:`Error: 21822. Please contact us`,
        error_21823:`Request failed (error code: 21823). Please try it again`,
        error_21824:`Request failed (error code: 21824). Please try it again`,
        error_21825:`Request failed (error code: 21825). Please try it again`,
        error_21826:`Request failed (error code: 21826). Please try it again`,
        error_21901:`Error: 21901. Please contact us`,
        error_21902:`Request failed (error code: 21902). Please try it again`,
        error_21903:`The account status has changed (error code: 21903), please refresh the page and try again`,
        error_21904:`Error: 21904. Please try it again`,
        error_21905:`Error: 21905. Please try it again`,
        error_21906:`Request failed (error code: 21906). Please try it again`,
        error_21907:`Google account has been linked to other account`,
        error_21908:`Request failed (error code: 21908). Please try it again`,
        error_22001:`Request failed (error code: 22001). Please try it again`,
        error_22002:`Unlink failed with no additional login`,
        error_22003:`Request failed (error code: 22003). Please try it again`,
        error_22101:`Error: 22101. Please contact us`,
        error_22102:`The account status has changed (error code: 22102), please refresh the page and try again`,
        error_22103:`Request failed (error code: 22103). Please try it again`,
        error_22104:`The account status has changed (error code: 22104), please refresh the page and try again`,
        error_22105:`Error: 22105. Please try it again`,
        error_22106:`Error: 22106. Please try it again`,
        error_22107:`Error: 22107. Please contact us`,
        error_22108:`Request failed (error code: 22108). Please try it again`,
        error_22201:`Error: 22201. Please contact us`,
        error_22202:`Invalid Verification (Error: 22202). Please try it again.`,
        error_22203:`Error: 22203. Please try it again"`,
        error_22204:`Request failed (error code: 22204). Please try it again`,
        error_22206:`Request failed (error code: 22206). Please try it again`,
        error_22401:`Error: 22401. Please contact us`,
        error_22402:`Invalid Verification (Error: 22402). Please try it again.`,
        error_22403:`Error: 22403. Please try it again`,
        error_22404:`Request failed (error code: 22404). Please try it again`,
        error_22405:`Facebook account has been linked to other email`,
        error_22406:`Error: 22406. Please try it again`,
        error_22407:`Error: 22407. Please contact us`,
        error_22408:`Error: 22408. Please contact us`,
        error_22409:`Error: 22409. Please contact us`,
        error_22410:`Error: 224010. Please contact us`,
        error_22411:`Error: 224011. Please contact us`,
        error_22412:`Error: 224012. Please contact us`,
        error_22413:`Error: 22413. Please contact us`,
        error_22414:`Request failed (error code: 22414). Please try it again`,
        error_22415:`Error: 22415. Please contact us`,
        error_22416:`Error: 22416. Please contact us`,
        error_22417:`Error: 22417. Please contact us`,
        error_22418:`Request failed (error code: 22418). Please try it again`,
        error_22419:`Error: 22419. Please contact us`,
        error_22420:`Error: 22420. Please contact us`,
        error_22421:`Error: 22421. Please contact us`,
        error_22422:`Request failed (error code: 22422). Please try it again`,
        error_22423:`Request failed (error code: 22423). Please try it again`,
        error_22424:`Request failed (error code: 22424). Please try it again`,
        error_22425:`Request failed (error code: 22425). Please try it again`,
        error_20098:`Error code: 20098. If you are browsing in Private mode, please switch to Normal mode and try again.`,
        error_22298:`Request for Google failed (Error: 22298). Please try it again.`,
        error_22498:`Request for Facebook failed (Error: 22498). Please try it again.`,
        error_24902:`Request failed (error code: 24902). Please try it again`,
        error_24903:`Request failed (error code: 24903). Please try it again`,
        error_24904:`Request failed (error code: 24904). Please try it again`,
        error_24905:`Request failed (error code: 24905). Please try it again`,
        login_title:`Log in to Aiseesoft`,
        password_placeholder:`Password`,
        log_in:`Log In`,
        no_account:`No account?`,
        create_it:`Create it`,
        or_log_in_with:`Or log in with`,
        passwordless_login:`Passwordless login`,
        log_in_done:`Log In Done`,
        three_rd_account_connect_info:`Congratulations! You have logged in successfully. Now you can connect one email account that is used to log in in the future.`,
        connect_now:`Connect now`,
        see_my_account:`See my account`,
        three_rd_login_merge_account:`The third-party account email address has been signed up, do you want to connect and log in with this email address directly?`,
        connect_log_in:`Connect & Log In`,
        create_an_account:`Create an account`,
        connect_account:`Connect Account`,
        connect_account_info:`After connecting, you can log in with this account.`,
        back_to_log_in:`Back to log in`,
        create_password:`Create Password`,
        create_now:`Create Now`,
        password_login_subtitle:`Passwordless login with email`,
        account_login:`Account Login`,
        rights:`By creating this account, you agree to <a href="https://www.eassiy.com/terms.html">Terms of Service</a> and <a href="https://www.eassiy.com/privacy.html">Privacy Policy</a>`,
        passwordless_login_done:`Passwordless login done`,
        passwordless_login_info:`Congratulations, you have completed passwordless login successfully. You can create a password for this account and log in with the account and password in the future. <a href="/create-password" style="display: initial;">Create Now</a>`,
        sign_up:`Sign Up`,
        register_info:`Create your account`,
        reset_now:`Reset Now`,
        forgot_password:`Forgot Password`,
        reset_password_subtitle:`Use your account email to reset password`,
        plan_products:`Plans & Products`,
        nick_name:`Username:`,
        email:`Email:`,
        my_products:`My Products`,
        my_orders:`My Orders`,
        unlink:`Unlink`,
        link:`Link`,
        connected_accounts:`Connected accounts`,
        last_name:`Last name:`,
        first_name:`First name:`,
        Gender:`Gender:`,
        Birth:`Birth:`,
        Month:`Month`,
        Year:`Year`,
        Country_Region:`Country/Region:`,
        Address:`Address:`,
        Save:`Save`,
        Date:`Date`,
        Male:`Male`,
        Female:`Female`,
        Unspecified:`Unspecified`,
        Security:`Security`,
        change_password:`Change Password`,
        change_now:`Change Now`,
        connect_email:`Connect email`,
        delete_account:`Delete Account`,
        delete_account_info:`When your account is deleted, all the data in Plan & Products associated with your account will be permanently deleted, and you might not be able to recover it. We recommend you tread warily.`,
        Delete:`Delete`,
        Logout:`Logout`,
        my_profile:`My Profile`,
        guides_faqs:`Guides & FAQs`,
        More:`More`,
        guides:`Guides`,
        register:`register`,
        hot_faq:`Hot FAQ`,
        Contents:`Contents:`,
        contact_us:`Contact us>>`,
        plan:`Plan`,
        unregistered:`Unregistered`,
        buy_more:`Purchase More`,
        buy_again:`Buy Again`,
        buy_now:`Buy Now`,
        free_no_limit:`Free & No limit`,
        expired:`Expired`,
        lifetime:`Lifetime`,
        remain:`Remain`,
        day_s:`Day(s)`,
        error_24801:`Request failed (error code: 24801). Please try it again`,
        no_app_found:`No app found！<a href="/">Refresh</a> or <a href="https://www.eassiy.com/">Go to the official website</a>`,
        get_more:`Get More >>`,
        edit_photo:`Edit Photo`,
        select_photo:`Select photo`,
        change_photo:`Change photo`,
        cancel:`Cancel`,
        hide_password:`Hide password`,
        show_password:`Show password`,
        zoom_in:`Zoom in`,
        zoom_out:`Zoom out`,
        rotate:`Rotate`,
        horizontal_flip:`Horizontal flip`,
        vertical_flip:`Vertical flip`,
        country:`Country`,
        country_1:`Select your country/region`,
        country_2:`Åaland Island`,
        country_3:`Afghanistan`,
        country_4:`Albania`,
        country_5:`Algeria`,
        country_6:`American Samoa`,
        country_7:`Andorra`,
        country_8:`Angola`,
        country_9:`Anguilla`,
        country_10:`Antarctica`,
        country_11:`Antigua & Barbuda`,
        country_12:`Argentina`,
        country_13:`Armenia`,
        country_14:`Aruba`,
        country_15:`Australia`,
        country_16:`Austria`,
        country_17:`Azerbaijan`,
        country_18:`Bahrain`,
        country_19:`Bangladesh`,
        country_20:`Barbados`,
        country_21:`Belarus`,
        country_22:`Belgium`,
        country_23:`Belize`,
        country_24:`Benin`,
        country_25:`Bermuda`,
        country_26:`Bhutan`,
        country_27:`Bolivia`,
        country_28:`Bosnia & Herzegovina`,
        country_29:`Botswana`,
        country_30:`Bouvet Island`,
        country_31:`Brazil`,
        country_32:`British Indian Ocean Territory`,
        country_33:`British Virgin Islands`,
        country_34:`Brunei`,
        country_35:`Bulgaria`,
        country_36:`Burkina`,
        country_37:`Burundi`,
        country_38:`Cambodia`,
        country_39:`Cameroon`,
        country_40:`Canada`,
        country_41:`Cape Verde`,
        country_42:`Caribbean Netherlands`,
        country_43:`Cayman Islands`,
        country_44:`Central African Republic`,
        country_45:`Chad`,
        country_46:`Chile`,
        country_47:`China`,
        country_48:`Christmas Island`,
        country_49:`Cocos (Keeling) Islands`,
        country_50:`Colombia`,
        country_51:`Cook Islands`,
        country_52:`Costa Rica`,
        country_53:`Côte d'Ivoire`,
        country_54:`Croatia`,
        country_55:`Cuba`,
        country_56:`Cyprus`,
        country_57:`Czech Republic`,
        country_58:`Democratic Republic of the Congo`,
        country_59:`Denmark`,
        country_60:`Djibouti`,
        country_61:`Dominica`,
        country_62:`Dominican Republic`,
        country_63:`Ecuador`,
        country_64:`Egypt`,
        country_65:`El Salvador`,
        country_66:`Equatorial Guinea`,
        country_67:`Eritrea`,
        country_68:`Estonia`,
        country_69:`Ethiopia`,
        country_70:`Falkland Islands`,
        country_71:`Faroe Islands`,
        country_72:`Federated States of Micronesia`,
        country_73:`Fiji`,
        country_74:`Finland`,
        country_75:`France`,
        country_76:`French Guiana`,
        country_77:`French polynesia`,
        country_78:`French Southern Territories`,
        country_79:`Gabon`,
        country_80:`Gambia`,
        country_81:`Georgia`,
        country_82:`Germany`,
        country_83:`Ghana`,
        country_84:`Gibraltar`,
        country_85:`Great Britain (United Kingdom; England)`,
        country_86:`Greece`,
        country_87:`Greenland`,
        country_88:`Grenada`,
        country_89:`Guadeloupe`,
        country_90:`Guam`,
        country_91:`Guatemala`,
        country_92:`Guernsey`,
        country_93:`Guinea`,
        country_94:`Guinea-Bissau`,
        country_95:`Guyana`,
        country_96:`Haiti`,
        country_97:`Heard Island and McDonald Islands`,
        country_98:`Honduras`,
        country_99:`Hong Kong`,
        country_100:`Hungary`,
        country_101:`Iceland`,
        country_102:`India`,
        country_103:`Indonesia`,
        country_104:`Iran`,
        country_105:`Iraq`,
        country_106:`Ireland`,
        country_107:`Isle of Man`,
        country_108:`Israel`,
        country_109:`Italy`,
        country_110:`Jamaica`,
        country_111:`Japan`,
        country_112:`Jersey`,
        country_113:`Jordan`,
        country_114:`Kazakhstan`,
        country_115:`Kenya`,
        country_116:`Kiribati`,
        country_117:`Kuwait`,
        country_118:`Kyrgyzstan`,
        country_119:`Laos`,
        country_120:`Latvia`,
        country_121:`Lebanon`,
        country_122:`Lesotho`,
        country_123:`Liberia`,
        country_124:`Libya`,
        country_125:`Liechtenstein`,
        country_126:`Lithuania`,
        country_127:`Luxembourg`,
        country_128:`Macao`,
        country_129:`Madagascar`,
        country_130:`Malawi`,
        country_131:`Malaysia`,
        country_132:`Maldives`,
        country_133:`Mali`,
        country_134:`Malta`,
        country_135:`Marshall islands`,
        country_136:`Martinique`,
        country_137:`Mauritania`,
        country_138:`Mauritius`,
        country_139:`Mayotte`,
        country_140:`Mexico`,
        country_141:`Moldova`,
        country_142:`Monaco`,
        country_143:`Mongolia`,
        country_144:`Montenegro`,
        country_145:`Montserrat`,
        country_146:`Morocco`,
        country_147:`Mozambique`,
        country_148:`Myanmar (Burma)`,
        country_149:`Namibia`,
        country_150:`Nauru`,
        country_151:`Nepal`,
        country_152:`Netherlands`,
        country_153:`New Caledonia`,
        country_154:`New Zealand`,
        country_155:`Nicaragua`,
        country_156:`Niger`,
        country_157:`Nigeria`,
        country_158:`Niue`,
        country_159:`Norfolk Island`,
        country_160:`North Korea`,
        country_161:`Northern Mariana Islands`,
        country_162:`Norway`,
        country_163:`Oman`,
        country_164:`Pakistan`,
        country_165:`Palau`,
        country_166:`Palestinian territories`,
        country_167:`Panama`,
        country_168:`Papua New Guinea`,
        country_169:`Paraguay`,
        country_170:`Peru`,
        country_171:`Pitcairn Islands`,
        country_172:`Poland`,
        country_173:`Portugal`,
        country_174:`Puerto Rico`,
        country_175:`Qatar`,
        country_176:`Republic of Macedonia (FYROM)`,
        country_177:`Republic of the Congo`,
        country_178:`Réunion`,
        country_179:`Romania`,
        country_180:`Russian Federation`,
        country_181:`Rwanda`,
        country_182:`Saint Barthélemy`,
        country_183:`Saint Martin (France)`,
        country_184:`Saint-Pierre and Miquelon`,
        country_185:`Samoa`,
        country_186:`San Marino`,
        country_187:`Sao Tome & Principe`,
        country_188:`Saudi Arabia`,
        country_189:`Senegal`,
        country_190:`Serbia`,
        country_191:`Seychelles`,
        country_192:`Sierra Leone`,
        country_193:`Singapore`,
        country_194:`Slovakia`,
        country_195:`Slovenia`,
        country_196:`Solomon Islands`,
        country_197:`Somalia`,
        country_198:`South Africa`,
        country_199:`South Georgia and the South Sandwich Isla`,
        country_200:`South Korea`,
        country_201:`South Sudan`,
        country_202:`Spain`,
        country_203:`Sri Lanka`,
        country_204:`St. Helena & Dependencies`,
        country_205:`St. Kitts & Nevis`,
        country_206:`St. Lucia`,
        country_207:`St. Vincent & the Grenadines`,
        country_208:`Sudan`,
        country_209:`Suriname`,
        country_210:`Swaziland`,
        country_211:`Sweden`,
        country_212:`Switzerland`,
        country_213:`Syria`,
        country_214:`Taiwan`,
        country_215:`Tajikistan`,
        country_216:`Tanzania`,
        country_217:`Template:Country data SJM Svalbard`,
        country_218:`Thailand`,
        country_219:`The Bahamas`,
        country_220:`The Comoros`,
        country_221:`The Philippines`,
        country_222:`Timor-Leste (East Timor)`,
        country_223:`Togo`,
        country_224:`Tokelau`,
        country_225:`Tonga`,
        country_226:`Trinidad & Tobago`,
        country_227:`Tunisia`,
        country_228:`Turkey`,
        country_229:`Turkmenistan`,
        country_230:`Turks & Caicos Islands`,
        country_231:`Tuvalu`,
        country_232:`Uganda`,
        country_233:`Ukraine`,
        country_234:`United Arab Emirates`,
        country_235:`United States Minor Outlying Islands`,
        country_236:`United States of America (USA)`,
        country_237:`United States Virgin Islands`,
        country_238:`Uruguay`,
        country_239:`Uzbekistan`,
        country_240:`Vanuatu`,
        country_241:`Vatican City (The Holy See)`,
        country_242:`Venezuela`,
        country_243:`Vietnam`,
        country_244:`Wallis and Futuna`,
        country_245:`Western Sahara`,
        country_246:`Yemen`,
        country_247:`Zambia`,
        country_248:`Zimbabwe`,
        google_login:`Sign in with Google`,
        State:`State`,
        Activation_code:`Activation code`,
        Question:`List all the apps you have logged in`,
        Copy_complete:`Copy complete`,
        footer:`Copyright © 2023 Aiseesoft Studio. All rights reserved.`,
        store:`Store`,
        resource:`Resource`,
        support:`support`,
        video_converter:`Video Converter`,
        multimedia:`Multimedia`,
        data_recovery:`Data Recovery`,
        utility:`Utility`,
        free_tools:`Free Tools`,
        for_windows:`For Windows`,
        for_mac:`For Mac`,
        pdf_solutions:`PDF Solutions`,
        data_manager:`Data Manager`,
        other_tools:`Other Tools`,
        free_online_solutions:`Free Online Solutions`,
        free_online_tools:`Free Online Tools`,
        desktop_freeware:`Desktop Freeware`,
        product_1:`Video Converter Ultimate`,
        product_2:`Total Video Converter`,
        product_3:`MTS Converter`,
        product_4:`4K Converter`,
        product_5:`MXF Converter`,
        product_6:`3D Converter`,
        product_7:`Mac Video Converter Ultimate`,
        product_8:`Video Converter for Mac`,
        product_9:`MTS Converter for Mac`,
        product_10:`4K Converter for Mac`,
        product_11:`MXF Converter for Mac`,
        product_12:`3D Converter for Mac`,
        product_13:`Blu-ray Player`,
        product_14:`Video Enhancer`,
        product_15:`Video Editor`,
        product_16:`Slideshow Creator`,
        product_17:`Blu-ray Creator`,
        product_18:`Burnova - DVD Creator Pro`,
        product_19:`DVD Creator`,
        product_20:`Mac Blu-ray Player`,
        product_21:`Mac Video Enhancer`,
        product_22:`Video Editor for Mac`,
        product_23:`DVD Creator for Mac`,
        product_24:`MP4 Converter for Mac`,
        product_25:`Windows Data Recovery`,
        product_26:`FoneLab iPhone Data Recovery`,
        product_27:`FoneLab iOS System Recovery`,
        product_28:`FoneLab iOS Data Backup &amp; Restore`,
        product_29:`FoneLab Android Data Recovery`,
        product_30:`Broken Android Data Extraction`,
        product_31:`Android Data Backup &amp; Restore`,
        product_32:`Mac Data Recovery`,
        product_33:`Mac FoneLab iPhone Data Recovery`,
        product_34:`Mac FoneLab iOS System Recovery`,
        product_35:`Mac FoneLab iOS Data Backup &amp; Restore`,
        product_36:`Mac FoneLab Android Data Recovery`,
        product_37:`Mac Android Data Backup &amp; Restore`,
        product_38:`PDF Converter Ultimate`,
        product_39:`PDF Converter Ultimate for Mac`,
        product_40:`PDF Merger`,
        product_41:`Mac PDF to ePub Converter`,
        product_42:`Mac Cleaner`,
        product_43:`FoneTrans`,
        product_44:`WhatsApp Transfer for iOS`,
        product_45:`MobieSync`,
        product_46:`FoneEraser`,
        product_47:`iPhone Cleaner`,
        product_48:`PPT to Video Converter`,
        product_49:`HEIC Converter`,
        product_50:`iPhone Unlocker`,
        product_51:`Free Video Converter Online`,
        product_52:`Free Audio Converter Online`,
        product_53:`Free Video Compressor Online`,
        product_54:`Free Video Merger Online`,
        product_55:`Free HEIC Converter`,
        product_56:`Free Image Compressor`,
        product_57:`Free Watermark Remover`,
        product_58:`Free PDF Compressor Online`,
        product_59:`Free Image Upscaler Online`,
        product_60:`Free Background Remover Online`,
        product_61:`Free Image Converter Online`,
        product_62:`Free PDF PNG Converter Online`,
        product_63:`Free Video Converter`,
        product_64:`Free Video to GIF Converter`,
        product_65:`Free Android Data Recovery`,
        product_66:`Slideshow Maker`,
        product_67:`Free Video Editor`,
        product_68:`Free PDF Viewer`,
        product_69:`Screen Recorder`,
        product_70:`Mac Screen Recorder`,
        product_71:`Free Screen Recorder Online`,
        product_72:`Free Audio Recorder Online`,

        product_73:`Phone Mirror`,
        product_74:`AnyCoord - Location Changer`,
        product_75:`iPhone Password Manager`,
        product_76:`Free Audio Compressor Online`,
        product_77:`Free Video Trimmer Online`,
        product_78:`Free Video Cropper Online`,
        product_79:`Free Video Rotator Online`,
        product_80:`Free Video Looper Online`,
        product_81:`Free PDF JPG Converter Online`,
        product_81:`Free JPG Word Converter Online`,

        category_1:`Photo Editing`,
        category_2:`Video Editing`,
        category_3:`Eraser`,
        category_4:`Media Recorder`,
        posted_by:`Posted by`,
        to:`to`,
        author_1:`Ashley Mae`,
        author_2:`Jenny Ryan`,
        author_3:`Nicole Jones`,
        author_4:`Amanda Brown`,
        article_1:`How to Remove Text from Pictures Efficiently`,
        article_2:`How to Increase Image Size in KB to MB without Damaging Quality`,
        article_3:`How to Remove Getty Images Watermark on Windows and Mac`,
        article_4:`The Best Method to Convert Low-resolution Image to High-resolution Online for Free`,
        article_5:`TikTok Guide | Here’s How to Make a Video Collage on TikTok`,
        article_6:`5 Easy Ways to Combine VOB Files You Should Know`,
        article_7:`How to Upscale Video and Get Higher Resolution Easily`,
        article_8:`How to Combine MP4 Files on MacBook Pro/iMac/MacBook and More`,
        article_9:`2 Simple Ways to Delete Purchase History on iPhone [Solved]`,
        article_10:`How to Delete Snapchat Pictures Permanently from Chat and Memories`,
        article_11:`How to Delete Spotlight Search Suggestions and History on iPhone/iPad`,
        article_12:`[Solved] How to Clear Instagram Cache on iPhone with 3 Easy Ways`,
        article_13:`[Solved] How to Fix QuickTime Player Not Working on Mac`,
        article_14:`How to Record System Audio on Mac (Big Sur/Catalina/Mojave …)`,
        article_15:`Effective Ways to Fix PowerPoint Screen Recording Not Working`,
        article_16:`How to Record Audio with VLC [Solved]`,
        faq_title_1:`1. How to fix 4104 error?`,
        faq_title_2:`2. When can I get my money back after the refund request is approved?`,
        faq_title_3:`3. What is "1 Year Subscription" and how to turn off the "Automatic Renewal Service"?`,
        faq_title_4:`4. Can I cancel my order after placing Aiseesoft product?`,
        faq_title_5:`5. How to submit a review to you?`,
        faq_info_1:`This is due to the limited License you got. We adopt Single License in case the registration code stolen and prevent the violation use of the registration code. So Reg Code you got is Single License which can only be used on one computer. If you use it on more than one computer, there will be Error 4104. You cannot use it until we reset it for you.`,
        faq_info_2:`If you change or reinstall your computer, we can reset it for you. Please contact the customer service: <a href="mailto:support@eassiy.com">support@eassiy.com</a> to request reset the registration code for you.`,
        faq_info_3:`Once your refund request is approved, normally it will take 3~7 business days for the credit to appear on your account. Sometimes during holidays, it may take longer but it is not expected to take over 10 days. If you have not got the refund 20 days after the approval, please contact us at <a href="mailto:support@eassiy.com">support@eassiy.com</a> to check the refund.`,
        faq_info_4:`Automatic Renewal Service means that if you buy a 1 Year Subscription, the payment platform will charge the amount automatically (including applicable taxes) when the product license expires. But the payment flatform (Swreg or 2Checkout) will send you a letter to remind you that the order is going to be expired 7 days before the expiration. The e-mail will also include instructions of how to turn off the "Automatic Renewal Service".`,
        faq_info_5:`Since the order is processed automatically, it is not allowed to cancel the order. Besides, the free trial version will enable you to know more about Aiseesoft program and check whether it can meet your needs before purchasing it.`,
        faq_info_6:`If you really want to get a refund, please check our <a href="https://www.eassiy.com/refund-policy.html">Refund Policy</a>.`,
        faq_info_7:`We appreciate your time and attention to submit a review to us. Contact us (<a href="mailto:support@eassiy.com">support@eassiy.com</a>) to submit your review and we will seriously consider your review for our product updates.`,
        change_password_success:`Password changed successfully`,
        successful_login_title:`Login Success`,
        product_page:`Product Page>>`,
        successful_login_info:`Login completed. Please close the current page and go back to the original tab to continue with the following process. The current page will be closed automatically in 5 seconds. If auto-closing or closing by clicking the "Done" button fails, please close this tab directly.`,
        successful_login_info_firefox:`Login completed. Please close the current page and go back to the original tab to continue with the following process.`,
        my_account:`My Account`,
        my_history:`My History`,
        remove_watermark:`Remove Watermark`,
        no_history:`No History`,
        history_all:`Select all`,
        history_open:`Open`,
        history_down:`Download`,
        history_delete:`Delete`,
        history_clear:`Clear invalid`,
        images:`image(s)`,
        use_this_function:`Use This Product>>`,
        hd_downloading:`HD original images downloading benefits:`,
        lifetimeRemaining:`Lifetime-Remaining`,
        Remaining:`Remaining`,
        email_verification:`Email Verification`,
        email_verification_info:`We have sent the verification message to your email <span class="email"></span>, and please finish the verification. After verifying, the benefits will be in sync automatically.`,
        wrong_email:`Wrong email address?`,
        click_here_to_modify:`Click here to modify`,
        get_more_help:`Get more help?`,
        click_here:`Click here`,
        email_verification_info_success:`Congratulations for verifying your email account.`,
        email_verification_info_error:`Verification failed because of the expired link.`,
        registration_succeeded:`Registration Succeeded`,
        registration_succeeded_info_1:`Congratulations! You have registered successfully. We have sent the verification message to your email <span class="email"></span>, and please finish verifying to use the benefits of this email.`,
        registration_succeeded_info_2:`Click "Done" to return to the Home page and use this product.`,
        registration_succeeded_info_3:`Click "Done" to close the current page and return to the Home page for the following operations. If closing fails, please manually close the tab.`,
        verify_email:`Verify Email`,
        registered_email_not_verified:`The registered email has not been verified, please verify it at once.`,
        email_verification_time_1:`Didn't receive a verification email?`,
        email_verification_time_2:`After`,
        email_verification_time_3:`Click here to resend it`,
        error_26301:`Error code: 26301, please contact us`,
        error_26302:`Error code: 26302, please contact us`,
        error_26303:`Email format error (error code: 26303). Please enter it again`,
        error_26304:`Password above 8 characters is recommended (error code: 26304)`,
        error_26305:`Reuqest failed (error code: 26305). Please retry it`,
        error_26306:`Email has been registered, please <a href="/login">go to log in</a>`,
        error_26307:`Reuqest failed (error code: 26307). Please retry it`,
        error_26308:`Reuqest failed (error code: 26308). Please retry it`,
        error_26401:`Error code: 26401, please retry it`,
        error_26402:`Email has been verified (error code: 26402), please retry it`,
        error_26403:`Reuqest failed (error code: 26403). Please retry it`,
        error_26404:`Request failed (error code: 26404). Please retry it`,
        error_26501:`Error code: 26501, please contact us`,
        error_26502:`Error code: 26502, please contact us`,
        error_26503:`Email format error (error code: 26503). Please enter it again`,
        error_26504:`Reuqest failed (error code: 26504). Please retry it`,
        error_26505:`Email has not been registered, please <a href="/register">register it first</a>`,
        error_26506:`Email has been verified.`,
        error_26507:`Reuqest failed (error code: 26507). Please retry it`,
        error_26508:`Verification fialed (error code: 26508), please retry it`,
        error_26509:`Request failed (error code: 26509), please retry it`,
        error_26510:`Error code: 26510, please contact us`,
        error_26601:`Error code: 26601, please contact us`,
        error_26602:`Error code: 26602, please contact us`,
        error_26603:`Reuqest failed (error code: 26603). Please retry it`,
        error_26604:`Error code: 26604, please contact us`,
        error_26605:`Error code: 26605, please contact us`,
        error_26701:`Error code: 26701, please contact us`,
        error_26702:`Reuqest failed (error code: 26702). Please retry it`,
        error_26703:`Error code: 26703, please contact us`,
        error_26704:`Error code: 26704, please contact us`,
        error_26705:`Wait for logging in (error code: 26705). Please retry it`,
        no_cookie:`You have turned on the Block all cookies function in your browser, so you cannot log in. Please go to Settings to check the box of Allow all cookies.`,
        error_26801:`Error code: 26801, please contact us`,
        error_26802:`Error code: 26802, please contact us`,
        error_26803:`Request failed (error code: 26803). Please retry it`,
        error_26804:`Request failed (error code: 26804). Please retry it`,
        error_order:`Request failed (error code: 27098), please try it again!`,
        error_order1:`The order query is incomplete（error code: `,
        error_order2:`）, please refresh and try it again.`,
        modify_email_title:`Modify Email`,
        modify_email_info:`You can use the modified email to log into your account.`,
        images_per:`Images per`,
        error_26101:`Error: 26101. Please contact us`,
        error_26102:`Error: 26102. Please contact us`,
        error_26103:`Request failed (error code:26103). Please try it again`,
        error_26104:`Error code: 26104, plesase retry it`,
        error_26105:`Error code: 26105, plesase retry it`,
        error_26106:`Delete failed (error code: 26106). Please try it again`,
        error_26201:`Error: 26201. Please contact us`,
        error_26202:`Request failed (error code:26202). Please try it again`,
        error_26001:`Error: 26001. Please contact us`,
        error_26002:`Error: 26002. Please contact us`,
        error_26003:`Error: 26003. Please contact us`,
        error_26004:`Error: 26004. Please contact us`,
        error_26005:`Request failed (error code:26005). Please try it again`,
        error_26006:`Error code: 26006, plesase retry it`,
        error_26008:`Error: 26008. Please contact us`,
        go_to_the_home_page:`Go to the Home page`,

        error_27101:`Request failed (error code: 27101). Please retry ist`,
        error_27201:`Error code: 27201, please contact us`,
        error_27202:`Error code: 27202, please retry it`,
        error_27203:`Request failed (error code: 27203). Please retry it`,
        error_27204:`Invalid code (error code: 27204).`,
        error_27205:`Request failed (error code: 27205). Please retry it`,
        error_27206:`Request failed (error code: 27206). Please retry it`,
        error_27207:`Request failed (error code: 27207). Please retry it`,
        no_history_found:`You haven't used any tool! <a href="/">Refresh</a> or <a href="https://www.eassiy.com/">Go to the official website</a>`,

        error_25301:`Error: 25301. Please contact us`,
        error_25302:`Error: 25302. Please contact us`,
        error_25303:`Request failed (error code: 25303). Please try it again`,
        error_25304:`Request failed (error code: 25304). Please try it again`,
        error_25305:`Request failed (error code: 25305). Please try it again`,
        error_25306:`Request failed (error code: 25306). Please try it again`,
        image_upscaler_p:`Image without watermark downloading benefits:`,
    },
    de:{
        overtime_1:`Fehlercode:`,
        overtime_2:`, bitte versuche es noch einmal`,
        isnetwork:`Internetfehler. Bitte überprüfen Sie und versuchen Sie erneut`,
        email_placeholder:`Email`,
        email_empty:`Bitte geben Sie Ihre E-Mail-Adresse ein`,
        email_not_valid:`Email ist ungültig`,
        email_not_valid_1:`Bitte geben Sie Ihre E-Mail-Adresse ein`,
        email_not_valid_2:`E-Mail ist ungültig, bitte verwenden Sie eine andere Adresse.`,
        email_not_valid_3:`Keine E-Mail-Eingabe`,
        password_placeholder:`Passwort`,
        password_empty:`Bitte geben Sie Ihr Passwort ein`,
        password_not_valid:`Falsches Konto oder Passwort`,
        password_not_valid_1:`Passwort mit mehr als 8 Zeichen ist erforderlich`,
        password_not_valid_2:`Bitte erstellen Sie ein Passwort`,
        password_placeholder_1:`Bitte erstellen Sie Ihr Passwort`,
        password_placeholder_2:`Bestätigen Sie Ihr Passwort`,
        password_placeholder_3:`Neues Passwort erstellen`,
        password_placeholder_4:`Bestätigen Sie neues Passwort`,
        password_placeholder_5:`Altes Passwort eingeben`,
        copy_password_empty:`Bitte bestätigen Sie das Passwort`,
        copy_password_not_valid:`Bitte bestätigen Sie Ihr Passwort`,
        copy_passwords_inconsistent:`Ihre Passwortbestätigung stimmt nicht überein`,
        code_empty:`Bitte geben Sie den Bestätigungscode ein`,
        code_not_valid:`Ungültiger Bestätigungscode`,
        code_placeholder:`Verifizierungs-Schlüssel`,
        not_received_code:`Wenn Ihr Postfach den Verifizierungscode längere Zeit nicht erhalten hat, holen Sie sich bitte den Verifizierungscode erneut.`,
        get_first_code:`Bitte holen Sie sich zuerst den Bestätigungscode.`,
        connect_your_email_placeholder:`Verbinden Sie Ihre E-Mail`,
        last_name_placeholder:`Bitte geben Sie Ihren Nachnamen ein`,
        first_name_placeholder:`Bitte geben Sie Ihren Vornamen ein`,
        address_placeholder:`Bitte geben Sie Ihre Adresse ein`,
        no_code_text:`Wir haben einen Bestätigungscode gesendet. Bitte geben Sie Ihren Code ein. <span class='tips'>Keinen Code erhalten?`,
        no_code_text_1:`1.Bitte stellen Sie sicher, dass die E-Mail-Adresse gültig ist und E-Mails empfangen kann.`,
        no_code_text_2:`2.Da die E-Mail automatisch vom System gesendet wird, kann sie als Spam- oder Junk-E-Mail gekennzeichnet werden. Bitte überprüfen Sie, ob sich die E-Mail im Papierkorb befindet.`,
        no_code_text_3:`3. Können Sie Ihr Problem nicht lösen?`,
        no_code_text_3_span:`Dann klicken Sie hier, um uns zu kontaktieren.`,
        order_no:`Sie haben keine Produkte gekauft. Bei Fragen <a href="https://www.eassiy.com/de/buy/background-remover.html">Jetzt einkaufen</a> Oder <a href="https://www.eassiy.com/de/support/"> Kontaktieren Sie uns</a>`,
        error_24901:`Das aktuelle Konto hat keine E-Mail-Verknüpfung und kann die Bestellungen nicht finden. Bitte verbinden Sie eine E-Mail.`,
        user_guide:`Benutzerhandbuch>>`,
        download:`Herunterladen`,
        order_number:`Bestellnummer`,
        Refund:`zurückerstattet`,
        Disabled:`Deaktiviert`,
        Normal:`Normal`,
        Modify:`Ändern`,
        Modify_1:`Ändern>>`,
        Connect:`Verbinden`,
        unlink_success:`Verknüpfung erfolgreich aufheben`,
        connect_success:`Erfolgreich verbinden`,
        feedback_title:`Vielen Dank für Ihr Feedback. Bitte notieren Sie Ihre Frage und wir werden uns innerhalb von 24 Stunden bei Ihnen melden.`,
        feedback_thank_you:`Vielen Dank!<br />Ihr Feedback wurde erfolgreich übermittelt.`,
        feedback_email:`Geben Sie Ihre E-Mail hier ein!`,
        feedback_content:`Schreiben Sie hier alle Probleme oder Vorschläge auf, auf die Sie stoßen.`,
        feedback_submit:`einreichen`,
        form_contents:`Sie haben keine Beschreibung eingegeben. Bitte geben Sie sie ein und senden Sie es erneut.`,
        old_password:`Bitte geben Sie altes Passwort ein`,
        new_password:`Bitte erstellen Sie ein neues Passwort`,
        old_new_password:`Das neue Passwort darf nicht mit dem alten identisch sein`,
        incorrect_password:`Falsches Passwort`,
        delete_no:`Jetzt löschen`,
        Caps:`Die Feststelltaste ist aktiviert`,
        Get:`Holen`,
        Done:`Fertig`,
        error_20001:`Fehler: 20001. Bitte melden Sie sich erneut an.`,
        error_20002:`Fehler: 20002. Bitte melden Sie sich erneut an.`,
        error_20003:`Fehler: 20003. Bitte melden Sie sich erneut an.`,
        error_20004:`Anfrage fehlgeschlagen (Fehlercode: 20004). Bitte versuchen Sie es erneut.`,
        error_20005:`Die Anmeldesitzung ist abgelaufen (Fehler: 20005). Bitte melden Sie sich erneut an.`,
        error_20006:`Anfrage fehlgeschlagen (Fehlercode: 20006). Bitte versuchen Sie es erneut.`,
        error_20007:`Die Anmeldesitzung ist abgelaufen (Fehler: 20007). Bitte melden Sie sich erneut an.`,
        error_20008:`Die Anmeldesitzung ist abgelaufen (Fehler: 20008). Bitte melden Sie sich erneut an.`,
        error_20009:`Die Anmeldesitzung ist abgelaufen (Fehler: 20009). Bitte melden Sie sich erneut an.`,
        error_20101:`Bitte geben Sie Ihre E-Mail ein (Fehlercode: 20101)`,
        error_20102:`E-Mail ist ungültig (Fehlercode: 20102)`,
        error_20103:`Anfrage fehlgeschlagen (Fehlercode: 20103). Bitte versuchen Sie es erneut`,
        error_20104:`E-Mail wird bereits verwendet, bitte <a href="/login">melden Sie sich an</a> oder registrieren Sie sich mit einer neuen`,
        error_20105:`Anfrage fehlgeschlagen (Fehlercode: 20105). Bitte versuchen Sie es erneut`,
        error_20106:`Fehler beim Senden der E-Mail, bitte versuchen Sie es erneut`,
        error_20201:`Bitte geben Sie Ihre E-Mail ein (Fehlercode: 20201)`,
        error_20202:`Bitte geben Sie Ihr Passwort ein (Fehlercode: 20202)`,
        error_20203:`Bitte geben Sie den Bestätigungscode ein (Fehlercode: 20203)`,
        error_20204:`E-Mail ist ungültig (Fehlercode: 20204)`,
        error_20205:`Passwort mit mehr als 8 Zeichen ist erforderlich (Fehlercode: 20205)`,
        error_20206:`Anfrage fehlgeschlagen (Fehlercode: 20206). Bitte versuchen Sie es erneut`,
        error_20207:`Ungültiger Bestätigungscode`,
        error_20208:`Anfrage fehlgeschlagen (Fehlercode: 20208). Bitte versuchen Sie es erneut`,
        error_20209:`Anfrage fehlgeschlagen (Fehlercode: 20209). Bitte versuchen Sie es erneut`,
        error_20301:`Bitte geben Sie Ihre E-Mail ein (Fehlercode: 20301)`,
        error_20302:`Fehler: 20302. Bitte kontaktieren Sie uns`,
        error_20303:`E-Mail ist ungültig (Fehlercode: 20303)`,
        error_20304:`Anfrage fehlgeschlagen (Fehlercode: 20304). Bitte versuchen Sie es erneut`,
        error_20305:`Konto existiert nicht. Bitte melden Sie sich erneut an oder <a href="/register">Erstellen Sie es</a> zuerst.`,
        error_20306:`Noch kein Passwort. Verwenden Sie <a href="/passwordless-login">Passwortlose Anmeldung</a> oder <a href="/create-password">Erstellen Sie ein Passwort</a> und melden Sie sich an.`,
        error_20308:`Anfrage fehlgeschlagen (Fehlercode: 20308). Bitte versuchen Sie es erneut`,
        error_20401:`Abmelden fehlgeschlagen (Fehlercode: 20401). Bitte versuchen Sie es erneut`,
        error_20501:`Bitte geben Sie Ihre E-Mail ein (Fehlercode: 20501)`,
        error_20502:`E-Mail ist ungültig (Fehlercode: 20502)`,
        error_20503:`Anfrage fehlgeschlagen (Fehlercode: 20503). Bitte versuchen Sie es erneut`,
        error_20504:`E-Mail konnte nicht gesendet werden. Bitte versuchen Sie es erneut.`,
        error_20601:`Bitte geben Sie Ihre E-Mail ein (Fehlercode: 20601)`,
        error_20602:`Bitte geben Sie den Bestätigungscode ein (Fehlercode: 20602)`,
        error_20603:`E-Mail ist ungültig (Fehlercode: 20603)`,
        error_20604:`Anfrage fehlgeschlagen (Fehlercode: 20604). Bitte versuchen Sie es erneut`,
        error_20606:`Anfrage fehlgeschlagen (Fehlercode: 20606). Bitte versuchen Sie es erneut`,
        error_20607:`Anfrage fehlgeschlagen (Fehlercode: 20607). Bitte versuchen Sie es erneut`,
        error_20608:`Anfrage fehlgeschlagen (Fehlercode: 20608). Bitte versuchen Sie es erneut`,
        error_20701:`Bitte geben Sie Ihre E-Mail ein (Fehlercode: 20701)`,
        error_20702:`E-Mail ist ungültig (Fehlercode: 20702)`,
        error_20703:`Anfrage fehlgeschlagen (Fehlercode: 20703). Bitte versuchen Sie es erneut`,
        error_20704:`Konto existiert nicht. Bitte melden Sie sich erneut an oder <a href="/register">Erstellen Sie es</a> zuerst.`,
        error_20705:`Anfrage fehlgeschlagen (Fehlercode: 20705). Bitte versuchen Sie es erneut`,
        error_20706:`E-Mail konnte nicht gesendet werden. Bitte versuchen Sie es erneut`,
        error_20801:`Bitte geben Sie Ihre E-Mail ein (Fehlercode: 20801)`,
        error_20802:`Fehler: 20802. Bitte kontaktieren Sie uns`,
        error_20803:`Bitte geben Sie den Bestätigungscode ein (Fehlercode: 20803)`,
        error_20804:`E-Mail ist ungültig (Fehlercode: 20804)`,
        error_20805:`Passwort mit mehr als 8 Zeichen ist erforderlich (Fehlercode: 20805)`,
        error_20806:`Anfrage fehlgeschlagen (Fehlercode: 20806). Bitte versuchen Sie es erneut`,
        error_20808:`Anfrage fehlgeschlagen (Fehlercode: 20808). Bitte versuchen Sie es erneut`,
        error_20901:`Anfrage fehlgeschlagen (Fehlercode: 20901). Bitte versuchen Sie es erneut`,
        error_20902:`Anfrage fehlgeschlagen (Fehlercode: 20902). Bitte versuchen Sie es erneut`,
        error_21000:`Änderungen werden gespeichert`,
        error_21001:`Es werden keine Informationen übermittelt (Fehlercode: 21001)`,
        error_21002:`Anfrage fehlgeschlagen (Fehlercode: 21002). Bitte versuchen Sie es erneut`,
        error_21101:`Bitte geben Sie Ihre E-Mail ein (Fehlercode: 21101)`,
        error_21102:`E-Mail ist ungültig (Fehlercode: 21102)`,
        error_21103:`Anfrage fehlgeschlagen (Fehlercode: 21103), bitte erneut versuchen`,
        error_21104:`E-Mail ist bereits verbunden, bitte verwenden Sie eine neue`,
        error_21105:`Anfrage fehlgeschlagen (Fehlercode: 21105), bitte erneut versuchen`,
        error_21106:`E-Mail konnte nicht gesendet werden. Bitte versuchen Sie es erneut`,
        error_21201:`Bitte geben Sie Ihre E-Mail ein (Fehlercode: 21201)`,
        error_21202:`Bitte geben Sie den Bestätigungscode ein (Fehlercode: 21202)`,
        error_21203:`E-Mail ist ungültig (Fehlercode: 21203)`,
        error_21204:`Fehler: 21204. Bitte kontaktieren Sie uns`,
        error_21205:`Fehler: 21205. Bitte kontaktieren Sie uns`,
        error_21206:`Passwort mit mehr als 8 Zeichen ist erforderlich (Fehlercode: 21206)`,
        error_21207:`Anfrage fehlgeschlagen (Fehlercode: 21207). Bitte versuchen Sie es erneut`,
        error_21209:`Anfrage fehlgeschlagen (Fehlercode: 21209). Bitte versuchen Sie es erneut`,
        error_21301:`Bitte geben Sie altes Passwort ein (Fehlercode: 21301)`,
        error_21302:`Bitte erstellen Sie ein neues Passwort (Fehlercode: 21302)`,
        error_21303:`Das neue Passwort darf nicht mit dem alten identisch sein. (Fehler: 21303)`,
        error_21304:`Passwort mit mehr als 8 Zeichen ist erforderlich (Fehlercode: 21304)`,
        error_21306:`Anfrage fehlgeschlagen (Fehlercode: 21306). Bitte versuchen Sie es erneut`,
        error_21402:`Anfrage fehlgeschlagen (Fehlercode: 21402). Bitte versuchen Sie es erneut`,
        error_21403:`Bestätigungscode konnte nicht gesendet werden. Bitte senden Sie es erneut`,
        error_21500:`Das Konto wurde gelöscht`,
        error_21501:`Bitte geben Sie den Bestätigungscode ein (Fehlercode: 21501)`,
        error_21502:`Die Anmeldesitzung ist abgelaufen (Fehler: 21502). Bitte melden Sie sich erneut an.`,
        error_21503:`Anfrage fehlgeschlagen (Fehlercode: 21503). Bitte versuchen Sie es erneut`,
        error_21505:`Anfrage fehlgeschlagen (Fehlercode: 21505), bitte versuchen Sie es erneut`,
        error_21601:`Fehler: 20601. Bitte kontaktieren Sie uns`,
        error_21602:`Ungültige Bestätigung (Fehler: 20602). Bitte versuchen Sie es erneut.`,
        error_21603:`Fehler: 20603. Bitte versuchen Sie es erneut`,
        error_21604:`Anfrage fehlgeschlagen (Fehlercode: 21604). Bitte versuchen Sie es erneut`,
        error_21606:`Anfrage fehlgeschlagen (Fehlercode: 21606). Bitte versuchen Sie es erneut`,
        error_21611:`Anfrage fehlgeschlagen (Fehlercode: 21611). Bitte versuchen Sie es erneut`,
        error_21801:`Fehler: 21801. Bitte kontaktieren Sie uns`,
        error_21802:`Anfrage fehlgeschlagen (Fehler: 21802). Bitte versuchen Sie es erneut`,
        error_21803:`Fehler: 21803. Bitte versuchen Sie es erneut`,
        error_21804:`Anfrage fehlgeschlagen (Fehlercode: 21804). Bitte versuchen Sie es erneut`,
        error_21806:`Fehler: 21806. Bitte versuchen Sie es erneut`,
        error_21807:`Fehler: 21807. Bitte kontaktieren Sie uns`,
        error_21808:`Fehler: 21808. Bitte kontaktieren Sie uns`,
        error_21809:`Fehler: 21809. Bitte kontaktieren Sie uns`,
        error_21810:`Fehler: 21810. Bitte kontaktieren Sie uns`,
        error_21811:`Fehler: 21811. Bitte kontaktieren Sie uns`,
        error_21812:`Fehler: 21812. Bitte kontaktieren Sie uns`,
        error_21813:`Anfrage fehlgeschlagen (Fehlercode: 21813). Bitte versuchen Sie es erneut`,
        error_21814:`Fehler: 21814. Bitte kontaktieren Sie uns`,
        error_21815:`Anfrage fehlgeschlagen (Fehlercode: 21815). Bitte versuchen Sie es erneut`,
        error_21816:`Fehler: 21816. Bitte kontaktieren Sie uns`,
        error_21817:`Fehler: 21817. Bitte kontaktieren Sie uns`,
        error_21818:`Fehler: 21818. Bitte kontaktieren Sie uns`,
        error_21819:`Anfrage fehlgeschlagen (Fehlercode: 21819). Bitte versuchen Sie es erneut`,
        error_21820:`Fehler: 21820. Bitte kontaktieren Sie uns`,
        error_21821:`Fehler: 21821. Bitte kontaktieren Sie uns`,
        error_21822:`Fehler: 21822. Bitte kontaktieren Sie uns`,
        error_21823:`Anfrage fehlgeschlagen (Fehlercode: 21823). Bitte versuchen Sie es erneut`,
        error_21824:`Anfrage fehlgeschlagen (Fehlercode: 21824). Bitte versuchen Sie es erneut`,
        error_21825:`Anfrage fehlgeschlagen (Fehlercode: 21825). Bitte versuchen Sie es erneut`,
        error_21826:`Anfrage fehlgeschlagen (Fehlercode: 21826). Bitte versuchen Sie es erneut`,
        error_21901:`Fehler: 21901. Bitte kontaktieren Sie uns`,
        error_21902:`Anfrage fehlgeschlagen (Fehlercode: 21902). Bitte versuchen Sie es erneut`,
        error_21903:`Der Kontostatus hat sich geändert (Fehlercode: 21903), bitte aktualisieren Sie die Seite und versuchen Sie es erneut`,
        error_21904:`Fehler: 21904. Bitte versuchen Sie es erneut`,
        error_21905:`Fehler: 21905. Bitte versuchen Sie es erneut`,
        error_21906:`Anfrage fehlgeschlagen (Fehlercode: 21906). Bitte versuchen Sie es erneut`,
        error_21907:`Das Google-Konto wurde mit einem anderen Konto verknüpft`,
        error_21908:`Anfrage fehlgeschlagen (Fehlercode: 21908). Bitte versuchen Sie es erneut`,
        error_22001:`Anfrage fehlgeschlagen (Fehlercode: 22001). Bitte versuchen Sie es erneut`,
        error_22002:`Aufheben der Verknüpfung ohne zusätzliche Anmeldung fehlgeschlagen`,
        error_22003:`Anfrage fehlgeschlagen (Fehlercode: 22003). Bitte versuchen Sie es erneut`,
        error_22101:`Fehler: 22101. Bitte kontaktieren Sie uns`,
        error_22102:`Der Kontostatus hat sich geändert (Fehlercode: 22102), bitte aktualisieren Sie die Seite und versuchen Sie es erneut`,
        error_22103:`Anfrage fehlgeschlagen (Fehlercode: 22103). Bitte versuchen Sie es erneut`,
        error_22104:`Der Kontostatus hat sich geändert (Fehlercode: 22104), bitte aktualisieren Sie die Seite und versuchen Sie es erneut`,
        error_22105:`Fehler: 22105. Bitte versuchen Sie es erneut`,
        error_22106:`Fehler: 22106. Bitte versuchen Sie es erneut`,
        error_22107:`Fehler: 22107. Bitte kontaktieren Sie uns`,
        error_22108:`Anfrage fehlgeschlagen (Fehlercode: 22108). Bitte versuchen Sie es erneut`,
        error_22201:`Fehler: 22201. Bitte kontaktieren Sie uns`,
        error_22202:`Ungültige Verifizierung (Fehler: 22202). Bitte versuchen Sie es erneut.`,
        error_22203:`Fehler: 22203. Bitte versuchen Sie es erneut."`,
        error_22204:`Anfrage fehlgeschlagen (Fehlercode: 22204). Bitte versuchen Sie es erneut`,
        error_22206:`Anfrage fehlgeschlagen (Fehlercode: 22206). Bitte versuchen Sie es erneut`,
        error_22401:`Fehler: 22401. Bitte kontaktieren Sie uns`,
        error_22402:`Ungültige Bestätigung (Fehler: 22402). Bitte versuchen Sie es erneut.`,
        error_22403:`Fehler: 22403. Bitte versuchen Sie es erneut`,
        error_22404:`Anfrage fehlgeschlagen (Fehlercode: 22404). Bitte versuchen Sie es erneut`,
        error_22405:`Facebook-Konto wurde mit anderer E-Mail verknüpft`,
        error_22406:`Fehler: 22406. Bitte versuchen Sie es erneut`,
        error_22407:`Fehler: 22407. Bitte kontaktieren Sie uns`,
        error_22408:`Fehler: 22408. Bitte kontaktieren Sie uns`,
        error_22409:`Fehler: 22409. Bitte kontaktieren Sie uns`,
        error_22410:`Fehler: 224010. Bitte kontaktieren Sie uns`,
        error_22411:`Fehler: 224011. Bitte kontaktieren Sie uns`,
        error_22412:`Fehler: 224012. Bitte kontaktieren Sie uns`,
        error_22413:`Fehler: 22413. Bitte kontaktieren Sie uns`,
        error_22414:`Anfrage fehlgeschlagen (Fehlercode: 22414). Bitte versuchen Sie es erneut`,
        error_22415:`Fehler: 22415. Bitte kontaktieren Sie uns`,
        error_22416:`Fehler: 22416. Bitte kontaktieren Sie uns`,
        error_22417:`Fehler: 22417. Bitte kontaktieren Sie uns`,
        error_22418:`Anfrage fehlgeschlagen (Fehlercode: 22418). Bitte versuchen Sie es erneut`,
        error_22419:`Fehler: 22419. Bitte kontaktieren Sie uns`,
        error_22420:`Fehler: 22420. Bitte kontaktieren Sie uns`,
        error_22421:`Fehler: 22421. Bitte kontaktieren Sie uns`,
        error_22422:`Anfrage fehlgeschlagen (Fehlercode: 22422). Bitte versuchen Sie es erneut`,
        error_22423:`Anfrage fehlgeschlagen (Fehlercode: 22423). Bitte versuchen Sie es erneut`,
        error_22424:`Anfrage fehlgeschlagen (Fehlercode: 22424). Bitte versuchen Sie es erneut`,
        error_22425:`Anfrage fehlgeschlagen (Fehlercode: 22425). Bitte versuchen Sie es erneut`,
        error_20098:`Fehlercode: 20098. Wenn Sie im privaten Modus surfen, wechseln Sie bitte in den normalen Modus und versuchen Sie es erneut.`,
        error_22298:`Anfrage an Google fehlgeschlagen (Fehler: 22298). Bitte versuchen Sie es erneut.`,
        error_22498:`Anfrage an Facebook fehlgeschlagen (Fehler: 22498). Bitte versuchen Sie es erneut.`,
        error_24902:`Anfrage fehlgeschlagen (Fehlercode: 24902). Bitte versuchen Sie es erneut`,
        error_24903:`Anfrage fehlgeschlagen (Fehlercode: 24903). Bitte versuchen Sie es erneut`,
        error_24904:`Anfrage fehlgeschlagen (Fehlercode: 24904). Bitte versuchen Sie es erneut`,
        error_24905:`Anfrage fehlgeschlagen (Fehlercode: 24905). Bitte versuchen Sie es erneut`,
        login_title:`Melden Sie sich bei Aiseesoft an`,
        password_placeholder:`Passwort`,
        log_in:`Einloggen`,
        no_account:`Kein Account?`,
        create_it:`Erstellen Sie es`,
        or_log_in_with:`Oder loggen Sie sich mit`,
        passwordless_login:`Passwortfreies Login`,
        log_in_done:`Anmelden Fertig`,
        three_rd_account_connect_info:`Herzliche Glückwünsche! Sie haben sich erfolgreich angemeldet. Jetzt können Sie ein E-Mail-Konto verbinden, das für die zukünftige Anmeldung verwendet wird.`,
        connect_now:`Jetzt verbinden`,
        see_my_account:`Mein Konto sehen`,
        three_rd_login_merge_account:`Die E-Mail-Adresse des Drittanbieterkontos wurde registriert. Möchten Sie sich direkt mit dieser E-Mail-Adresse verbinden und anmelden?`,
        connect_log_in:`Verbinden und einloggen`,
        create_an_account:`Ein Konto erstellen`,
        connect_account:`Konto verbinden`,
        connect_account_info:`Nach der Verbindung können Sie sich mit diesem Konto anmelden.`,
        back_to_log_in:`Zurück zur Anmeldung`,
        create_password:`Passwort erstellen`,
        create_now:`Jetzt erstellen`,
        password_login_subtitle:`Passwortlose Anmeldung mit E-Mail`,
        account_login:`Account Login`,
        rights:`Durch das Erstellen dieses Kontos stimmen Sie den <a href="https://www.eassiy.com/de/terms.html">Nutzungsbedingungen</a> und <a href="https://www.eassiy.com/de/privacy.html">Datenschutzerklärung</a>`,
        passwordless_login_done:`Passwortlose Anmeldung abgeschlossen`,
        passwordless_login_info:`Herzlichen Glückwunsch, Sie haben die passwortlose Anmeldung erfolgreich abgeschlossen. Sie können für dieses Konto ein Passwort erstellen und sich zukünftig mit dem Konto und Passwort anmelden. <a href="/create-password" style="display: initial;">Jetzt erstellen</a>`,
        sign_up:`Anmeldung`,
        register_info:`Ihren Account erstellen`,
        reset_now:`Jetzt zurücksetzen`,
        forgot_password:`Passwort vergessen`,
        reset_password_subtitle:`Verwenden Sie Ihre Konto-E-Mail, um das Passwort zurückzusetzen`,
        plan_products:`Pläne und Produkte`,
        nick_name:`Nutzername`,
        email:`Email`,
        my_products:`Meine Produkte`,
        my_orders:`Meine Bestellungen`,
        unlink:`Verknüpfung aufheben`,
        link:`Verknüpfung`,
        connected_accounts:`Verbundene Konten`,
        last_name:`Nachname`,
        first_name:`Vorname`,
        Gender:`Geschlecht`,
        Birth:`Geburt`,
        Month:`Monat`,
        Year:`Jahr`,
        Country_Region:`Land/Region`,
        Address:`Die Anschrift`,
        Save:`Speichern`,
        Date:`Datum`,
        Male:`Männlich`,
        Female:`Weiblich`,
        Unspecified:`Nicht spezifiziert`,
        Security:`Sicherheit`,
        change_password:`Passwort ändern`,
        change_now:`Jetzt ändern`,
        connect_email:`E-Mail verbinden`,
        delete_account:`Konto löschen`,
        delete_account_info:`Wenn Ihr Konto gelöscht wird, werden alle Plan- und Produktdaten Ihres Kontos dauerhaft gelöscht und Sie können es möglicherweise nicht wiederherstellen. Wir empfehlen Ihnen, mit Vorsicht vorzugehen.`,
        Delete:`Löschen`,
        Logout:`Ausloggen`,
        my_profile:`Mein Profil`,
        guides_faqs:`Anleitungen & FAQs`,
        More:`Mehr`,
        guides:`Anleitungen`,
        register:`registrieren`,
        hot_faq:`Beliebte FAQ`,
        Contents:`Inhalt:`,
        contact_us:`Kontaktieren Sie uns>>`,
        plan:`Plan`,
        unregistered:`Nicht registriert`,
        buy_more:`Mehr kaufen`,
        buy_again:`Nochmal kaufen`,
        buy_now:`Jetzt kaufen`,
        free_no_limit:`Kostenlos & unbegrenzt`,
        expired:`Abgelaufen`,
        lifetime:`Lebenszeit`,
        remain:`Bleiben übrig`,
        day_s:`Tage)`,
        error_24801:`Anfrage fehlgeschlagen (Fehlercode: 24801). Bitte versuchen Sie es erneut`,
        no_app_found:`Keine App gefunden！<a href="/">Aktualisieren</a> oder <a href="https://www.eassiy.com/de">Gehen Sie zur offiziellen Website</a>`,
        get_more:`Mehr erfahren >>`,
        edit_photo:`Foto bearbeiten`,
        select_photo:`Foto auswählen`,
        change_photo:`Foto ändern`,
        cancel:`Abbrechen`,
        hide_password:`Passwort verbergen`,
        show_password:`Passwort anzeigen`,
        zoom_in:`Hineinzoomen`,
        zoom_out:`Rauszoomen`,
        rotate:`Drehen`,
        horizontal_flip:`Horizontaler Flip`,
        vertical_flip:`Vertikaler Flip`,
        country:`Land`,
        country_1:`Wählen Sie Ihr Land/Ihre Region aus`,
        country_2:`aland-Insel`,
        country_3:`Afghanistan`,
        country_4:`Albanien`,
        country_5:`Algerien`,
        country_6:`Amerikanischen Samoa-Inseln`,
        country_7:`Andorra`,
        country_8:`Angola`,
        country_9:`Anguilla`,
        country_10:`Antarktis`,
        country_11:`Antigua und Barbuda`,
        country_12:`Argentinien`,
        country_13:`Armenien`,
        country_14:`Aruba`,
        country_15:`Australien`,
        country_16:`Österreich`,
        country_17:`Aserbaidschan`,
        country_18:`Bahrain`,
        country_19:`Bangladesch`,
        country_20:`Barbados`,
        country_21:`Weißrussland`,
        country_22:`Belgien`,
        country_23:`Belize`,
        country_24:`Benin`,
        country_25:`Bermuda`,
        country_26:`Bhutan`,
        country_27:`Bolivien`,
        country_28:`Bosnien & Herzegowina`,
        country_29:`Botswana`,
        country_30:`Bouvet-Insel`,
        country_31:`Brasilien`,
        country_32:`Britisches Territorium des Indischen Ozeans`,
        country_33:`Britische Jungferninseln`,
        country_34:`Brunei`,
        country_35:`Bulgarien`,
        country_36:`Burkina`,
        country_37:`Burundi`,
        country_38:`Kambodscha`,
        country_39:`Kamerun`,
        country_40:`Kanada`,
        country_41:`Kap Verde`,
        country_42:`Karibik Niederlande`,
        country_43:`Cayman Inseln`,
        country_44:`Zentralafrikanische Republik`,
        country_45:`Tschad`,
        country_46:`Chile`,
        country_47:`China`,
        country_48:`Weihnachtsinsel`,
        country_49:`Kokosinseln (Keelinginseln)`,
        country_50:`Kolumbien`,
        country_51:`Cookinseln`,
        country_52:`Costa Rica`,
        country_53:`"Côte d'Ivoire"`,
        country_54:`Kroatien`,
        country_55:`Kuba`,
        country_56:`Zypern`,
        country_57:`Tschechien`,
        country_58:`Demokratische Republik Kongo`,
        country_59:`Dänemark`,
        country_60:`Dschibuti`,
        country_61:`Dominica`,
        country_62:`Dominikanische Republik`,
        country_63:`Ecuador`,
        country_64:`Ägypten`,
        country_65:`El Salvador`,
        country_66:`Äquatorialguinea`,
        country_67:`Eritrea`,
        country_68:`Estland`,
        country_69:`Äthiopien`,
        country_70:`Falkland Inseln`,
        country_71:`Färöer Inseln`,
        country_72:`Die Verbündeten Staaten von Micronesia`,
        country_73:`Fidschi`,
        country_74:`Finnland`,
        country_75:`Frankreich`,
        country_76:`Französisch-Guayana`,
        country_77:`Französisch Polynesien`,
        country_78:`Südfranzösische Territorien`,
        country_79:`Gabun`,
        country_80:`Gambia`,
        country_81:`Georgia`,
        country_82:`Deutschland`,
        country_83:`Ghana`,
        country_84:`Gibraltar`,
        country_85:`Großbritannien (Großbritannien; England)`,
        country_86:`Griechenland`,
        country_87:`Grönland`,
        country_88:`Grenada`,
        country_89:`Guadeloupe`,
        country_90:`Guam`,
        country_91:`Guatemala`,
        country_92:`Guernsey`,
        country_93:`Guinea`,
        country_94:`Guinea-Bissau`,
        country_95:`Guyana`,
        country_96:`Haiti`,
        country_97:`Heard Island und McDonald-Inseln`,
        country_98:`Honduras`,
        country_99:`Hongkong`,
        country_100:`Ungarn`,
        country_101:`Island`,
        country_102:`Indien`,
        country_103:`Indonesien`,
        country_104:`Iran`,
        country_105:`Irak`,
        country_106:`Irland`,
        country_107:`Isle of Man`,
        country_108:`Israel`,
        country_109:`Italien`,
        country_110:`Jamaika`,
        country_111:`Japan`,
        country_112:`Jersey`,
        country_113:`Jordanien`,
        country_114:`Kasachstan`,
        country_115:`Kenia`,
        country_116:`Kiribati`,
        country_117:`Kuwait`,
        country_118:`Kirgisistan`,
        country_119:`Laos`,
        country_120:`Lettland`,
        country_121:`Libanon`,
        country_122:`Lesotho`,
        country_123:`Liberia`,
        country_124:`Libyen`,
        country_125:`Liechtenstein`,
        country_126:`Litauen`,
        country_127:`Luxemburg`,
        country_128:`Macau`,
        country_129:`Madagaskar`,
        country_130:`Malawi`,
        country_131:`Malaysia`,
        country_132:`Malediven`,
        country_133:`Mali`,
        country_134:`Malta`,
        country_135:`Marshallinseln`,
        country_136:`Martinique`,
        country_137:`Mauretanien`,
        country_138:`Mauritius`,
        country_139:`Mayotte`,
        country_140:`Mexiko`,
        country_141:`Moldawien`,
        country_142:`Monaco`,
        country_143:`Mongolei`,
        country_144:`Montenegro`,
        country_145:`Montserrat`,
        country_146:`Marokko`,
        country_147:`Mosambik`,
        country_148:`Myanmar (Burma)`,
        country_149:`Namibia`,
        country_150:`Nauru`,
        country_151:`Nepal`,
        country_152:`Niederlande`,
        country_153:`Neu-Kaledonien`,
        country_154:`Neuseeland`,
        country_155:`Nicaragua`,
        country_156:`Niger`,
        country_157:`Nigeria`,
        country_158:`Niue`,
        country_159:`Norfolkinsel`,
        country_160:`Nord Korea`,
        country_161:`Nördliche Marianneninseln`,
        country_162:`Norwegen`,
        country_163:`Oman`,
        country_164:`Pakistan`,
        country_165:`Palau`,
        country_166:`Palästinensische Gebiete`,
        country_167:`Panama`,
        country_168:`Papua Neu-Guinea`,
        country_169:`Paraguay`,
        country_170:`Peru`,
        country_171:`Pitcairninseln`,
        country_172:`Polen`,
        country_173:`Portugal`,
        country_174:`Puerto Rico`,
        country_175:`Katar`,
        country_176:`Republik Mazedonien (FYROM)`,
        country_177:`Republik Kongo`,
        country_178:`Wiedervereinigung`,
        country_179:`Rumänien`,
        country_180:`Russische Föderation`,
        country_181:`Ruanda`,
        country_182:`Saint-Barthélemy`,
        country_183:`Saint-Martin (Frankreich)`,
        country_184:`Saint-Pierre und Miquelon`,
        country_185:`Samoa`,
        country_186:`San Marino`,
        country_187:`Sao Tome & Principe`,
        country_188:`Saudi Arabien`,
        country_189:`Senegal`,
        country_190:`Serbien`,
        country_191:`Seychellen`,
        country_192:`Sierra Leone`,
        country_193:`Singapur`,
        country_194:`Slowakei`,
        country_195:`Slowenien`,
        country_196:`Salomon-Inseln`,
        country_197:`Somalia`,
        country_198:`Südafrika`,
        country_199:`Südgeorgien und die Südliche Sandwichinsel`,
        country_200:`Südkorea`,
        country_201:`Südsudan`,
        country_202:`Spanien`,
        country_203:`Sri Lanka`,
        country_204:`St. Helena & Abhängigkeiten`,
        country_205:`St. Kitts & Nevis`,
        country_206:`St. Lucia`,
        country_207:`St. Vincent und die Grenadinen`,
        country_208:`Sudan`,
        country_209:`Surinam`,
        country_210:`Swasiland`,
        country_211:`Schweden`,
        country_212:`Schweiz`,
        country_213:`Syrien`,
        country_214:`Taiwan`,
        country_215:`Tadschikistan`,
        country_216:`Tansania`,
        country_217:`Vorlage:Länderdaten SJM Spitzbergen`,
        country_218:`Thailand`,
        country_219:`Die Bahamas`,
        country_220:`Die Komoren`,
        country_221:`Die Phillipinen`,
        country_222:`Timor-Leste (Osttimor)`,
        country_223:`Gehen`,
        country_224:`Tokelau`,
        country_225:`Tonga`,
        country_226:`Trinidad & Tobago`,
        country_227:`Tunesien`,
        country_228:`Truthahn`,
        country_229:`Turkmenistan`,
        country_230:`Turks- und Caicosinseln`,
        country_231:`Tuvalu`,
        country_232:`Uganda`,
        country_233:`Ukraine`,
        country_234:`Vereinigte Arabische Emirate`,
        country_235:`Kleinere vorgelagerte Inseln der Vereinigten Staaten`,
        country_236:`Vereinigte Staaten von Amerika (USA)`,
        country_237:`Amerikanische Jungferninseln`,
        country_238:`Uruguay`,
        country_239:`Usbekistan`,
        country_240:`Vanuatu`,
        country_241:`Vatikanstadt (Der Heilige Stuhl)`,
        country_242:`Venezuela`,
        country_243:`Vietnam`,
        country_244:`Wallis und Futuna`,
        country_245:`Westsahara`,
        country_246:`Jemen`,
        country_247:`Sambia`,
        country_248:`Zimbabwe`,
        google_login:`Anmeldung mit Google`,
        footer:`Copyright © 2023 Aiseesoft Studio. Alle Rechte vorbehalten.`,
        State:`Status`,
        Activation_code:`Aktivierungscode`,
        Question:`Liste alle APPs auf, die Sie eingeloggt haben`,
        Copy_complete:`Kopieren abgeschlossen`,

        store:`Store`,
        resource:`Anleitungen`,
        support:`Support`,
        video_converter:`Video Converter`,
        multimedia:`Multimedia`,
        data_recovery:`Data Recovery`,
        utility:`Utility`,
        free_tools:`Free Tools`,
        for_windows:`Für Windows`,
        for_mac:`Für Mac`,
        pdf_solutions:`PDF-Lösungen`,
        data_manager:`Datenmanager`,
        other_tools:`Andere Werkzeuge`,
        free_online_solutions:`Kostenlose Online-Lösungen`,
        free_online_tools:`Kostenlose Online-Tools`,
        desktop_freeware:`Desktop-Freeware`,
        product_1:`Video Converter Ultimate`,
        product_2:`Total Video Converter`,
        product_3:`MTS Konverter`,
        product_4:`4K Konverter`,
        product_5:`MXF Konverter`,
        product_6:`3D Konverter`,
        product_7:`Mac Video Converter Ultimate`,
        product_8:`Video Converter für Mac`,
        product_9:`MTS Converter für Mac`,
        product_10:`4K Converter für Mac`,
        product_11:`MXF Converter für Mac`,
        product_12:`3D Converter für Mac`,
        product_13:`Blu-ray Player`,
        product_14:`Video Enhancer`,
        product_15:`Video Editor`,
        product_16:`Slideshow Creator`,
        product_17:`Blu-ray Creator`,
        product_18:`Burnova - DVD Creator Pro`,
        product_19:`DVD Creator`,
        product_20:`Mac Blu-ray Player`,
        product_21:`Mac Video Enhancer`,
        product_22:`Video Editor für Mac`,
        product_23:`DVD Creator für Mac`,
        product_24:`MP4 Converter für Mac`,
        product_25:`Data Recovery`,
        product_26:`FoneLab iPhone Datenrettung`,
        product_27:`iOS Systemwiederherstellung`,
        product_28:`iOS Datensicherung &amp; Wiederherstellung`,
        product_29:`FoneLab Android Datenrettung`,
        product_30:`Android Datenextrahierung`,
        product_31:`Android Datensicherung &amp; Wiederherstellung`,
        product_32:`Mac Data Recovery`,
        product_33:`Mac FoneLab iPhone Datenrettung`,
        product_34:`iOS Systemwiederherstellung für Mac`,
        product_35:`iOS Datensicherung &amp; Wiederherstellung für Mac`,
        product_36:`Mac FoneLab Android Datenrettung`,
        product_37:`Android Datensicherung &amp; Wiederherstellung für Mac`,
        product_38:`PDF Converter Ultimate`,
        product_39:`Mac PDF Converter Ultimate`,
        product_40:`PDF Merger`,
        product_41:`Mac PDF to ePub Converter`,
        product_42:`Mac Cleaner`,
        product_43:`FoneTrans`,
        product_44:`WhatsApp Transfer für iOS`,
        product_45:`MobieSync`,
        product_46:`FoneEraser`,
        product_47:`iPhone Cleaner`,
        product_48:`PPT to Video Converter`,
        product_49:`HEIC Converter`,
        product_50:`iPhone Unlocker`,
        product_51:`Kostenloser Online-Videokonverter`,
        product_52:`Kostenloser Online-Audiokonverter`,
        product_53:`Kostenloser Online-Videokompressor`,
        product_54:`Kostenlose Online-Videozusammenführung`,
        product_55:`Kostenloser HEIC-Konverter`,
        product_56:`Kostenloser Bildkompressor`,
        product_57:`Kostenloser Wasserzeichen-Entferner`,
        product_58:`Kostenloser PDF-Kompressor Online`,
        product_59:`Kostenloser Online-Bild-Upscaler`,
        product_60:`Kostenloser Online-Hintergrundentferner`,
        product_61:`Kostenloser Online-Bildkonverter`,
        product_62:`Kostenloser Online-PDF-PNG-Konverter`,
        product_63:`Kostenloser Videokonverter`,
        product_64:`Kostenloser Video-zu-GIF-Konverter`,
        product_65:`Kostenlose Android-Datenwiederherstellung`,
        product_66:`Diashow-Ersteller`,
        product_67:`Kostenloser Video-Editor`,
        product_68:`Kostenloser PDF-Viewer`,
        product_69:`Bildschirm recorder`,
        product_70:`Mac-Bildschirmrekorder`,
        product_71:`Kostenloser Online-Bildschirmrekorder`,
        product_72:`Kostenloser Online-Audiorecorder`,
        product_73:`Telefonspiegel`,
        product_74:`AnyCoord - Standortwechsler`,
        product_75:`iPhone Passwort-Manager`,
        product_76:`Kostenloser Online-Audiokompressor`,
        product_77:`Kostenloser Video-Trimmer online`,
        product_78:`Kostenloser Online-Videoschneider`,
        product_79:`Kostenloser Online-Videorotator`,
        product_80:`Kostenloser Video-Looper online`,
        product_81:`Kostenloser Online-PDF-JPG-Konverter`,
        product_81:`Kostenloser JPG-Word-Konverter online`,
        category_1:`Fotobearbeitung`,
        category_2:`Videobearbeitung`,
        category_3:`Radiergummi`,
        category_4:`Medienrekorder`,
        posted_by:`Geschrieben von`,
        to:`zu`,
        author_1:`Ashley Mae`,
        author_2:`Jenny Ryan`,
        author_3:`Nicole Jones`,
        author_4:`Amanda Brown`,
        article_1:`So entfernen Sie effizient Text aus Bildern`,
        article_2:`So erhöhen Sie die Bildgröße in KB auf MB, ohne die Qualität zu beeinträchtigen`,
        article_3:`So entfernen Sie das Getty Images-Wasserzeichen unter Windows und Mac`,
        article_4:`Die beste Methode, um ein Bild mit niedriger Auflösung online kostenlos in ein hochauflösendes zu konvertieren`,
        article_5:`TikTok-Anleitung | So erstellen Sie eine Videocollage auf TikTok`,
        article_6:`5 einfache Möglichkeiten zum Kombinieren von VOB-Dateien, die Sie kennen sollten`,
        article_7:`So skalieren Sie Videos und erhalten einfach eine höhere Auflösung`,
        article_8:`So kombinieren Sie MP4-Dateien auf MacBook Pro/iMac/MacBook und mehr`,
        article_9:`2 einfache Möglichkeiten zum Löschen des Kaufverlaufs auf dem iPhone [Gelöst]`,
        article_10:`So löschen Sie Snapchat-Bilder dauerhaft aus Chat und Erinnerungen`,
        article_11:`So löschen Sie Spotlight-Suchvorschläge und den Verlauf auf dem iPhone/iPad`,
        article_12:`[Gelöst] So löschen Sie den Instagram-Cache auf dem iPhone mit 3 einfachen Möglichkeiten`,
        article_13:`[Gelöst] So beheben Sie, dass QuickTime Player auf dem Mac nicht funktioniert`,
        article_14:`So nehmen Sie Systemaudio auf einem Mac auf (Big Sur/Catalina/Mojave …)`,
        article_15:`Effektive Möglichkeiten, um zu beheben, dass die PowerPoint-Bildschirmaufzeichnung nicht funktioniert`,
        article_16:`So nehmen Sie Audio mit VLC auf [Gelöst]`,
        faq_title_1:`1. Wie behebt man den 4104-Fehler?`,
        faq_title_2:`2. Wann kann ich mein Geld zurückerhalten, nachdem der Rückerstattungsantrag genehmigt wurde?`,
        faq_title_3:`3. Was ist ein "1 Jahresabonnement" und wie kann man den "Automatischen Verlängerungsdienst" deaktivieren?`,
        faq_title_4:`4. Kann ich meine Bestellung stornieren, nachdem ich ein Aiseesoft-Produkt aufgegeben habe?`,
        faq_title_5:`5. Wie reiche ich Ihnen eine Bewertung ein?`,
        faq_info_1:`Das liegt an der begrenzten Lizenz, die Sie erhalten haben. Wir übernehmen die Einzellizenz für den Fall, dass der Registrierungscode gestohlen wird und verhindern die Verletzung des Registrierungscodes. Der Reg-Code, den Sie erhalten haben, ist eine Einzellizenz, die nur auf einem Computer verwendet werden kann. Wenn Sie es auf mehr als einem Computer verwenden, wird Fehler 4104 angezeigt. Sie können es nicht verwenden, bis wir es für Sie zurückgesetzt haben.`,
        faq_info_2:`Wenn Sie Ihren Computer ändern oder neu installieren, können wir ihn für Sie zurücksetzen. Bitte wenden Sie sich an den Kundenservice: <a href="mailto:support@eassiy.com">support@eassiy.com</a>, um das Zurücksetzen des Registrierungscodes für Sie anzufordern.`,
        faq_info_3:`Sobald Ihr Rückerstattungsantrag genehmigt wurde, dauert es normalerweise 3 bis 7 Werktage, bis die Gutschrift auf Ihrem Konto angezeigt wird. Während der Ferien kann es manchmal länger dauern, aber es wird nicht erwartet, dass es länger als 10 Tage dauert. Wenn Sie die Rückerstattung 20 Tage nach der Genehmigung nicht erhalten haben, kontaktieren Sie uns bitte unter <a href="mailto:support@eassiy.com">support@eassiy.com</a>, um die Rückerstattung zu überprüfen.`,
        faq_info_4:`Automatischer Verlängerungsservice bedeutet, dass beim Kauf eines 1-Jahres-Abonnements die Zahlungsplattform den Betrag (einschließlich der anfallenden Steuern) automatisch berechnet, wenn die Produktlizenz abläuft. Aber das Zahlungs-Flatform (wie z.B. 2Checkout) sendet Ihnen einen Brief, um Sie daran zu erinnern, dass die Bestellung 7 Tage vor Ablauf abläuft. Die E-Mail enthält auch Anweisungen zum Deaktivieren des „Automatischen Verlängerungsdienstes“.`,
        faq_info_5:`Da die Bestellung automatisch verarbeitet wird, ist eine Stornierung der Bestellung nicht zulässig. Außerdem können Sie mit der kostenlosen Testversion mehr über das Aiseesoft-Programm erfahren und prüfen, ob es Ihren Anforderungen entspricht, bevor Sie es kaufen.`,
        faq_info_6:`Wenn Sie wirklich eine Rückerstattung wünschen, lesen Sie bitte unsere <a href="https://www.eassiy.com/de/refund-policy.html">Erstattungsrichtlinien</a>.`,
        faq_info_7:`Wir schätzen Ihre Zeit und Aufmerksamkeit, um uns eine Bewertung zu senden. Kontaktieren Sie uns (<a href="mailto:support@eassiy.com">support@eassiy.com</a>), um Ihre Bewertung abzugeben, und wir werden Ihre Bewertung für unsere Produktaktualisierungen ernsthaft berücksichtigen.`,
        change_password_success:`das Passwort wurde erfolgreich geändert`,
        successful_login_title:`Einloggen erfolgreich`,
        product_page:`Produktseite>>`,
        successful_login_info:`Anmeldung abgeschlossen. Bitte schließen Sie die aktuelle Seite und kehren Sie zum ursprünglichen Tab zurück, um mit dem folgenden Vorgang fortzufahren. Die aktuelle Seite wird in 5 Sekunden automatisch geschlossen. Wenn das automatische Schließen oder Schließen durch Klicken auf die Schaltfläche "Fertig" fehlschlägt, schließen Sie diese Registerkarte bitte direkt.`,
        successful_login_info_firefox:`Anmeldung abgeschlossen. Bitte schließen Sie die aktuelle Seite und kehren Sie zum ursprünglichen Tab zurück, um mit dem folgenden Vorgang fortzufahren.`,
        my_account:`Mein Konto`,
        my_history:`Meine Geschichte`,
        remove_watermark:`Wasserzeichen entfernen`,
        no_history:`Keine Historie`,
        history_all:`Wählen Sie Alle`,
        history_open:`Offen`,
        history_down:`Herunterladen`,
        history_delete:`Löschen`,
        history_clear:`Ungültig löschen`,
        images:`Bild(er)`,
        use_this_function:`Verwenden Sie diese Funktion>>`,
        hd_downloading:`Vorteile beim Herunterladen von HD-Originalbildern:`,
        lifetimeRemaining:`Restlebensdauer`,
        Remaining:`Verbleibend`,
        email_verification:`E-Mail-Verifizierung`,
        email_verification_info:`Wir haben die Bestätigungsnachricht an Ihre E-Mail-Adresse <span class="email"></span> gesendet, und bitte schließen Sie die Bestätigung ab. Nach der Überprüfung werden die Vorteile automatisch synchronisiert.`,
        wrong_email:`Falsche E-Mail Adresse?`,
        click_here_to_modify:`Klicken Sie hier, um zu ändern`,
        get_more_help:`Weitere Hilfe erhalten?`,
        click_here:`Hier klicken`,
        email_verification_info_success:`Herzlichen Glückwunsch zur Bestätigung Ihres E-Mail-Kontos.`,
        email_verification_info_error:`Die Überprüfung ist aufgrund des abgelaufenen Links fehlgeschlagen.`,
        registration_succeeded:`Registrierung erfolgreich`,
        registration_succeeded_info_1:`Herzliche Glückwünsche! Du hast dich erfolgreich registriert. Wir haben die Bestätigungsnachricht an Ihre E-Mail-Adresse <span class="email"></span> gesendet. Bitte schließen Sie die Bestätigung ab, um die Vorteile dieser E-Mail nutzen zu können.`,
        registration_succeeded_info_2:`Klicken Sie auf „Fertig“, um zur Startseite zurückzukehren und dieses Produkt zu verwenden.`,
        registration_succeeded_info_3:`Klicken Sie auf „Fertig“, um die aktuelle Seite zu schließen und für die folgenden Vorgänge zur Startseite zurückzukehren. Wenn das Schließen fehlschlägt, schließen Sie die Registerkarte bitte manuell.`,
        verify_email:`E-Mail bestätigen`,
        registered_email_not_verified:`Die registrierte E-Mail wurde nicht verifiziert, bitte verifizieren Sie sie sofort.`,
        email_verification_time_1:`Keine Bestätigungs-E-Mail erhalten?`,
        email_verification_time_2:`Nach`,
        email_verification_time_3:`Klicken Sie hier, um es erneut zu senden`,
        error_26301:`Fehlercode: 26301, bitte kontaktieren Sie uns`,
        error_26302:`Fehlercode: 26302, bitte kontaktieren Sie uns`,
        error_26303:`E-Mail-Formatfehler (Fehlercode: 26303). Bitte geben Sie es erneut ein`,
        error_26304:`Passwort mit mehr als 8 Zeichen wird empfohlen (Fehlercode: 26304)`,
        error_26305:`Anforderung fehlgeschlagen (Fehlercode: 26305). Bitte versuchen Sie es erneut`,
        error_26306:`E-Mail wurde registriert, bitte <a href="https://account.aisesoft.com/login">gehen Sie zum Anmelden</a>`,
        error_26307:`Anforderung fehlgeschlagen (Fehlercode: 26307). Bitte versuchen Sie es erneut`,
        error_26308:`Anforderung fehlgeschlagen (Fehlercode: 26308). Bitte versuchen Sie es erneut`,
        error_26401:`Fehlercode: 26401, bitte versuchen Sie es erneut`,
        error_26402:`E-Mail wurde verifiziert (Fehlercode: 26402), bitte versuchen Sie es erneut`,
        error_26403:`Anforderung fehlgeschlagen (Fehlercode: 26403). Bitte versuchen Sie es erneut`,
        error_26404:`Anfrage fehlgeschlagen (Fehlercode: 26404). Bitte versuchen Sie es erneut`,
        error_26501:`Fehlercode: 26501, bitte kontaktieren Sie uns`,
        error_26502:`Fehlercode: 26502, bitte kontaktieren Sie uns`,
        error_26503:`E-Mail-Formatfehler (Fehlercode: 26503). Bitte geben Sie es erneut ein`,
        error_26504:`Anforderung fehlgeschlagen (Fehlercode: 26504). Bitte versuchen Sie es erneut`,
        error_26505:`E-Mail wurde nicht registriert, bitte <a href="https://account.aisesoft.com/register">registrieren Sie es zuerst</a>`,
        error_26506:`E-Mail wurde bestätigt.`,
        error_26507:`Anforderung fehlgeschlagen (Fehlercode: 26507). Bitte versuchen Sie es erneut`,
        error_26508:`Überprüfung fehlgeschlagen (Fehlercode: 26508), bitte versuchen Sie es erneut`,
        error_26509:`Anfrage fehlgeschlagen (Fehlercode: 26509), bitte versuchen Sie es erneut`,
        error_26510:`Fehlercode: 26510, bitte kontaktieren Sie uns`,
        error_26601:`Fehlercode: 26601, bitte kontaktieren Sie uns`,
        error_26602:`Fehlercode: 26602, bitte kontaktieren Sie uns`,
        error_26603:`Anforderung fehlgeschlagen (Fehlercode: 26603). Bitte versuchen Sie es erneut`,
        error_26604:`Fehlercode: 26604, bitte kontaktieren Sie uns`,
        error_26605:`Fehlercode: 26605, bitte kontaktieren Sie uns`,
        error_26701:`Fehlercode: 26701, bitte kontaktieren Sie uns`,
        error_26702:`Anforderung fehlgeschlagen (Fehlercode: 26702). Bitte versuchen Sie es erneut`,
        error_26703:`Fehlercode: 26703, bitte kontaktieren Sie uns`,
        error_26704:`Fehlercode: 26704, bitte kontaktieren Sie uns`,
        error_26705:`Warten Sie auf die Anmeldung (Fehlercode: 26705). Bitte versuchen Sie es erneut`,
        no_cookie:`Sie haben die Funktion „Alle Cookies blockieren“ in Ihrem Browser aktiviert, sodass Sie sich nicht anmelden können. Bitte gehen Sie zu den Einstellungen, um das Kontrollkästchen „Alle Cookies zulassen“ zu aktivieren.`,
        error_26801:`Fehlercode: 26801, bitte kontaktieren Sie uns`,
        error_26802:`Fehlercode: 26802, bitte kontaktieren Sie uns`,
        error_26803:`Anfrage fehlgeschlagen (Fehlercode: 26803). Bitte versuchen Sie es erneut`,
        error_26804:`Anfrage fehlgeschlagen (Fehlercode: 26804). Bitte versuchen Sie es erneut`,
        error_order:`Anfrage fehlgeschlagen (Fehlercode: 27098), bitte versuchen Sie es erneut!`,
        error_order1:`Die Bestellabfrage ist unvollständig（Fehlercode:`,
        error_order2:`）, bitte aktualisieren und erneut versuchen.`,
        modify_email_title:`E-Mail ändern`,
        modify_email_info:`Sie können die geänderte E-Mail verwenden, um sich bei Ihrem Konto anzumelden.`,
        images_per:`Billeder pr`,
        error_26101:`Fehler: 26101. Bitte kontaktieren Sie uns`,
        error_26102:`Fehler: 26102. Bitte kontaktieren Sie uns`,
        error_26103:`Anfrage fehlgeschlagen (Fehlercode: 26103). Bitte versuchen Sie es erneut`,
        error_26104:`Fehlercode: 26104, bitte versuchen Sie es erneut`,
        error_26105:`Fehlercode: 26105, bitte versuchen Sie es erneut`,
        error_26106:`Löschen fehlgeschlagen (Fehlercode: 26106). Bitte versuchen Sie es erneut`,
        error_26201:`Fehler: 26201. Bitte kontaktieren Sie uns`,
        error_26202:`Anfrage fehlgeschlagen (Fehlercode: 26202). Bitte versuchen Sie es erneut`,
        error_26001:`Fehler: 26001. Bitte kontaktieren Sie uns`,
        error_26002:`Fehler: 26002. Bitte kontaktieren Sie uns`,
        error_26003:`Fehler: 26003. Bitte kontaktieren Sie uns`,
        error_26004:`Fehler: 26004. Bitte kontaktieren Sie uns`,
        error_26005:`Anfrage fehlgeschlagen (Fehlercode: 26005). Bitte versuchen Sie es erneut`,
        error_26006:`Fehlercode: 26006, bitte versuchen Sie es erneut`,
        error_26008:`Fehler: 26008. Bitte kontaktieren Sie uns`,
        go_to_the_home_page:`Gehen Sie zur Startseite`,
        error_27101:`Anfrage fehlgeschlagen (Fehlercode: 27101). Bitte versuchen Sie es erneut`,
        error_27201:`Fehlercode: 27201, bitte kontaktieren Sie uns`,
        error_27202:`Fehlercode: 27202, bitte versuchen Sie es erneut`,
        error_27203:`Anfrage fehlgeschlagen (Fehlercode: 27203). Bitte versuchen Sie es erneut`,
        error_27204:`Ungültiger Code (Fehlercode: 27204).`,
        error_27205:`Anfrage fehlgeschlagen (Fehlercode: 27205). Bitte versuchen Sie es erneut`,
        error_27206:`Anfrage fehlgeschlagen (Fehlercode: 27206). Bitte versuchen Sie es erneut`,
        error_27207:`Anfrage fehlgeschlagen (Fehlercode: 27207). Bitte versuchen Sie es erneut`,
        no_history_found:`Sie haben kein Tool verwendet! <a href="/">Aktualisieren</a> oder <a href="https://www.eassiy.com/de">Gehen Sie zur offiziellen Website</a>`,
        error_25301:`Fehler: 25301. Bitte kontaktieren Sie uns`,
        error_25302:`Fehler: 25302. Bitte kontaktieren Sie uns`,
        error_25303:`Anfrage fehlgeschlagen (Fehlercode: 25303). Bitte versuchen Sie es erneut`,
        error_25304:`Anfrage fehlgeschlagen (Fehlercode: 25304). Bitte versuchen Sie es erneut`,
        error_25305:`Anfrage fehlgeschlagen (Fehlercode: 25305). Bitte versuchen Sie es erneut`,
        error_25306:`Anfrage fehlgeschlagen (Fehlercode: 25306). Bitte versuchen Sie es erneut`,
        image_upscaler_p:`Bilder ohne Wasserzeichen herunterladen:`,
    },
    fr:{
        overtime_1:`Code d'erreur:`,
        overtime_2:`, veuillez réessayer`,
        isnetwork:`Erreur Internet. Veuillez vérifier et réessayer`,
        email_placeholder:`E-mail`,
        email_empty:`Veuillez saisir l'e-mail`,
        email_not_valid:`L'email n'est pas valide`,
        email_not_valid_1:`Veuillez saisir votre email`,
        email_not_valid_2:`L'e-mail n'est pas valide, veuillez utiliser une autre adresse.`,
        email_not_valid_3:`Pas de saisie d'e-mail`,
        password_placeholder:`Mot de passe`,
        password_empty:`Veuillez saisir le mot de passe`,
        password_not_valid:`Compte ou mot de passe incorrect`,
        password_not_valid_1:`Un mot de passe supérieur à 8 caractères est requis`,
        password_not_valid_2:`Veuillez créer un mot de passe`,
        password_placeholder_1:`Créez votre mot de passe`,
        password_placeholder_2:`Confirmez votre mot de passe`,
        password_placeholder_3:`Créer un nouveau mot de passe`,
        password_placeholder_4:`Confirmer le nouveau mot de passe`,
        password_placeholder_5:`Saisir l'ancien mot de passe`,
        copy_password_empty:`Veuillez confirmer le mot de passe`,
        copy_password_not_valid:`Veuillez confirmer votre mot de passe`,
        copy_passwords_inconsistent:`Votre confirmation de mot de passe ne correspond pas`,
        code_empty:`Veuillez saisir le code de vérification`,
        code_not_valid:`Code de vérification invalide`,
        code_placeholder:`Code de Vérification`,
        not_received_code:`Si votre boîte aux lettres n'a pas reçu le code de vérification depuis longtemps, veuillez obtenir à nouveau le code de vérification.`,
        get_first_code:`Veuillez d'abord obtenir le code de vérification.`,
        connect_your_email_placeholder:`Connectez votre E-mail`,
        last_name_placeholder:`Veuillez entrer votre nom de famille`,
        first_name_placeholder:`Veuillez entrer votre prénom`,
        address_placeholder:`Veuillez entrer votre adresse`,
        no_code_text:`Nous avons envoyé un code de vérification. Veuillez saisir votre code. <span class='tips'>Vous n'avez pas reçu de code ?`,
        no_code_text_1:`1.Veuillez vous assurer que l'adresse e-mail est valide et qu'elle peut recevoir des e-mails.`,
        no_code_text_2:`2.Pour que l'e-mail soit envoyé automatiquement par le système, il peut être signalé comme spam ou courrier indésirable. Veuillez vérifier si l'e-mail se trouve dans le dossier indésirable.`,
        no_code_text_3:`3.Impossible résoudre votre problème ?`,
        no_code_text_3_span:`Alors cliquez ici pour nous contacter.`,
        order_no:`Vous n'avez acheté aucun produit. Pour toute question, veuillez <a href="https://www.eassiy.com/fr/buy/background-remover.html">Acheter maintenant</a> Ou <a href="https://www.eassiy.com/fr/support/"> Contactez-nous</a>`,
        error_24901:`Le compte actuel n'a pas d'adresse e-mail liée et ne trouve pas les commandes. Veuillez ajouter un e-mail.`,
        user_guide:`Guide de l'utilisateur>>`,
        download:`Télécharger`,
        order_number:`Numéro de commande`,
        Refund:`Remboursement`,
        Disabled:`Désactivée`,
        Normal:`Normal`,
        Modify:`Modifier`,
        Modify_1:`Modifier>>`,
        Connect:`Relier`,
        unlink_success:`Dissocier avec succès`,
        connect_success:`Connectez-vous avec succès`,
        feedback_title:`Merci pour vos commentaires. Veuillez laisser votre problème et nous vous répondrons dans les 24 heures.`,
        feedback_thank_you:`Merci !<br />Vos commentaires ont été soumis avec succès.`,
        feedback_email:`Entrez votre e-mail ici !`,
        feedback_content:`Laissez ici des problèmes ou suggestions que vous avez rencontrés.`,
        feedback_submit:`Soumettre`,
        form_contents:`Vous n'avez entré aucune description. Veuillez le saisir et soumettre à nouveau.`,
        old_password:`Veuillez saisir l'ancien mot de passe`,
        new_password:`Veuillez créer un nouveau mot de passe`,
        old_new_password:`Le nouveau mot de passe ne peut pas être le même que l'ancien`,
        incorrect_password:`Mot de passe incorrect`,
        delete_no:`Effacer maintenant`,
        Caps:`Touche Maj activée`,
        Get:`Obtenir`,
        Done:`Terminé`,
        error_20001:`Erreur : 20001. Veuillez vous reconnecter.`,
        error_20002:`Erreur : 20002. Veuillez vous reconnecter.`,
        error_20003:`Erreur : 20003. Veuillez vous reconnecter.`,
        error_20004:`Échec de la demande (code d'erreur : 20004). Veuillez réessayer.`,
        error_20005:`La session de connexion a expiré (Erreur : 20005). Veuillez vous reconnecter.`,
        error_20006:`Échec de la demande (code d'erreur : 20006). Veuillez réessayer.`,
        error_20007:`La session de connexion a expiré (Erreur : 20007). Veuillez vous reconnecter.`,
        error_20008:`La session de connexion a expiré (Erreur : 20008). Veuillez vous reconnecter.`,
        error_20009:`La session de connexion a expiré (Erreur : 20009). Veuillez vous reconnecter.`,
        error_20101:`Veuillez saisir votre email (code d'erreur : 20101)`,
        error_20102:`L'e-mail n'est pas valide (code d'erreur : 20102)`,
        error_20103:`Échec de la demande (code d'erreur : 20103). Veuillez réessayer`,
        error_20104:`L'e-mail est déjà utilisé, veuillez <a href="/login">vous connecter</a> ou vous inscrire avec un nouveau`,
        error_20105:`Échec de la demande (code d'erreur : 20105). Veuillez réessayer`,
        error_20106:`Échec de l'envoi de l'e-mail, veuillez réessayer`,
        error_20201:`Veuillez saisir votre e-mail (code d'erreur : 20201)`,
        error_20202:`Veuillez saisir votre mot de passe (code d'erreur : 20202)`,
        error_20203:`Veuillez saisir le code de vérification (code d'erreur : 20203)`,
        error_20204:`L'e-mail n'est pas valide (code d'erreur : 20204)`,
        error_20205:`Un mot de passe supérieur à 8 caractères est requis (code d'erreur : 20205)`,
        error_20206:`Échec de la demande (code d'erreur : 20206). Veuillez réessayer`,
        error_20207:`Code de vérification invalide`,
        error_20208:`Échec de la demande (code d'erreur : 20208). Veuillez réessayer`,
        error_20209:`Échec de la demande (code d'erreur : 20209). Veuillez réessayer`,
        error_20301:`Veuillez saisir votre e-mail (code d'erreur : 20301)`,
        error_20302:`Erreur : 20302. Veuillez nous contacter`,
        error_20303:`L'e-mail n'est pas valide (code d'erreur : 20303)`,
        error_20304:`Échec de la demande (code d'erreur : 20304). Veuillez réessayer`,
        error_20305:`Le compte n'existe pas. Veuillez saisir à nouveau ou <a href="/register">Créer un nouveau</a> d'abord.`,
        error_20306:`Pas encore de mot de passe. Utilisez la <a href="/passwordless-login">connexion sans mot de passe</a> ou <a href="/create-password">définissez un mot de passe</a> et connectez-vous.`,
        error_20308:`Échec de la demande (code d'erreur : 20308). Veuillez réessayer`,
        error_20401:`Échec de la déconnexion (code d'erreur : 20401). Veuillez réessayer`,
        error_20501:`Veuillez saisir votre e-mail (code d'erreur : 20501)`,
        error_20502:`L'e-mail n'est pas valide (code d'erreur : 20502)`,
        error_20503:`Échec de la demande (code d'erreur : 20503). Veuillez réessayer`,
        error_20504:`Échec de l'envoi de l'e-mail. Veuillez réessayer.`,
        error_20601:`Veuillez saisir votre email (code d'erreur : 20601)`,
        error_20602:`Veuillez saisir le code de vérification (code d'erreur : 20602)`,
        error_20603:`L'e-mail n'est pas valide (code d'erreur : 20603)`,
        error_20604:`Échec de la demande (code d'erreur : 20604). Veuillez réessayer`,
        error_20606:`Échec de la demande (code d'erreur : 20606). Veuillez réessayer`,
        error_20607:`Échec de la demande (code d'erreur : 20607). Veuillez réessayer`,
        error_20608:`Échec de la demande (code d'erreur : 20608). Veuillez réessayer`,
        error_20701:`Veuillez saisir votre email (code d'erreur : 20701)`,
        error_20702:`L'e-mail n'est pas valide (code d'erreur : 20702)`,
        error_20703:`Échec de la demande (code d'erreur : 20703). Veuillez réessayer`,
        error_20704:`Le compte n'existe pas. Veuillez saisir à nouveau ou <a href="/register">Créer un nouveau</a> d'abord.`,
        error_20705:`Échec de la demande (code d'erreur : 20705). Veuillez réessayer`,
        error_20706:`Échec de l'envoi de l'e-mail. Veuillez réessayer`,
        error_20801:`Veuillez saisir votre e-mail (code d'erreur : 20801)`,
        error_20802:`Erreur : 20802. Veuillez nous contacter`,
        error_20803:`Veuillez saisir le code de vérification (code d'erreur : 20803)`,
        error_20804:`L'e-mail n'est pas valide (code d'erreur : 20804)`,
        error_20805:`Un mot de passe supérieur à 8 caractères est requis (code d'erreur : 20805)`,
        error_20806:`Échec de la demande (code d'erreur : 20806). Veuillez réessayer`,
        error_20808:`Échec de la demande (code d'erreur : 20808). Veuillez réessayer`,
        error_20901:`Échec de la demande (code d'erreur : 20901). Veuillez réessayer`,
        error_20902:`Échec de la demande (code d'erreur : 20902). Veuillez réessayer`,
        error_21000:`Les modifications sont enregistrées`,
        error_21001:`Aucune information n'est soumise (code d'erreur : 21001)`,
        error_21002:`Échec de la demande (code d'erreur : 21002). Veuillez réessayer`,
        error_21101:`Veuillez saisir votre email (code d'erreur : 21101)`,
        error_21102:`L'e-mail n'est pas valide (code d'erreur : 21102)`,
        error_21103:`Échec de la demande (code d'erreur : 21103), veuillez réessayer`,
        error_21104:`L'e-mail est déjà connecté, veuillez en utiliser un nouveau`,
        error_21105:`Échec de la demande (code d'erreur : 21105), veuillez réessayer`,
        error_21106:`Échec de l'envoi de l'e-mail. Veuillez réessayer`,
        error_21201:`Veuillez saisir votre email (code d'erreur : 21201)`,
        error_21202:`Veuillez saisir le code de vérification (code d'erreur : 21202)`,
        error_21203:`L'e-mail n'est pas valide (code d'erreur : 21203)`,
        error_21204:`Erreur : 21204. Veuillez nous contacter`,
        error_21205:`Erreur : 21205. Veuillez nous contacter`,
        error_21206:`Un mot de passe supérieur à 8 caractères est requis (code d'erreur : 21206)`,
        error_21207:`Échec de la demande (code d'erreur : 21207). Veuillez réessayer`,
        error_21209:`Échec de la demande (code d'erreur : 21209). Veuillez réessayer`,
        error_21301:`Veuillez saisir l'ancien mot de passe (code d'erreur : 21301)`,
        error_21302:`Veuillez créer un nouveau mot de passe (code d'erreur : 21302)`,
        error_21303:`Le nouveau mot de passe ne peut pas être le même que l'ancien. (Erreur : 21303)`,
        error_21304:`Un mot de passe supérieur à 8 caractères est requis (code d'erreur : 21304)`,
        error_21306:`Échec de la demande (code d'erreur : 21306). Veuillez réessayer`,
        error_21402:`Échec de la demande (code d'erreur : 21402). Veuillez réessayer`,
        error_21403:`Échec de l'envoi du code de vérification. Veuillez le renvoyer`,
        error_21500:`Le compte a été supprimé`,
        error_21501:`Veuillez saisir le code de vérification (code d'erreur : 21501)`,
        error_21502:`La session de connexion a expiré (Erreur : 21502). Veuillez vous reconnecter.`,
        error_21503:`Échec de la demande (code d'erreur : 21503). Veuillez réessayer`,
        error_21505:`Échec de la demande (code d'erreur : 21505), veuillez réessayer`,
        error_21601:`Erreur : 20601. Veuillez nous contacter`,
        error_21602:`Vérification non valide (erreur : 20602). Veuillez réessayer.`,
        error_21603:`Erreur : 20603. Veuillez réessayer`,
        error_21604:`Échec de la demande (code d'erreur : 21604). Veuillez réessayer`,
        error_21606:`Échec de la demande (code d'erreur : 21606). Veuillez réessayer`,
        error_21611:`Échec de la demande (code d'erreur : 21611). Veuillez réessayer`,
        error_21801:`Erreur : 21801. Veuillez nous contacter`,
        error_21802:`Échec de la demande (Erreur : 21802). Veuillez réessayer`,
        error_21803:`Erreur : 21803. Veuillez réessayer`,
        error_21804:`Échec de la demande (code d'erreur : 21804). Veuillez réessayer`,
        error_21806:`Erreur : 21806. Veuillez réessayer`,
        error_21807:`Erreur : 21807. Veuillez nous contacter`,
        error_21808:`Erreur : 21808. Veuillez nous contacter`,
        error_21809:`Erreur : 21809. Veuillez nous contacter`,
        error_21810:`Erreur : 21810. Veuillez nous contacter`,
        error_21811:`Erreur : 21811. Veuillez nous contacter`,
        error_21812:`Erreur : 21812. Veuillez nous contacter`,
        error_21813:`Échec de la demande (code d'erreur : 21813). Veuillez réessayer`,
        error_21814:`Erreur : 21814. Veuillez nous contacter`,
        error_21815:`Échec de la demande (code d'erreur : 21815). Veuillez réessayer`,
        error_21816:`Erreur : 21816. Veuillez nous contacter`,
        error_21817:`Erreur : 21817. Veuillez nous contacter`,
        error_21818:`Erreur : 21818. Veuillez nous contacter`,
        error_21819:`Échec de la demande (code d'erreur : 21819). Veuillez réessayer`,
        error_21820:`Erreur : 21820. Veuillez nous contacter`,
        error_21821:`Erreur : 21821. Veuillez nous contacter`,
        error_21822:`Erreur : 21822. Veuillez nous contacter`,
        error_21823:`Échec de la demande (code d'erreur : 21823). Veuillez réessayer`,
        error_21824:`Échec de la demande (code d'erreur : 21824). Veuillez réessayer`,
        error_21825:`Échec de la demande (code d'erreur : 21825). Veuillez réessayer`,
        error_21826:`Échec de la demande (code d'erreur : 21826). Veuillez réessayer`,
        error_21901:`Erreur : 21901. Veuillez nous contacter`,
        error_21902:`Échec de la demande (code d'erreur : 21902). Veuillez réessayer`,
        error_21903:`Le statut du compte a changé (code d'erreur : 21903), veuillez actualiser la page et réessayer`,
        error_21904:`Erreur : 21904. Veuillez réessayer`,
        error_21905:`Erreur : 21905. Veuillez réessayer`,
        error_21906:`Échec de la demande (code d'erreur : 21906). Veuillez réessayer`,
        error_21907:`Le compte Google a été associé à un autre compte`,
        error_21908:`Échec de la demande (code d'erreur : 21908). Veuillez réessayer`,
        error_22001:`Échec de la demande (code d'erreur : 22001). Veuillez réessayer`,
        error_22002:`Échec de la dissociation sans connexion supplémentaire`,
        error_22003:`Échec de la demande (code d'erreur : 22003). Veuillez réessayer`,
        error_22101:`Erreur : 22101. Veuillez nous contacter`,
        error_22102:`Le statut du compte a changé (code d'erreur : 22102), veuillez actualiser la page et réessayer`,
        error_22103:`Échec de la demande (code d'erreur : 22103). Veuillez réessayer`,
        error_22104:`Le statut du compte a changé (code d'erreur : 22104), veuillez actualiser la page et réessayer`,
        error_22105:`Erreur : 22105. Veuillez réessayer`,
        error_22106:`Erreur : 22106. Veuillez réessayer`,
        error_22107:`Erreur : 22107. Veuillez nous contacter`,
        error_22108:`Échec de la demande (code d'erreur : 22 108). Veuillez réessayer`,
        error_22201:`Erreur : 22201. Veuillez nous contacter`,
        error_22202:`Vérification non valide (Erreur : 22202). Veuillez réessayer.`,
        error_22203:`Erreur : 22203. Veuillez réessayer`,
        error_22204:`Échec de la demande (code d'erreur : 22204). Veuillez réessayer`,
        error_22206:`Échec de la demande (code d'erreur : 22206). Veuillez réessayer`,
        error_22401:`Erreur : 22401. Veuillez nous contacter`,
        error_22402:`Vérification non valide (erreur : 22402). Veuillez réessayer.`,
        error_22403:`Erreur : 22403. Veuillez réessayer`,
        error_22404:`Échec de la demande (code d'erreur : 22404). Veuillez réessayer`,
        error_22405:`Le compte Facebook a été lié à d'autres e-mails`,
        error_22406:`Erreur : 22406. Veuillez réessayer`,
        error_22407:`Erreur : 22407. Veuillez nous contacter`,
        error_22408:`Erreur : 22408. Veuillez nous contacter`,
        error_22409:`Erreur : 22409. Veuillez nous contacter`,
        error_22410:`Erreur : 224010. Veuillez nous contacter`,
        error_22411:`Erreur : 224011. Veuillez nous contacter`,
        error_22412:`Erreur : 224012. Veuillez nous contacter`,
        error_22413:`Erreur : 22413. Veuillez nous contacter`,
        error_22414:`Échec de la demande (code d'erreur : 22414). Veuillez réessayer`,
        error_22415:`Erreur : 22415. Veuillez nous contacter`,
        error_22416:`Erreur : 22416. Veuillez nous contacter`,
        error_22417:`Erreur : 22417. Veuillez nous contacter`,
        error_22418:`Échec de la demande (code d'erreur : 22418). Veuillez réessayer`,
        error_22419:`Erreur : 22419. Veuillez nous contacter`,
        error_22420:`Erreur : 22420. Veuillez nous contacter`,
        error_22421:`Erreur : 22421. Veuillez nous contacter`,
        error_22422:`Échec de la demande (code d'erreur : 22422). Veuillez réessayer`,
        error_22423:`Échec de la demande (code d'erreur : 22423). Veuillez réessayer`,
        error_22424:`Échec de la demande (code d'erreur : 22424). Veuillez réessayer`,
        error_22425:`Échec de la demande (code d'erreur : 22425). Veuillez réessayer`,
        error_20098:`Code d'erreur : 20098. Si vous naviguez en mode privé, veuillez passer en mode normal et réessayer.`,
        error_22298:`La demande pour Google a échoué (Erreur : 22298). Veuillez réessayer.`,
        error_22498:`La demande de Facebook a échoué (Erreur : 22498). Veuillez réessayer.`,
        error_24902:`Échec de la demande (code d'erreur : 24902). Veuillez réessayer`,
        error_24903:`Échec de la demande (code d'erreur : 24903). Veuillez réessayer`,
        error_24904:`Échec de la demande (code d'erreur : 24904). Veuillez réessayer`,
        error_24905:`Échec de la demande (code d'erreur : 24905). Veuillez réessayer`,
        login_title:`Connexion à Aiseesoft`,
        password_placeholder:`Mot de passe`,
        log_in:`Connexion`,
        no_account:`Pas de compte?`,
        create_it:`Créer un nouveau`,
        or_log_in_with:`Ou connexion avec`,
        passwordless_login:`Connexion par E-mail`,
        log_in_done:`Connexion Terminé`,
        three_rd_account_connect_info:`Félicitations! Vous vous êtes connecté avec succès. Vous pouvez maintenant connecter un compte de E-mail qui sera utilisé pour vous connecter à l'avenir.`,
        connect_now:`Connexion maintenant`,
        see_my_account:`Voir mon compte`,
        three_rd_login_merge_account:`L'adresse e-mail du compte tiers a été enregistrée, voulez-vous vous connecter et vous connecter directement avec cette adresse e-mail ?`,
        connect_log_in:`Associer & Se Coonecter`,
        create_an_account:`Créer un compte`,
        connect_account:`Associer le compte`,
        connect_account_info:`Après l'association, vous pouvez vous connecter avec ce compte.`,
        back_to_log_in:`Retour connexion`,
        create_password:`Créer un mot de passe`,
        create_now:`Créer maintenant`,
        password_login_subtitle:`Connexion sans mot de passe avec e-mail`,
        account_login:`Connexion au compte`,
        rights:`En créant ce compte, vous acceptez les <a href="https://www.eassiy.com/fr/terms.html">Conditions d'utilisation</a> et les <a href="https://www.eassiy.com/fr/privacy.html">Politique de confidentialité</a>`,
        passwordless_login_done:`Connexion sans mot de passe effectuée`,
        passwordless_login_info:`Félicitations, vous avez terminé avec succès la connexion sans mot de passe. Vous pouvez créer un mot de passe pour ce compte et vous connecter avec le compte et le mot de passe à l'avenir. <a href="/create-password" style="display: initial;">Créer maintenant</a>`,
        sign_up:`S'inscrire`,
        register_info:`Créez votre compte`,
        reset_now:`Réinitialiser maintenant`,
        forgot_password:`Mot de passe oublié`,
        reset_password_subtitle:`Utilisez l'e-mail de votre compte pour réinitialiser le mot de passe`,
        plan_products:`Plan & Produits`,
        nick_name:`Surnom`,
        email:`E-mail`,
        my_products:`Mes produits`,
        my_orders:`Mes commandes`,
        unlink:`Dissocier`,
        link:`Associer`,
        connected_accounts:`Comptes associés`,
        last_name:`Nom de famille`,
        first_name:`Prénom`,
        Gender:`Genre`,
        Birth:`Naissance`,
        Month:`Mois`,
        Year:`Année`,
        Country_Region:`Pays/Région`,
        Address:`Adresse`,
        Save:`Enregistrer`,
        Date:`Date`,
        Male:`Homme`,
        Female:`Femme`,
        Unspecified:`Non spécifié`,
        Security:`Sécurité`,
        change_password:`Changer le mot de passe`,
        change_now:`Changer maintenant`,
        connect_email:`Connecter email`,
        delete_account:`Supprimer le compte`,
        delete_account_info:`Lorsque votre compte est supprimé, toutes les données du forfait et des produits associées à votre compte seront définitivement supprimées et vous ne pourrez peut-être pas les récupérer. Nous vous recommandons de faire preuve de prudence.`,
        Delete:`Supprimer`,
        Logout:`Se déconnecter`,
        my_profile:`Mon profil`,
        guides_faqs:`Guides & FAQs`,
        More:`Plus`,
        guides:`Guides`,
        register:`S'inscrire`,
        hot_faq:`FAQ chaude`,
        Contents:`Contenu:`,
        contact_us:`Contactez-nous>>`,
        plan:`Plan`,
        unregistered:`Non enregistré`,
        buy_more:`Acheter plus`,
        buy_again:`Acheter à nouveau`,
        buy_now:`Acheter maintenant`,
        free_no_limit:`Gratuit et sans limite`,
        expired:`Expiré`,
        lifetime:`Durée de vie`,
        remain:`Rester`,
        day_s:`Jours)`,
        error_24801:`Échec de la demande (code d'erreur : 24801). Veuillez réessayer`,
        no_app_found:`Aucune application trouvée！<a href="/">Actualiser</a> ou <a href="https://www.eassiy.com/fr/">Accéder au site officiel</a>`,
        get_more:`Obtenir plus >>`,
        edit_photo:`Modifier photo`,
        select_photo:`Sélectionnez une photo`,
        change_photo:`Changer la photo`,
        cancel:`Annuler`,
        hide_password:`Masquer le mot de passe`,
        show_password:`Montrer le mot de passe`,
        zoom_in:`Agrandir`,
        zoom_out:`Dézoomer`,
        rotate:`Tourner`,
        horizontal_flip:`Retournement horizontal`,
        vertical_flip:`Retournement vertical`,
        country:`Pays`,
        country_1:`Sélectionnez votre pays/région`,
        country_2:`Île de land`,
        country_3:`Afghanistan`,
        country_4:`Albanie`,
        country_5:`Algérie`,
        country_6:`Samoa américaines`,
        country_7:`Andorre`,
        country_8:`Angola`,
        country_9:`Anguilla`,
        country_10:`Antarctique`,
        country_11:`Antigua-et-Barbuda`,
        country_12:`Argentine`,
        country_13:`Arménie`,
        country_14:`Aruba`,
        country_15:`Australie`,
        country_16:`L'Autriche`,
        country_17:`Azerbaïdjan`,
        country_18:`Bahreïn`,
        country_19:`Bangladesh`,
        country_20:`Barbade`,
        country_21:`Biélorussie`,
        country_22:`la Belgique`,
        country_23:`Belize`,
        country_24:`Bénin`,
        country_25:`Bermudes`,
        country_26:`Bhoutan`,
        country_27:`Bolivie`,
        country_28:`Bosnie-Herzégovine`,
        country_29:`Botswana`,
        country_30:`Île Bouvet`,
        country_31:`Brésil`,
        country_32:`Territoire britannique de l'océan Indien`,
        country_33:`Îles Vierges britanniques`,
        country_34:`Brunéi`,
        country_35:`Bulgarie`,
        country_36:`Burkina`,
        country_37:`Burundi`,
        country_38:`Cambodge`,
        country_39:`Cameroun`,
        country_40:`Canada`,
        country_41:`Cap-Vert`,
        country_42:`Pays-Bas caribéens`,
        country_43:`Îles Caïmans`,
        country_44:`République centrafricaine`,
        country_45:`Tchad`,
        country_46:`Chili`,
        country_47:`Chine`,
        country_48:`L'île de noël`,
        country_49:`Îles Cocos (Keeling)`,
        country_50:`Colombie`,
        country_51:`les Îles Cook`,
        country_52:`Costa Rica`,
        country_53:`"Côte d'Ivoire"`,
        country_54:`Croatie`,
        country_55:`Cuba`,
        country_56:`Chypre`,
        country_57:`République Tchèque`,
        country_58:`République Démocratique du Congo`,
        country_59:`Danemark`,
        country_60:`Djibouti`,
        country_61:`Dominique`,
        country_62:`République dominicaine`,
        country_63:`Équateur`,
        country_64:`Egypte`,
        country_65:`Le Salvador`,
        country_66:`Guinée Équatoriale`,
        country_67:`Érythrée`,
        country_68:`Estonie`,
        country_69:`Ethiopie`,
        country_70:`les îles Falkland`,
        country_71:`Îles Féroé`,
        country_72:`États fédérés de Micronésie`,
        country_73:`Fidji`,
        country_74:`Finlande`,
        country_75:`La France`,
        country_76:`Guyane Française`,
        country_77:`Polynésie française`,
        country_78:`Terres Australes Françaises`,
        country_79:`Gabon`,
        country_80:`Gambie`,
        country_81:`Géorgie`,
        country_82:`Allemagne`,
        country_83:`Ghana`,
        country_84:`Gibraltar`,
        country_85:`Grande-Bretagne (Royaume-Uni, Angleterre)`,
        country_86:`Grèce`,
        country_87:`Groenland`,
        country_88:`Grenade`,
        country_89:`Guadeloupe`,
        country_90:`Guam`,
        country_91:`Guatemala`,
        country_92:`Guernesey`,
        country_93:`Guinée`,
        country_94:`Guinée-Bissau`,
        country_95:`Guyane`,
        country_96:`Haïti`,
        country_97:`Île Heard et Îles McDonald`,
        country_98:`Honduras`,
        country_99:`Hong Kong`,
        country_100:`Hongrie`,
        country_101:`Islande`,
        country_102:`Inde`,
        country_103:`Indonésie`,
        country_104:`L'Iran`,
        country_105:`Irak`,
        country_106:`Irlande`,
        country_107:`île de Man`,
        country_108:`Israël`,
        country_109:`Italie`,
        country_110:`Jamaïque`,
        country_111:`Japon`,
        country_112:`Jersey`,
        country_113:`Jordan`,
        country_114:`Kazakhstan`,
        country_115:`Kenya`,
        country_116:`Kiribati`,
        country_117:`Koweit`,
        country_118:`Kirghizistan`,
        country_119:`Laos`,
        country_120:`Lettonie`,
        country_121:`Liban`,
        country_122:`Lesotho`,
        country_123:`Libéria`,
        country_124:`Libye`,
        country_125:`Liechtenstein`,
        country_126:`Lituanie`,
        country_127:`Luxembourg`,
        country_128:`Macao`,
        country_129:`Madagascar`,
        country_130:`Malawi`,
        country_131:`Malaisie`,
        country_132:`Maldives`,
        country_133:`Mali`,
        country_134:`Malte`,
        country_135:`Iles Marshall`,
        country_136:`Martinique`,
        country_137:`Mauritanie`,
        country_138:`Maurice`,
        country_139:`Mayotte`,
        country_140:`Mexique`,
        country_141:`Moldavie`,
        country_142:`Monaco`,
        country_143:`Mongolie`,
        country_144:`Monténégro`,
        country_145:`Montserrat`,
        country_146:`Maroc`,
        country_147:`Mozambique`,
        country_148:`Myanmar (Birmanie)`,
        country_149:`Namibie`,
        country_150:`Nauru`,
        country_151:`Népal`,
        country_152:`Pays-Bas`,
        country_153:`Nouvelle Calédonie`,
        country_154:`Nouvelle-Zélande`,
        country_155:`Nicaragua`,
        country_156:`Niger`,
        country_157:`Nigeria`,
        country_158:`Nioué`,
        country_159:`l'ile de Norfolk`,
        country_160:`Corée du Nord`,
        country_161:`Îles Mariannes du Nord`,
        country_162:`Norvège`,
        country_163:`Oman`,
        country_164:`Pakistan`,
        country_165:`Palaos`,
        country_166:`Territoires palestiniens`,
        country_167:`Panama`,
        country_168:`Papouasie Nouvelle Guinée`,
        country_169:`Paraguay`,
        country_170:`Pérou`,
        country_171:`Îles Pitcairn`,
        country_172:`Pologne`,
        country_173:`le Portugal`,
        country_174:`Porto Rico`,
        country_175:`Qatar`,
        country_176:`République de Macédoine (ARYM)`,
        country_177:`République du Congo`,
        country_178:`Réunion`,
        country_179:`Roumanie`,
        country_180:`Fédération Russe`,
        country_181:`Rwanda`,
        country_182:`Saint-Barthélemy`,
        country_183:`Saint-Martin (France)`,
        country_184:`Saint-Pierre-et-Miquelon`,
        country_185:`Samoa`,
        country_186:`Saint Marin`,
        country_187:`Sao Tomé et Principe`,
        country_188:`Arabie Saoudite`,
        country_189:`Sénégal`,
        country_190:`Serbie`,
        country_191:`les Seychelles`,
        country_192:`Sierra Leone`,
        country_193:`Singapour`,
        country_194:`Slovaquie`,
        country_195:`Slovénie`,
        country_196:`Les îles Salomon`,
        country_197:`Somalie`,
        country_198:`Afrique du Sud`,
        country_199:`Géorgie du Sud et l'île de Sandwich du Sud`,
        country_200:`Corée du Sud`,
        country_201:`Soudan du sud`,
        country_202:`Espagne`,
        country_203:`Sri Lanka`,
        country_204:`Sainte-Hélène & Dépendances`,
        country_205:`Saint-Kitts-et-Nevis`,
        country_206:`Sainte Lucie`,
        country_207:`Saint-Vincent-et-les Grenadines`,
        country_208:`Soudan`,
        country_209:`Surinam`,
        country_210:`Swaziland`,
        country_211:`Suède`,
        country_212:`la Suisse`,
        country_213:`Syrie`,
        country_214:`Taïwan`,
        country_215:`Tadjikistan`,
        country_216:`Tanzanie`,
        country_217:`Modèle : Données du pays SJM Svalbard`,
        country_218:`Thaïlande`,
        country_219:`Les Bahamas`,
        country_220:`Les Comores`,
        country_221:`Les Philippines`,
        country_222:`Timor-Leste (Timor oriental)`,
        country_223:`Aller`,
        country_224:`Tokélaou`,
        country_225:`Tonga`,
        country_226:`Trinité-et-Tobago`,
        country_227:`Tunisie`,
        country_228:`Turquie`,
        country_229:`Turkménistan`,
        country_230:`Îles Turques et Caïques`,
        country_231:`Tuvalu`,
        country_232:`Ouganda`,
        country_233:`Ukraine`,
        country_234:`Emirats Arabes Unis`,
        country_235:`Îles mineures éloignées des États-Unis`,
        country_236:`États-Unis d'Amérique (États-Unis)`,
        country_237:`Îles Vierges des États-Unis`,
        country_238:`Uruguay`,
        country_239:`Ouzbékistan`,
        country_240:`Vanuatu`,
        country_241:`Cité du Vatican (Saint-Siège)`,
        country_242:`Venezuela`,
        country_243:`Viêt Nam`,
        country_244:`Wallis et Futuna`,
        country_245:`Sahara occidental`,
        country_246:`Yémen`,
        country_247:`Zambie`,
        country_248:`Zimbabwe`,
        google_login:`Connexion avec Google`,
        footer:`Copyright © 2023 Aiseesoft Studio. Tous les droits sont réservés.`,
        State:`État`,
        Activation_code:`Code d'activation`,
        Question:`Répertoriez toutes les applications auxquelles vous vous êtes connecté`,
        Copy_complete:`Copie terminée`,

        
        store:`Boutique`,
        resource:`Ressource`,
        support:`Support`,
        video_converter:`Convertisseur vidéo`,
        multimedia:`Multimédia`,
        data_recovery:`Récupération de données`,
        utility:`Utilitaire`,
        free_tools:`Outils gratuits`,
        for_windows:`Pour les fenêtres`,
        for_mac:`Pour Mac`,
        pdf_solutions:`Solutions PDF`,
        data_manager:`Manager de données`,
        other_tools:`D'autres Outils`,
        free_online_solutions:`Solutions en ligne gratuites`,
        free_online_tools:`Outils en ligne gratuits`,
        desktop_freeware:`Logiciel de bureau gratuit`,
        product_1:`Convertisseur vidéo ultime`,
        product_2:`Convertisseur vidéo total`,
        product_3:`Convertisseur MTS`,
        product_4:`Convertisseur 4K`,
        product_5:`Convertisseur MXF`,
        product_6:`Convertisseur 3D`,
        product_7:`Convertisseur vidéo Mac ultime`,
        product_8:`Convertisseur vidéo pour Mac`,
        product_9:`Convertisseur MTS pour Mac`,
        product_10:`Convertisseur 4K pour Mac`,
        product_11:`Convertisseur MXF pour Mac`,
        product_12:`Convertisseur 3D pour Mac`,
        product_13:`Lecteur Blu-ray`,
        product_14:`Vidéo Enhancer`,
        product_15:`Vidéo Editor`,
        product_16:`Slideshow Créateur`,
        product_17:`Créateur Blu-ray`,
        product_18:`Burnova - Créateur de DVD Pro`,
        product_19:`Créateur de DVD`,
        product_20:`Lecteur Blu-ray Mac`,
        product_21:`Mac Vidéo Enhancer`,
        product_22:`Vidéo Editor pour Mac`,
        product_23:`Créateur de DVD pour Mac`,
        product_24:`Convertisseur MP4 pour Mac`,
        product_25:`Récupération de données Windows`,
        product_26:`FoneLab Récupération de données iPhone`,
        product_27:`FoneLab Récupération du système iOS`,
        product_28:`FoneLab Sauvegarde &amp; Restauration de données iOS`,
        product_29:`FoneLab Récupération de données Android`,
        product_30:`Extraction de données Android cassée`,
        product_31:`Sauvegarde &amp; Restauration de données Android`,
        product_32:`Récupération de données Mac`,
        product_33:`Mac FoneLab Récupération de données iPhone`,
        product_34:`Mac FoneLab Récupération du système iOS`,
        product_35:`Mac FoneLab Sauvegarde &amp; Restauration de données iOS`,
        product_36:`Mac FoneLab Récupération de données Android`,
        product_37:`Mac FoneLab Sauvegarde &amp; Restauration de données Android`,
        product_38:`Convertisseur PDF Ultime`,
        product_39:`PDF Converter Ultimate pour Mac`,
        product_40:`Fusion de PDF`,
        product_41:`Convertisseur Mac PDF en ePub`,
        product_42:`Nettoyeur Mac`,
        product_43:`FoneTrans`,
        product_44:`Transfert WhatsApp pour iOS`,
        product_45:`MobieSync`,
        product_46:`FoneEraser`,
        product_47:`iPhone Cleaner`,
        product_48:`Convertisseur PPT en vidéo`,
        product_49:`Convertisseur HEIC`,
        product_50:`iPhone Unlocker`,
        product_51:`Convertisseur vidéo gratuit en ligne`,
        product_52:`Convertisseur audio gratuit en ligne`,
        product_53:`Compresseur vidéo gratuit en ligne`,
        product_54:`Fusion vidéo gratuite en ligne`,
        product_55:`Convertisseur HEIC gratuit`,
        product_56:`Compresseur d'images gratuit`,
        product_57:`Suppresseur de filigrane gratuit`,
        product_58:`Compresseur PDF gratuit en ligne`,
        product_59:`Upscaler d'images gratuit en ligne`,
        product_60:`Supprimer l'arrière-plan gratuit en ligne`,
        product_61:`Convertisseur d'images gratuit en ligne`,
        product_62:`Convertisseur PDF PNG gratuit en ligne`,
        product_63:`Convertisseur vidéo gratuit`,
        product_64:`Convertisseur gratuit de vidéo en GIF`,
        product_65:`Récupération de données Android gratuite`,
        product_66:`Créateur de diaporamas`,
        product_67:`Éditeur vidéo gratuit`,
        product_68:`Visionneuse PDF gratuite`,
        product_69:`Enregistreur d'écran`,
        product_70:`Enregistreur d'écran Mac`,
        product_71:`Enregistreur d'écran gratuit en ligne`,
        product_72:`Enregistreur audio gratuit en ligne`,
        product_73:`Téléphone Miroir`,
        product_74:`AnyCoord - Changeur d'emplacement`,
        product_75:`Gestionnaire de mots de passe iPhone`,
        product_76:`Compresseur audio gratuit en ligne`,
        product_77:`Découpeur vidéo gratuit en ligne`,
        product_78:`Recadrage vidéo gratuit en ligne`,
        product_79:`Rotateur vidéo gratuit en ligne`,
        product_80:`Boucleur vidéo gratuit en ligne`,
        product_81:`Convertisseur PDF JPG gratuit en ligne`,
        product_81:`Convertisseur de mots JPG gratuit en ligne`,
        category_1:`Retouche photo`,
        category_2:`Montage vidéo`,
        category_3:`La gomme`,
        category_4:`Enregistreur multimédia`,
        posted_by:`posté par`,
        to:`à`,
        author_1:`Ashley Mae`,
        author_2:`Jenny Ryan`,
        author_3:`Nicole Jones`,
        author_4:`Amanda Marron`,
        article_1:`Comment supprimer efficacement le texte des images`,
        article_2:`Comment augmenter la taille de l'image de Ko à Mo sans endommager la qualité`,
        article_3:`Comment supprimer le filigrane Getty Images sur Windows et Mac`,
        article_4:`La meilleure méthode pour convertir une image basse résolution en haute résolution en ligne gratuitement`,
        article_5:`Guide TikTok | Voici comment faire un collage vidéo sur TikTok`,
        article_6:`5 façons simples de combiner des fichiers VOB que vous devez connaître`,
        article_7:`Comment améliorer la vidéo et obtenir facilement une résolution plus élevée`,
        article_8:`Comment combiner des fichiers MP4 sur MacBook Pro/iMac/MacBook et plus`,
        article_9:`2 façons simples de supprimer l'historique des achats sur iPhone [Résolu]`,
        article_10:`Comment supprimer définitivement les images Snapchat du chat et des souvenirs`,
        article_11:`Comment supprimer les suggestions et l'historique de recherche Spotlight sur iPhone/iPad`,
        article_12:`[Résolu] Comment effacer le cache Instagram sur iPhone avec 3 manières simples`,
        article_13:`[Résolu] Comment réparer QuickTime Player ne fonctionne pas sur Mac`,
        article_14:`Comment enregistrer l'audio du système sur Mac (Big Sur/Catalina/Mojave …)`,
        article_15:`Moyens efficaces pour réparer l'enregistrement d'écran PowerPoint ne fonctionne pas`,
        article_16:`Comment enregistrer de l'audio avec VLC [Résolu]`,
        faq_title_1:`1. Comment corriger l'erreur 4104 ?`,
        faq_title_2:`2. Quand puis-je récupérer mon argent une fois la demande de remboursement approuvée ?`,
        faq_title_3:`3. Qu'est-ce qu'un « abonnement d'un an » et comment désactiver le « service de renouvellement automatique » ?`,
        faq_title_4:`4. Puis-je annuler ma commande après avoir placé un produit Aiseesoft ?`,
        faq_title_5:`5. Comment soumettre un avis ?`,
        faq_info_1:`Cela est dû à la licence limitée que vous avez. Nous adoptons une licence unique en cas de vol du code d'enregistrement et empêchons l'utilisation abusive du code d'enregistrement. Ainsi, le code Reg que vous avez obtenu est une licence unique qui ne peut être utilisée que sur un seul ordinateur. Si vous l'utilisez sur plusieurs ordinateurs, il y aura l'erreur 4104. Vous ne pouvez pas l'utiliser tant que nous ne l'avons pas réinitialisé pour vous.`,
        faq_info_2:`Si vous modifiez ou réinstallez votre ordinateur, nous pouvons le réinitialiser pour vous. Veuillez contacter le service client : <a href="mailto:support@eassiy.com">support@eassiy.com</a> pour demander la réinitialisation du code d'enregistrement.`,
        faq_info_3:`Une fois votre demande de remboursement approuvée, il faut normalement 3 à 7 jours ouvrables pour que le crédit apparaisse sur votre compte. Parfois, pendant les vacances, cela peut prendre plus de temps, mais cela ne devrait pas prendre plus de 10 jours. Si vous n'avez pas reçu le remboursement 20 jours après l'approbation, veuillez nous contacter à <a href="mailto:support@eassiy.com">support@eassiy.com</a> pour vérifier le remboursement.`,
        faq_info_4:`Le service de renouvellement automatique signifie que si vous achetez un abonnement d'un an, la plate-forme de paiement facturera le montant automatiquement (y compris les taxes applicables) à l'expiration de la licence du produit. Mais la plateforme de paiement (2Checkout) vous enverra une lettre pour vous rappeler que la commande va expirer 7 jours avant l'expiration. L'e-mail comprendra également des instructions sur la façon de désactiver le « Service de renouvellement automatique ».`,
        faq_info_5:`Étant donné que la commande est traitée automatiquement, il n'est pas permis d'annuler la commande. En outre, la version d'essai gratuite vous permettra d'en savoir plus sur le programme Aiseesoft et de vérifier s'il peut répondre à vos besoins avant de l'acheter.`,
        faq_info_6:`Si vous souhaitez vraiment obtenir un remboursement, veuillez consulter notre <a href="https://www.eassiy.com/fr/refund-policy.html">Politique de remboursement</a>.`,
        faq_info_7:`Nous apprécions votre temps et votre attention pour nous soumettre un avis. Contactez-nous (<a href="mailto:support@eassiy.com">support@eassiy.com</a>) pour soumettre votre avis et nous considérerons sérieusement votre avis pour les mises à jour de nos produits.`,
        change_password_success:`Le mot de passe a été changé avec succès`,
        successful_login_title:`Connexion réussie`,
        product_page:`Fiche produit>>`,
        successful_login_info:`Connexion terminée. Veuillez fermer la page actuelle et revenir à l'onglet d'origine pour continuer le processus suivant. La page en cours se fermera automatiquement dans 5 secondes. Si la fermeture automatique ou la fermeture en cliquant sur le bouton "Terminé" échoue, veuillez fermer cet onglet directement.`,
        successful_login_info_firefox:`Connexion terminée. Veuillez fermer la page actuelle et revenir à l'onglet d'origine pour continuer le processus suivant.`,
        my_account:`Mon compte`,
        my_history:`Mon histoire`,
        remove_watermark:`Supprimer filigrane`,
        no_history:`Pas d'historique`,
        history_all:`Tout sélectionner`,
        history_open:`Ouvert`,
        history_down:`Télécharger`,
        history_delete:`Supprimer`,
        history_clear:`Effacer invalide`,
        images:`images)`,
        use_this_function:`Utiliser cette fonction>>`,
        hd_downloading:`Avantages du téléchargement d'images originales HD :`,
        lifetimeRemaining:`Durée de vie restante`,
        Remaining:`Restant`,
        email_verification:`vérification de l'E-mail`,
        email_verification_info:`Nous avons envoyé le message de vérification à votre adresse e-mail <span class="email"></span>, et veuillez terminer la vérification. Après vérification, les avantages seront synchronisés automatiquement.`,
        wrong_email:`Mauvaise adresse email?`,
        click_here_to_modify:`Cliquez ici pour modifier`,
        get_more_help:`Obtenir plus d'aide ?`,
        click_here:`Cliquez ici`,
        email_verification_info_success:`Félicitations pour la vérification de votre compte de messagerie.`,
        email_verification_info_error:`La vérification a échoué en raison de l'expiration du lien.`,
        registration_succeeded:`Inscription réussie`,
        registration_succeeded_info_1:`Toutes nos félicitations! Vous vous êtes bien inscrit. Nous avons envoyé le message de vérification à votre adresse e-mail <span class="email"></span>, et veuillez terminer la vérification pour utiliser les avantages de cet e-mail.`,
        registration_succeeded_info_2:`Cliquez sur "Terminé" pour revenir à la page d'accueil et utiliser ce produit.`,
        registration_succeeded_info_3:`Cliquez sur "Terminé" pour fermer la page en cours et revenir à la page d'accueil pour les opérations suivantes. Si la fermeture échoue, veuillez fermer manuellement l'onglet.`,
        verify_email:`Vérifier les courriels`,
        registered_email_not_verified:`L'e-mail enregistré n'a pas été vérifié, veuillez le vérifier immédiatement.`,
        email_verification_time_1:`Vous n'avez pas reçu d'e-mail de vérification ?`,
        email_verification_time_2:`Après`,
        email_verification_time_3:`Cliquez ici pour le ré-expédier`,
        error_26301:`Code d'erreur : 26301, veuillez nous contacter`,
        error_26302:`Code d'erreur : 26302, veuillez nous contacter`,
        error_26303:`Erreur de format d'e-mail (code d'erreur : 26303). Veuillez le saisir à nouveau`,
        error_26304:`Un mot de passe supérieur à 8 caractères est recommandé (code d'erreur : 26304)`,
        error_26305:`Reuqest a échoué (code d'erreur : 26305). Veuillez réessayer`,
        error_26306:`L'e-mail a été enregistré, veuillez <a href="/login">aller à la connexion</a>`,
        error_26307:`Reuqest a échoué (code d'erreur : 26307). Veuillez réessayer`,
        error_26308:`Reuqest a échoué (code d'erreur : 26308). Veuillez réessayer`,
        error_26401:`Code d'erreur : 26401, veuillez réessayer`,
        error_26402:`L'e-mail a été vérifié (code d'erreur : 26402), veuillez réessayer`,
        error_26403:`Reuqest a échoué (code d'erreur : 26403). Veuillez réessayer`,
        error_26404:`Échec de la demande (code d'erreur : 26404). Veuillez réessayer`,
        error_26501:`Code d'erreur : 26501, veuillez nous contacter`,
        error_26502:`Code d'erreur : 26502, veuillez nous contacter`,
        error_26503:`Erreur de format d'e-mail (code d'erreur : 26503). Veuillez le saisir à nouveau`,
        error_26504:`Reuqest a échoué (code d'erreur : 26504). Veuillez réessayer`,
        error_26505:`L'e-mail n'a pas été enregistré, veuillez <a href="/register">l'enregistrer d'abord</a>`,
        error_26506:`L'e-mail a été vérifié.`,
        error_26507:`Reuqest a échoué (code d'erreur : 26507). Veuillez réessayer`,
        error_26508:`La vérification a échoué (code d'erreur : 26508), veuillez réessayer`,
        error_26509:`Échec de la demande (code d'erreur : 26509), veuillez réessayer`,
        error_26510:`Code d'erreur : 26510, veuillez nous contacter`,
        error_26601:`Code d'erreur : 26601, veuillez nous contacter`,
        error_26602:`Code d'erreur : 26602, veuillez nous contacter`,
        error_26603:`Reuqest a échoué (code d'erreur : 26603). Veuillez réessayer`,
        error_26604:`Code d'erreur : 26604, veuillez nous contacter`,
        error_26605:`Code d'erreur : 26605, veuillez nous contacter`,
        error_26701:`Code d'erreur : 26701, veuillez nous contacter`,
        error_26702:`Reuqest a échoué (code d'erreur : 26702). Veuillez réessayer`,
        error_26703:`Code d'erreur : 26703, veuillez nous contacter`,
        error_26704:`Code d'erreur : 26704, veuillez nous contacter`,
        error_26705:`Attendez la connexion (code d'erreur : 26705). Veuillez réessayer`,
        no_cookie:`Vous avez activé la fonction Bloquer tous les cookies dans votre navigateur, vous ne pouvez donc pas vous connecter. Veuillez vous rendre dans Paramètres pour cocher la case Autoriser tous les cookies.`,
        error_26801:`Code d'erreur : 26801, veuillez nous contacter`,
        error_26802:`Code d'erreur : 26802, veuillez nous contacter`,
        error_26803:`La demande a échoué (code d'erreur : 26803). Veuillez réessayer`,
        error_26804:`Échec de la demande (code d'erreur : 26804). Veuillez réessayer`,
        error_order:`Échec de la demande (code d'erreur : 27098), veuillez réessayer !`,
        error_order1:`La demande de commande est incomplète (code d'erreur :`,
        error_order2:`）, veuillez actualiser et réessayer.`,
        modify_email_title:`Modifier l'e-mail`,
        modify_email_info:`Vous pouvez utiliser l'e-mail modifié pour vous connecter à votre compte.`,
        images_per:`Images par`,
        error_26101:`Erreur : 26101. Veuillez nous contacter`,
        error_26102:`Erreur : 26102. Veuillez nous contacter`,
        error_26103:`Échec de la demande (code d'erreur : 26103). Veuillez réessayer`,
        error_26104:`Code d'erreur : 26104, veuillez réessayer`,
        error_26105:`Code d'erreur : 26105, veuillez réessayer`,
        error_26106:`Échec de la suppression (code d'erreur : 26106). Veuillez réessayer`,
        error_26201:`Erreur : 26201. Veuillez nous contacter`,
        error_26202:`Échec de la demande (code d'erreur : 26202). Veuillez réessayer`,
        error_26001:`Erreur : 26001. Veuillez nous contacter`,
        error_26002:`Erreur : 26002. Veuillez nous contacter`,
        error_26003:`Erreur : 26003. Veuillez nous contacter`,
        error_26004:`Erreur : 26004. Veuillez nous contacter`,
        error_26005:`Échec de la demande (code d'erreur : 26005). Veuillez réessayer`,
        error_26006:`Code d'erreur : 26006, veuillez réessayer`,
        error_26008:`Erreur : 26008. Veuillez nous contacter`,
        go_to_the_home_page:`Aller à la page d'accueil`,
        error_27101:`Échec de la demande (code d'erreur : 27101). Veuillez réessayer`,
        error_27201:`Code d'erreur : 27201, veuillez nous contacter`,
        error_27202:`Code d'erreur : 27202, veuillez réessayer`,
        error_27203:`Échec de la demande (code d'erreur : 27203). Veuillez réessayer`,
        error_27204:`Code invalide (code d'erreur : 27204).`,
        error_27205:`Échec de la demande (code d'erreur : 27205). Veuillez réessayer`,
        error_27206:`Échec de la requête (code d'erreur : 27206). Veuillez réessayer`,
        error_27207:`Échec de la demande (code d'erreur : 27207). Veuillez réessayer`,
        no_history_found:`Vous n'avez utilisé aucun outil! <a href="/">Actualiser</a> ou <a href="https://www.eassiy.com/fr/">Accéder au site officiel</a>`,
        error_25301:`Erreur : 25301. Veuillez nous contacter`,
        error_25302:`Erreur : 25302. Veuillez nous contacter`,
        error_25303:`Échec de la demande (code d'erreur : 25303). Veuillez réessayer`,
        error_25304:`Échec de la demande (code d'erreur : 25304). Veuillez réessayer`,
        error_25305:`Échec de la demande (code d'erreur : 25305). Veuillez réessayer`,
        error_25306:`Échec de la demande (code d'erreur : 25306). Veuillez réessayer`,
        image_upscaler_p:`Télécharger des images sans filigrane:`,
    },
    it:{
        overtime_1:`Codice di errore:`,
        overtime_2:`, per favore riprova`,
        isnetwork:`Errore Internet. Si prega di controllare e riprovare`,
        email_placeholder:`E-mail`,
        email_empty:`Si prega di inserire l'e-mail`,
        email_not_valid:`L'email non è valida`,
        email_not_valid_1:`Per favore inserisci la tua email`,
        email_not_valid_2:`L'email non è valida, utilizza un indirizzo diverso.`,
        email_not_valid_3:`Nessun input e-mail`,
        password_placeholder:`Parola d'ordine`,
        password_empty:`Si prega di inserire la password`,
        password_not_valid:`Account o password errati`,
        password_not_valid_1:`È richiesta una password superiore a 8 caratteri`,
        password_not_valid_2:`Si prega di creare una password`,
        password_placeholder_1:`crea la tua password`,
        password_placeholder_2:`Conferma la tua password`,
        password_placeholder_3:`Crea nuova password`,
        password_placeholder_4:`Conferma la nuova password`,
        password_placeholder_5:`Inserisci la vecchia password`,
        copy_password_empty:`Si prega di confermare la password`,
        copy_password_not_valid:`Si prega di confermare la password`,
        copy_passwords_inconsistent:`La conferma della password non corrisponde`,
        code_empty:`Inserisci il codice di verifica`,
        code_not_valid:`Codice di verifica non valido`,
        code_placeholder:`Codice di verifica`,
        not_received_code:`Se la tua casella di posta non riceve il codice di verifica da molto tempo, richiedi di nuovo il codice di verifica.`,
        get_first_code:`Si prega di ottenere prima il codice di verifica.`,
        connect_your_email_placeholder:`Collega la tua email`,
        last_name_placeholder:`Per favore inserisci il tuo cognome`,
        first_name_placeholder:`Per favore immettere il proprio Nome`,
        address_placeholder:`Per favore inserisci il tuo indirizzo`,
        no_code_text:`Abbiamo inviato un codice di verifica. Inserisci il tuo codice. <span class='tips'>Non hai ricevuto il codice?`,
        no_code_text_1:`1. Assicurati che l'indirizzo e-mail sia valido e che possa ricevere e-mail.`,
        no_code_text_2:`2.Se l'e-mail viene inviata automaticamente dal sistema, potrebbe essere contrassegnata come spam o posta indesiderata. Si prega di verificare se l'e-mail si trova nella cartella Cestino.`,
        no_code_text_3:`3.Non riesci a risolvere il tuo problema?`,
        no_code_text_3_span:`Allora clicca qui per contattarci.`,
        order_no:`Non hai acquistato alcun prodotto. Per qualsiasi domanda, <a href="https://www.eassiy.com/it/buy/background-remover.html">Acquista ora</a> oppure <a href="https://www.eassiy.com/it/support/"> Contattaci</a>`,
        error_24901:`L'account corrente non ha un indirizzo email collegato e non riesce a trovare gli ordini. Si prega di collegare un'e-mail.`,
        user_guide:`Guida per l'utente>>`,
        download:`Scarica`,
        order_number:`Numero d'ordine`,
        Refund:`Rimborso`,
        Disabled:`Disabilitato`,
        Normal:`Normale`,
        Modify:`Modificare`,
        Modify_1:`Modifica>>`,
        Connect:`Collegare`,
        unlink_success:`Scollega con successo`,
        connect_success:`Connetti con successo`,
        feedback_title:`Grazie per il tuo feedback. Per favore lascia il tuo problema e ti risponderemo entro 24 ore.`,
        feedback_thank_you:`Grazie!<br />Il tuo feedback è stato inviato con successo.`,
        feedback_email:`Inserisci la tua email qua!`,
        feedback_content:`Lascia qui qualsiasi problema o suggerimento che hai riscontrato.`,
        feedback_submit:`Invia`,
        form_contents:`Non hai inserito alcuna descrizione. Inseriscilo e invia di nuovo.`,
        old_password:`Si prega di inserire la vecchia password`,
        new_password:`Si prega di creare una nuova password`,
        old_new_password:`La nuova password non può essere uguale a quella vecchia`,
        incorrect_password:`password errata`,
        delete_no:`Cancella ora`,
        Caps:`Blocco maiuscolo attivo`,
        Get:`Ottenere`,
        Done:`Fatto`,
        error_20001:`Errore: 20001. Effettua nuovamente l'accesso.`,
        error_20002:`Errore: 20002. Effettua nuovamente l'accesso.`,
        error_20003:`Errore: 20003. Effettua nuovamente l'accesso.`,
        error_20004:`Richiesta non riuscita (codice errore: 20004). Si prega di riprovare.`,
        error_20005:`La sessione di accesso è scaduta (errore: 20005). Per favore esegui l'accesso di nuovo.`,
        error_20006:`Richiesta non riuscita (codice errore: 20006). Si prega di riprovare.`,
        error_20007:`La sessione di accesso è scaduta (Errore: 20007). Per favore esegui l'accesso di nuovo.`,
        error_20008:`La sessione di accesso è scaduta (Errore: 20008). Per favore esegui l'accesso di nuovo.`,
        error_20009:`La sessione di accesso è scaduta (Errore: 20009). Per favore esegui l'accesso di nuovo.`,
        error_20101:`Per favore inserisci la tua email (codice errore: 20101)`,
        error_20102:`L'e-mail non è valida (codice errore: 20102)`,
        error_20103:`Richiesta non riuscita (codice errore: 20103). Per favore riprova`,
        error_20104:`L'email è già in uso, per favore <a href="/login">accedi</a> o registrati con una nuova`,
        error_20105:`Richiesta non riuscita (codice errore: 20105). Per favore riprova`,
        error_20106:`Impossibile inviare l'e-mail, riprovare`,
        error_20201:`Inserisci la tua email (codice errore: 20201)`,
        error_20202:`Inserisci la tua password (codice di errore: 20202)`,
        error_20203:`Inserisci il codice di verifica (codice errore: 20203)`,
        error_20204:`Email non valida (codice errore: 20204)`,
        error_20205:`È richiesta una password superiore a 8 caratteri (codice errore: 20205)`,
        error_20206:`Richiesta non riuscita (codice errore: 20206). Per favore riprova`,
        error_20207:`Codice di verifica non valido`,
        error_20208:`Richiesta non riuscita (codice errore: 20208). Per favore riprova`,
        error_20209:`Richiesta non riuscita (codice errore: 20209). Per favore riprova`,
        error_20301:`Per favore inserisci la tua email (codice errore: 20301)`,
        error_20302:`Errore: 20302. Contattaci`,
        error_20303:`Email non valida (codice errore: 20303)`,
        error_20304:`Richiesta non riuscita (codice errore: 20304). Per favore riprova`,
        error_20305:`L'account non esiste. Si prega di reinserire o <a href="/register">Crealo</a> prima.`,
        error_20306:`Nessuna password ancora. Usa <a href="/passwordless-login">Accesso senza password</a> o <a href="/create-password">imposta una password</a> e accedi.`,
        error_20308:`Richiesta non riuscita (codice errore: 20308). Per favore riprova`,
        error_20401:`Impossibile disconnettersi (codice errore: 20401). Per favore riprova`,
        error_20501:`Per favore inserisci la tua email (codice errore: 20501)`,
        error_20502:`Email non valida (codice errore: 20502)`,
        error_20503:`Richiesta non riuscita (codice errore: 20503). Per favore riprova`,
        error_20504:`Impossibile inviare l'e-mail. Si prega di riprovare.`,
        error_20601:`Per favore inserisci la tua email (codice errore: 20601)`,
        error_20602:`Inserisci il codice di verifica (codice errore: 20602)`,
        error_20603:`Email non valida (codice errore: 20603)`,
        error_20604:`Richiesta non riuscita (codice errore: 20604). Per favore riprova`,
        error_20606:`Richiesta non riuscita (codice errore: 20606). Per favore riprova`,
        error_20607:`Richiesta non riuscita (codice errore: 20607). Per favore riprova`,
        error_20608:`Richiesta non riuscita (codice errore: 20608). Per favore riprova`,
        error_20701:`Per favore inserisci la tua email (codice errore: 20701)`,
        error_20702:`Email non valida (codice errore: 20702)`,
        error_20703:`Richiesta non riuscita (codice errore: 20703). Per favore riprova`,
        error_20704:`L'account non esiste. Si prega di reinserire o <a href="/register">Crealo</a> prima.`,
        error_20705:`Richiesta non riuscita (codice errore: 20705). Per favore riprova`,
        error_20706:`Impossibile inviare l'e-mail. Per favore riprova`,
        error_20801:`Per favore inserisci la tua email (codice errore: 20801)`,
        error_20802:`Errore: 20802. Contattaci`,
        error_20803:`Inserisci il codice di verifica (codice errore: 20803)`,
        error_20804:`Email non valida (codice errore: 20804)`,
        error_20805:`È richiesta una password superiore a 8 caratteri (codice errore: 20805)`,
        error_20806:`Richiesta non riuscita (codice errore: 20806). Per favore riprova`,
        error_20808:`Richiesta non riuscita (codice errore: 20808). Per favore riprova`,
        error_20901:`Richiesta non riuscita (codice errore: 20901). Per favore riprova`,
        error_20902:`Richiesta non riuscita (codice errore: 20902). Per favore riprova`,
        error_21000:`Le modifiche vengono salvate`,
        error_21001:`Nessuna informazione inviata (codice errore: 21001)`,
        error_21002:`Richiesta non riuscita (codice errore: 21002). Per favore riprova`,
        error_21101:`Per favore inserisci la tua email (codice errore: 21101)`,
        error_21102:`Email non valida (codice errore: 21102)`,
        error_21103:`Richiesta non riuscita (codice errore: 21103), riprovare di nuovo`,
        error_21104:`L'email è già collegata, usane una nuova`,
        error_21105:`Richiesta non riuscita (codice errore: 21105), riprovare di nuovo`,
        error_21106:`Impossibile inviare l'e-mail. Per favore riprova`,
        error_21201:`Per favore inserisci la tua email (codice errore: 21201)`,
        error_21202:`Inserisci il codice di verifica (codice errore: 21202)`,
        error_21203:`Email non valida (codice errore: 21203)`,
        error_21204:`Errore: 21204. Contattaci`,
        error_21205:`Errore: 21205. Contattaci`,
        error_21206:`È richiesta una password superiore a 8 caratteri (codice errore: 21206)`,
        error_21207:`Richiesta non riuscita (codice errore: 21207). Per favore riprova`,
        error_21209:`Richiesta non riuscita (codice errore: 21209). Per favore riprova`,
        error_21301:`Si prega di inserire la vecchia password (codice di errore: 21301)`,
        error_21302:`Si prega di creare una nuova password (codice di errore: 21302)`,
        error_21303:`La nuova password non può essere uguale a quella vecchia. (Errore: 21303)`,
        error_21304:`È richiesta una password superiore a 8 caratteri (codice errore: 21304)`,
        error_21306:`Richiesta non riuscita (codice errore: 21306). Per favore riprova`,
        error_21402:`Richiesta non riuscita (codice errore: 21402). Per favore riprova`,
        error_21403:`Impossibile inviare il codice di verifica. Per favore invialo di nuovo`,
        error_21500:`L'account è stato cancellato`,
        error_21501:`Inserisci il codice di verifica (codice errore: 21501)`,
        error_21502:`La sessione di accesso è scaduta (Errore: 21502). Per favore esegui l'accesso di nuovo.`,
        error_21503:`Richiesta non riuscita (codice errore: 21503). Per favore riprova`,
        error_21505:`Richiesta non riuscita (codice errore: 21505), riprovare`,
        error_21601:`Errore: 20601. Contattaci`,
        error_21602:`Verifica non valida (errore: 20602). Si prega di riprovare.`,
        error_21603:`Errore: 20603. Riprova`,
        error_21604:`Richiesta non riuscita (codice errore: 21604). Per favore riprova`,
        error_21606:`Richiesta non riuscita (codice errore: 21606). Per favore riprova`,
        error_21611:`Richiesta non riuscita (codice errore: 21611). Per favore riprova`,
        error_21801:`Errore: 21801. Contattaci`,
        error_21802:`Richiesta non riuscita (errore: 21802). Per favore riprova`,
        error_21803:`Errore: 21803. Riprova`,
        error_21804:`Richiesta non riuscita (codice errore: 21804). Per favore riprova`,
        error_21806:`Errore: 21806. Riprova`,
        error_21807:`Errore: 21807. Contattaci`,
        error_21808:`Errore: 21808. Contattaci`,
        error_21809:`Errore: 21809. Contattaci`,
        error_21810:`Errore: 21810. Contattaci`,
        error_21811:`Errore: 21811. Contattaci`,
        error_21812:`Errore: 21812. Contattaci`,
        error_21813:`Richiesta non riuscita (codice errore: 21813). Per favore riprova`,
        error_21814:`Errore: 21814. Contattaci`,
        error_21815:`Richiesta non riuscita (codice errore: 21815). Per favore riprova`,
        error_21816:`Errore: 21816. Contattaci`,
        error_21817:`Errore: 21817. Contattaci`,
        error_21818:`Errore: 21818. Contattaci`,
        error_21819:`Richiesta non riuscita (codice errore: 21819). Per favore riprova`,
        error_21820:`Errore: 21820. Contattaci`,
        error_21821:`Errore: 21821. Contattaci`,
        error_21822:`Errore: 21822. Contattaci`,
        error_21823:`Richiesta non riuscita (codice errore: 21823). Per favore riprova`,
        error_21824:`Richiesta non riuscita (codice errore: 21824). Per favore riprova`,
        error_21825:`Richiesta non riuscita (codice errore: 21825). Per favore riprova`,
        error_21826:`Richiesta non riuscita (codice errore: 21826). Per favore riprova`,
        error_21901:`Errore: 21901. Contattaci`,
        error_21902:`Richiesta non riuscita (codice errore: 21902). Per favore riprova`,
        error_21903:`Lo stato dell'account è cambiato (codice errore: 21903), aggiorna la pagina e riprova`,
        error_21904:`Errore: 21904. Riprova`,
        error_21905:`Errore: 21905. Riprova`,
        error_21906:`Richiesta non riuscita (codice errore: 21906). Per favore riprova`,
        error_21907:`L'account Google è stato collegato ad un altro account`,
        error_21908:`Richiesta non riuscita (codice errore: 21908). Per favore riprova`,
        error_22001:`Richiesta non riuscita (codice errore: 22001). Per favore riprova`,
        error_22002:`Scollegamento non riuscito senza accesso aggiuntivo`,
        error_22003:`Richiesta non riuscita (codice errore: 22003). Per favore riprova`,
        error_22101:`Errore: 22101. Contattaci`,
        error_22102:`Lo stato dell'account è cambiato (codice errore: 22102), aggiorna la pagina e riprova`,
        error_22103:`Richiesta non riuscita (codice errore: 22103). Per favore riprova`,
        error_22104:`Lo stato dell'account è cambiato (codice errore: 22104), aggiorna la pagina e riprova`,
        error_22105:`Errore: 22105. Riprova`,
        error_22106:`Errore: 22106. Riprova`,
        error_22107:`Errore: 22107. Contattaci`,
        error_22108:`Richiesta non riuscita (codice errore: 22108). Per favore riprova`,
        error_22201:`Errore: 22201. Contattaci`,
        error_22202:`Verifica non valida (errore: 22202). Si prega di riprovare.`,
        error_22203:`Errore: 22203. Riprova"`,
        error_22204:`Richiesta non riuscita (codice errore: 22204). Per favore riprova`,
        error_22206:`Richiesta non riuscita (codice errore: 22206). Per favore riprova`,
        error_22401:`Errore: 22401. Contattaci`,
        error_22402:`Verifica non valida (errore: 22402). Si prega di riprovare.`,
        error_22403:`Errore: 22403. Riprova`,
        error_22404:`Richiesta non riuscita (codice errore: 22404). Per favore riprova`,
        error_22405:`L'account Facebook è stato collegato ad altre email`,
        error_22406:`Errore: 22406. Riprova`,
        error_22407:`Errore: 22407. Contattaci`,
        error_22408:`Errore: 22408. Contattaci`,
        error_22409:`Errore: 22409. Contattaci`,
        error_22410:`Errore: 224010. Contattaci`,
        error_22411:`Errore: 224011. Contattaci`,
        error_22412:`Errore: 224012. Contattaci`,
        error_22413:`Errore: 22413. Contattaci`,
        error_22414:`Richiesta non riuscita (codice errore: 22414). Per favore riprova`,
        error_22415:`Errore: 22415. Contattaci`,
        error_22416:`Errore: 22416. Contattaci`,
        error_22417:`Errore: 22417. Contattaci`,
        error_22418:`Richiesta non riuscita (codice errore: 22418). Per favore riprova`,
        error_22419:`Errore: 22419. Contattaci`,
        error_22420:`Errore: 22420. Contattaci`,
        error_22421:`Errore: 22421. Contattaci`,
        error_22422:`Richiesta non riuscita (codice errore: 22422). Per favore riprova`,
        error_22423:`Richiesta non riuscita (codice errore: 22423). Per favore riprova`,
        error_22424:`Richiesta non riuscita (codice errore: 22424). Per favore riprova`,
        error_22425:`Richiesta non riuscita (codice errore: 22425). Per favore riprova`,
        error_20098:`Codice di errore: 20098. Se stai navigando in modalità Privata, passa alla modalità Normale e riprova.`,
        error_22298:`Richiesta per Google non riuscita (errore: 22298). Si prega di riprovare.`,
        error_22498:`Richiesta per Facebook non riuscita (Errore: 22498). Si prega di riprovare.`,
        error_24902:`Richiesta non riuscita (codice errore: 24902). Per favore riprova`,
        error_24903:`Richiesta non riuscita (codice errore: 24903). Per favore riprova`,
        error_24904:`Richiesta non riuscita (codice errore: 24904). Per favore riprova`,
        error_24905:`Richiesta non riuscita (codice errore: 24905). Per favore riprova`,
        login_title:`Accedi ad Aiseesoft`,
        password_placeholder:`Parola d'ordine`,
        log_in:`Accesso`,
        no_account:`Nessun conto?`,
        create_it:`Crealo`,
        or_log_in_with:`Oppure accedi con`,
        passwordless_login:`Accesso senza password`,
        log_in_done:`Accedi Fatto`,
        three_rd_account_connect_info:`Congratulazioni! Hai effettuato l'accesso con successo. Ora puoi connettere un account e-mail che verrà utilizzato per accedere in futuro.`,
        connect_now:`Connettiti ora`,
        see_my_account:`Vedi il mio account`,
        three_rd_login_merge_account:`L'indirizzo email dell'account di terze parti è stato registrato, vuoi connetterti e accedere direttamente con questo indirizzo email?`,
        connect_log_in:`Connettiti e accedi`,
        create_an_account:`Creare un account`,
        connect_account:`Connetti account`,
        connect_account_info:`Dopo la connessione, puoi accedere con questo account.`,
        back_to_log_in:`Torna al login`,
        create_password:`Crea password`,
        create_now:`Crea ora`,
        password_login_subtitle:`Accesso senza password con e-mail`,
        account_login:`Account login`,
        rights:`Creando questo account, accetti i <a href="https://www.eassiy.com/it/terms.html">Termini di servizio</a> e <a href="https://www.eassiy.com/it/privacy.html">Informativa sulla privacy</a>`,
        passwordless_login_done:`Accesso senza password effettuato`,
        passwordless_login_info:`Congratulazioni, hai completato con successo l'accesso senza password. Puoi creare una password per questo account e accedere con l'account e la password in futuro. <a href="/create-password" style="display: initial;">Crea ora</a>`,
        sign_up:`Iscriviti`,
        register_info:`crea il tuo account`,
        reset_now:`Ripristina ora`,
        forgot_password:`Ha dimenticato la password`,
        reset_password_subtitle:`Usa l'email del tuo account per reimpostare la password`,
        plan_products:`Piano e prodotti`,
        nick_name:`Nome utente`,
        email:`E-mail`,
        my_products:`I miei prodotti`,
        my_orders:`I miei ordini`,
        unlink:`Scollega`,
        link:`Collegamento`,
        connected_accounts:`Account collegati`,
        last_name:`Cognome`,
        first_name:`nome di battesimo`,
        Gender:`Genere`,
        Birth:`Nascita`,
        Month:`Mese`,
        Year:`Anno`,
        Country_Region:`Paese/regione`,
        Address:`Indirizzo`,
        Save:`Salva`,
        Date:`Data`,
        Male:`Maschio`,
        Female:`Femmina`,
        Unspecified:`Non specificato`,
        Security:`Sicurezza`,
        change_password:`Cambia la password`,
        change_now:`Cambia ora`,
        connect_email:`Connetti e-mail`,
        delete_account:`Eliminare l'account`,
        delete_account_info:`Quando il tuo account viene eliminato, tutti i dati in Piano e prodotti associati al tuo account verranno eliminati in modo permanente e potresti non essere in grado di recuperarli. Ti consigliamo di procedere con cautela.`,
        Delete:`Elimina`,
        Logout:`Disconnettersi`,
        my_profile:`Il mio profilo`,
        guides_faqs:`Guide e FAQ`,
        More:`Di più`,
        guides:`Guide`,
        register:`Registrati`,
        hot_faq:`Domande frequenti`,
        Contents:`Contenuti:`,
        contact_us:`Contattaci>>`,
        plan:`Piano`,
        unregistered:`Non registrato`,
        buy_more:`Comprare di più`,
        buy_again:`Acquista di nuovo`,
        buy_now:`Acquista ora`,
        free_no_limit:`Gratuito e senza limiti`,
        expired:`Scaduto`,
        lifetime:`Tutta la vita`,
        remain:`Rimanere`,
        day_s:`Giorno/i`,
        error_24801:`Richiesta non riuscita (codice errore: 24801). Per favore riprova`,
        no_app_found:`Nessuna app trovata！<a href="/">Aggiorna</a> o <a href="https://www.eassiy.com/it/">Vai al sito web ufficiale</a>`,
        get_more:`Ottieni di più >>`,
        edit_photo:`Modifica foto`,
        select_photo:`Seleziona foto`,
        change_photo:`Cambia foto`,
        cancel:`Annulla`,
        hide_password:`Nascondi password`,
        show_password:`Mostra password`,
        zoom_in:`Ingrandire`,
        zoom_out:`Rimpicciolisci`,
        rotate:`Ruotare`,
        horizontal_flip:`Capovolgimento orizzontale`,
        vertical_flip:`Capovolgimento verticale`,
        country:`Nazione`,
        country_1:`Seleziona il tuo paese/regione`,
        country_2:`isola di aland`,
        country_3:`Afghanistan`,
        country_4:`Albania`,
        country_5:`Algeria`,
        country_6:`Samoa americane`,
        country_7:`Andorra`,
        country_8:`Angola`,
        country_9:`Anguilla`,
        country_10:`Antartide`,
        country_11:`Antigua e Barbuda`,
        country_12:`Argentina`,
        country_13:`Armenia`,
        country_14:`Aruba`,
        country_15:`Australia`,
        country_16:`Austria`,
        country_17:`Azerbaigian`,
        country_18:`Bahrein`,
        country_19:`Bangladesh`,
        country_20:`Barbados`,
        country_21:`Bielorussia`,
        country_22:`Belgio`,
        country_23:`Belize`,
        country_24:`Benin`,
        country_25:`Bermuda`,
        country_26:`Bhutan`,
        country_27:`Bolivia`,
        country_28:`Bosnia ed Erzegovina`,
        country_29:`Botswana`,
        country_30:`Isola Bouvet`,
        country_31:`Brasile`,
        country_32:`Territorio britannico dell'Oceano Indiano`,
        country_33:`Isole Vergini Britanniche`,
        country_34:`Brunei`,
        country_35:`Bulgaria`,
        country_36:`Burkina`,
        country_37:`Burundi`,
        country_38:`Cambogia`,
        country_39:`Camerun`,
        country_40:`Canada`,
        country_41:`capo Verde`,
        country_42:`Caraibi Paesi Bassi`,
        country_43:`Isole Cayman`,
        country_44:`Repubblica Centrafricana`,
        country_45:`Chad`,
        country_46:`Chile`,
        country_47:`Cina`,
        country_48:`Isola di Natale`,
        country_49:`Isole Cocos (Keeling)`,
        country_50:`Colombia`,
        country_51:`Isole Cook`,
        country_52:`Costa Rica`,
        country_53:`"Costa d'Avorio"`,
        country_54:`Croazia`,
        country_55:`Cuba`,
        country_56:`Cipro`,
        country_57:`Repubblica Ceca`,
        country_58:`Repubblica Democratica del Congo`,
        country_59:`Danimarca`,
        country_60:`Gibuti`,
        country_61:`dominica`,
        country_62:`Repubblica Dominicana`,
        country_63:`Ecuador`,
        country_64:`Egitto`,
        country_65:`El Salvador`,
        country_66:`Guinea Equatoriale`,
        country_67:`Eritrea`,
        country_68:`Estonia`,
        country_69:`Etiopia`,
        country_70:`Isole Falkland`,
        country_71:`Isole Faroe`,
        country_72:`Stati Federati di Micronesia`,
        country_73:`Figi`,
        country_74:`Finlandia`,
        country_75:`Francia`,
        country_76:`Guiana francese`,
        country_77:`Polinesia francese`,
        country_78:`Territori della Francia del sud`,
        country_79:`Gabon`,
        country_80:`Gambia`,
        country_81:`Georgia`,
        country_82:`Germania`,
        country_83:`Ghana`,
        country_84:`Gibilterra`,
        country_85:`Gran Bretagna (Regno Unito; Inghilterra)`,
        country_86:`Grecia`,
        country_87:`Groenlandia`,
        country_88:`Grenada`,
        country_89:`Guadalupa`,
        country_90:`Guami`,
        country_91:`Guatemala`,
        country_92:`Guernsey`,
        country_93:`Guinea`,
        country_94:`Guinea-Bissau`,
        country_95:`Guyana`,
        country_96:`Haiti`,
        country_97:`Isole Heard e McDonald`,
        country_98:`Honduras`,
        country_99:`Hong Kong`,
        country_100:`Ungheria`,
        country_101:`Islanda`,
        country_102:`India`,
        country_103:`Indonesia`,
        country_104:`Iran`,
        country_105:`Iraq`,
        country_106:`Irlanda`,
        country_107:`Isola di Man`,
        country_108:`Israele`,
        country_109:`Italia`,
        country_110:`Giamaica`,
        country_111:`Giappone`,
        country_112:`maglia`,
        country_113:`Giordania`,
        country_114:`Kazakistan`,
        country_115:`Kenia`,
        country_116:`Kiribati`,
        country_117:`Kuwait`,
        country_118:`Kirghizistan`,
        country_119:`Laos`,
        country_120:`Lettonia`,
        country_121:`Libano`,
        country_122:`Lesotho`,
        country_123:`Liberia`,
        country_124:`Libia`,
        country_125:`Liechtenstein`,
        country_126:`Lituania`,
        country_127:`Lussemburgo`,
        country_128:`Macao`,
        country_129:`Madagascar`,
        country_130:`Malawi`,
        country_131:`Malaysia`,
        country_132:`Maldive`,
        country_133:`Mali`,
        country_134:`Malta`,
        country_135:`Isole Marshall`,
        country_136:`Martinica`,
        country_137:`Mauritania`,
        country_138:`Mauritius`,
        country_139:`Mayotte`,
        country_140:`Messico`,
        country_141:`Moldavia`,
        country_142:`Monaco`,
        country_143:`Mongolia`,
        country_144:`Montenegro`,
        country_145:`Montserrat`,
        country_146:`Marocco`,
        country_147:`Mozambico`,
        country_148:`Birmania (Birmania)`,
        country_149:`Namibia`,
        country_150:`Nauru`,
        country_151:`Nepal`,
        country_152:`Olanda`,
        country_153:`Nuova Caledonia`,
        country_154:`Nuova Zelanda`,
        country_155:`Nicaragua`,
        country_156:`Niger`,
        country_157:`Nigeria`,
        country_158:`Niue`,
        country_159:`Isola Norfolk`,
        country_160:`Corea del nord`,
        country_161:`Isole Marianne settentrionali`,
        country_162:`Norvegia`,
        country_163:`Oman`,
        country_164:`Pakistan`,
        country_165:`Palau`,
        country_166:`Territori palestinesi`,
        country_167:`Panama`,
        country_168:`Papua Nuova Guinea`,
        country_169:`Paraguay`,
        country_170:`Perù`,
        country_171:`Isole Pitcairn`,
        country_172:`Polonia`,
        country_173:`Portogallo`,
        country_174:`Porto Rico`,
        country_175:`Qatar`,
        country_176:`Repubblica di Macedonia (FYROM)`,
        country_177:`Repubblica del Congo`,
        country_178:`Riunione`,
        country_179:`Romania`,
        country_180:`Federazione Russa`,
        country_181:`Ruanda`,
        country_182:`San Bartolomeo`,
        country_183:`Saint Martin (Francia)`,
        country_184:`Saint-Pierre e Miquelon`,
        country_185:`Samoa`,
        country_186:`San Marino`,
        country_187:`Sao Tomé e Principe`,
        country_188:`Arabia Saudita`,
        country_189:`Senegal`,
        country_190:`Serbia`,
        country_191:`Seychelles`,
        country_192:`Sierra Leone`,
        country_193:`Singapore`,
        country_194:`Slovacchia`,
        country_195:`Slovenia`,
        country_196:`Isole Salomone`,
        country_197:`Somalia`,
        country_198:`Sud Africa`,
        country_199:`Georgia del Sud e Isola Sandwich Meridionale`,
        country_200:`Corea del Sud`,
        country_201:`Sudan del Sud`,
        country_202:`Spagna`,
        country_203:`Sri Lanka`,
        country_204:`Sant'Elena e dipendenze`,
        country_205:`St. Kitts e Nevis`,
        country_206:`Santa Lucia`,
        country_207:`St. Vincent e Grenadine`,
        country_208:`Sudan`,
        country_209:`Suriname`,
        country_210:`Swaziland`,
        country_211:`Svezia`,
        country_212:`Svizzera`,
        country_213:`Siria`,
        country_214:`Taiwan`,
        country_215:`Tagikistan`,
        country_216:`Tanzania`,
        country_217:`Modello:Dati nazionali SJM Svalbard`,
        country_218:`Tailandia`,
        country_219:`Le Bahamas`,
        country_220:`Le Comore`,
        country_221:`Le Filippine`,
        country_222:`Timor Est (Timor Est)`,
        country_223:`Andare`,
        country_224:`Tokelau`,
        country_225:`Tonga`,
        country_226:`Trinidad & Tobago`,
        country_227:`Tunisia`,
        country_228:`tacchino`,
        country_229:`Turkmenistan`,
        country_230:`Isole Turks e Caicos`,
        country_231:`Tuvalu`,
        country_232:`Uganda`,
        country_233:`Ucraina`,
        country_234:`Emirati Arabi Uniti`,
        country_235:`Isole Minori Esterne degli Stati Uniti`,
        country_236:`Stati Uniti d'America (USA)`,
        country_237:`Isole Vergini americane`,
        country_238:`Uruguay`,
        country_239:`Uzbekistan`,
        country_240:`Vanuatu`,
        country_241:`Città del Vaticano (Santa Sede)`,
        country_242:`Venezuela`,
        country_243:`Vietnam`,
        country_244:`Wallis e Futuna`,
        country_245:`Sahara occidentale`,
        country_246:`Yemen`,
        country_247:`Zambia`,
        country_248:`Zimbabwe`,
        google_login:`Accedi con Google`,
        footer:`Copyright © 2023 Aiseesoft Studio. Tutti i diritti riservati.`,
        State:`Stato`,
        Activation_code:`Codice di attivazione`,
        Question:`Elenca tutte le app a cui hai effettuato l'accesso`,
        Copy_complete:`Copia completata`,

        store:`Negozio`,
        resource:`Risorsa`,
        support:`sostegno`,
        video_converter:`Convertitore video`,
        multimedia:`Multimedia`,
        data_recovery:`Recupero dati`,
        utility:`Utilità`,
        free_tools:`Strumenti gratuiti`,
        for_windows:`Per Windows`,
        for_mac:`Per Mac`,
        pdf_solutions:`Soluzioni PDF`,
        data_manager:`Gestore dati`,
        other_tools:`Altri strumenti`,
        free_online_solutions:`Soluzioni online gratuite`,
        free_online_tools:`Strumenti online gratuiti`,
        desktop_freeware:`Software gratuito per desktop`,
        product_1:`Video Converter Ultimate`,
        product_2:`Convertitore video totale`,
        product_3:`Convertitore MTS`,
        product_4:`Convertitore 4K`,
        product_5:`Convertitore MXF`,
        product_6:`Convertitore 3D`,
        product_7:`Convertitore video Mac Ultimate`,
        product_8:`Convertitore video per Mac`,
        product_9:`Convertitore MTS per Mac`,
        product_10:`Convertitore 4K per Mac`,
        product_11:`Convertitore MXF per Mac`,
        product_12:`Convertitore 3D per Mac`,
        product_13:`Lettore Blu-ray`,
        product_14:`Miglioratore video`,
        product_15:`Editor video`,
        product_16:`Creatore di presentazioni`,
        product_17:`Creatore Blu-ray`,
        product_18:`Burnova - Creatore di DVD Pro`,
        product_19:`Creatore di DVD`,
        product_20:`Lettore Blu-ray Mac`,
        product_21:`Ottimizzatore video per Mac`,
        product_22:`Editor video per Mac`,
        product_23:`Creatore di DVD per Mac`,
        product_24:`Convertitore MP4 per Mac`,
        product_25:`Recupero dati di Windows`,
        product_26:`Recupero dati iPhone FoneLab`,
        product_27:`FoneLab Ripristino del sistema iOS`,
        product_28:`FoneLab iOS Backup dei dati e amp; Ristabilire`,
        product_29:`FoneLab Recupero dati Android`,
        product_30:`Estrazione dati Android non funzionante`,
        product_31:`Backup dei dati Android e Ristabilire`,
        product_32:`Recupero Dati Mac`,
        product_33:`Recupero dati iPhone Mac FoneLab`,
        product_34:`Ripristino del sistema iOS Mac FoneLab`,
        product_35:`Backup dei dati iOS Mac FoneLab e amp; Ristabilire`,
        product_36:`Recupero dati Android Mac FoneLab`,
        product_37:`Backup e backup dei dati per Mac Android Ristabilire`,
        product_38:`PDF Converter Ultimate`,
        product_39:`PDF Converter Ultimate per Mac`,
        product_40:`Fusione PDF`,
        product_41:`Convertitore da PDF a ePub per Mac`,
        product_42:`Mac Cleaner`,
        product_43:`FoneTrans`,
        product_44:`Trasferimento WhatsApp per iOS`,
        product_45:`MobieSync`,
        product_46:`FoneEraser`,
        product_47:`Detergente per iPhone`,
        product_48:`Convertitore da PPT a video`,
        product_49:`Convertitore HEIC`,
        product_50:`Sblocco iPhone`,
        product_51:`Convertitore video gratuito online`,
        product_52:`Convertitore audio gratuito online`,
        product_53:`Compressore video gratuito online`,
        product_54:`Fusione video gratuita online`,
        product_55:`Convertitore HEIC gratuito`,
        product_56:`Compressore di immagini gratuito`,
        product_57:`Rimozione gratuita di filigrane`,
        product_58:`Compressore PDF gratuito online`,
        product_59:`Upscaler di immagini gratuito online`,
        product_60:`Rimozione dello sfondo gratuita online`,
        product_61:`Convertitore di immagini gratuito online`,
        product_62:`Convertitore PDF PNG gratuito online`,
        product_63:`Convertitore video gratuito`,
        product_64:`Convertitore gratuito da video a GIF`,
        product_65:`Recupero dati Android gratuito`,
        product_66:`Creatore di presentazioni`,
        product_67:`Editor video gratuito`,
        product_68:`Visualizzatore PDF gratuito`,
        product_69:`Registratore dello schermo`,
        product_70:`Registratore dello schermo per Mac`,
        product_71:`Registratore dello schermo gratuito in linea`,
        product_72:`Registratore audio gratuito online`,
        product_73:`Specchio del telefono`,
        product_74:`AnyCoord - Cambio di posizione`,
        product_75:`Gestore di password dell'iPhone`,
        product_76:`Compressore audio online gratuito`,
        product_77:`Rifinitore video gratuito online`,
        product_78:`Ritaglia video online gratuito`,
        product_79:`Rotatore video gratuito online`,
        product_80:`Video Looper online gratuito`,
        product_81:`Convertitore PDF JPG gratuito online`,
        product_81:`Convertitore gratuito di parole JPG online`,
        category_1:`Fotoritocco`,
        category_2:`Montaggio video`,
        category_3:`Gomma`,
        category_4:`Registratore multimediale`,
        posted_by:`Pubblicato da`,
        to:`a`,
        author_1:`Ashley Mae`,
        author_2:`Jenny Ryan`,
        author_3:`Nicole Jones`,
        author_4:`Amanda Brown`,
        article_1:`Come rimuovere il testo dalle immagini in modo efficiente`,
        article_2:`Come aumentare la dimensione dell'immagine da KB a MB senza danneggiare la qualità`,
        article_3:`Come rimuovere la filigrana Getty Images su Windows e Mac`,
        article_4:`Il metodo migliore per convertire gratuitamente un'immagine a bassa risoluzione in alta risoluzione online`,
        article_5:`Guida TikTok | Ecco come creare un collage video su TikTok`,
        article_6:`5 semplici modi per combinare file VOB che dovresti conoscere`,
        article_7:`Come migliorare i video e ottenere facilmente una risoluzione più alta`,
        article_8:`Come combinare file MP4 su MacBook Pro/iMac/MacBook e altro`,
        article_9:`2 semplici modi per eliminare la cronologia degli acquisti su iPhone [Risolto]`,
        article_10:`Come eliminare definitivamente le immagini di Snapchat da chat e ricordi`,
        article_11:`Come eliminare i suggerimenti e la cronologia di ricerca Spotlight su iPhone/iPad`,
        article_12:`[Risolto] Come cancellare la cache di Instagram su iPhone con 3 semplici modi`,
        article_13:`[Risolto] Come risolvere QuickTime Player che non funziona su Mac`,
        article_14:`Come registrare l'audio di sistema su Mac (Big Sur/Catalina/Mojave...)`,
        article_15:`Modi efficaci per correggere la registrazione dello schermo di PowerPoint che non funziona`,
        article_16:`Come registrare l'audio con VLC [Risolto]`,
        faq_title_1:`1. Come correggere l'errore 4104?`,
        faq_title_2:`2. Quando posso riavere i miei soldi dopo che la richiesta di rimborso è stata approvata?`,
        faq_title_3:`3. Che cos'è "Abbonamento 1 anno" e come disattivare il "Servizio di rinnovo automatico"?`,
        faq_title_4:`4. Posso cancellare il mio ordine dopo aver inserito il prodotto Aiseesoft?`,
        faq_title_5:`5. Come inviarti una recensione?`,
        faq_info_1:`Ciò è dovuto alla licenza limitata che hai. Adottiamo Licenza Unica in caso di furto del codice di registrazione e preveniamo la violazione dell'uso del codice di registrazione. Quindi il codice di registrazione che hai è una licenza singola che può essere utilizzata solo su un computer. Se lo utilizzi su più di un computer, verrà visualizzato l'errore 4104. Non potrai utilizzarlo finché non verrà ripristinato per te.`,
        faq_info_2:`Se cambi o reinstalli il computer, possiamo ripristinarlo per te. Contatta il servizio clienti: <a href="mailto:support@eassiy.com">support@eassiy.com</a> per richiedere la reimpostazione del codice di registrazione per te.`,
        faq_info_3:`Una volta che la tua richiesta di rimborso è stata approvata, normalmente ci vorranno 3~7 giorni lavorativi prima che il credito appaia sul tuo account. A volte durante le vacanze, potrebbe essere necessario più tempo, ma non si prevede che impiegheranno più di 10 giorni. Se non hai ricevuto il rimborso 20 giorni dopo l'approvazione, contattaci all'indirizzo <a href="mailto:support@eassiy.com">support@eassiy.com</a> per verificare il rimborso.`,
        faq_info_4:`Servizio di rinnovo automatico significa che se acquisti un abbonamento di 1 anno, la piattaforma di pagamento addebiterà automaticamente l'importo (incluse le tasse applicabili) alla scadenza della licenza del prodotto. Ma il modulo di pagamento (Swreg o 2Checkout) ti invierà una lettera per ricordarti che l'ordine scadrà 7 giorni prima della scadenza. L'e-mail conterrà anche le istruzioni su come disattivare il "Servizio di rinnovo automatico".`,
        faq_info_5:`Poiché l'ordine viene elaborato automaticamente, non è consentito annullare l'ordine. Inoltre, la versione di prova gratuita ti consentirà di saperne di più sul programma Aiseesoft e di verificare se può soddisfare le tue esigenze prima di acquistarlo.`,
        faq_info_6:`Se desideri davvero ottenere un rimborso, consulta la nostra <a href="https://www.eassiy.com/it/refund-policy.html">Norme sui rimborsi</a>.`,
        faq_info_7:`Apprezziamo il tuo tempo e la tua attenzione per inviarci una recensione. Contattaci (<a href="mailto:support@eassiy.com">support@eassiy.com</a>) per inviare la tua recensione e prenderemo in seria considerazione la tua recensione per gli aggiornamenti dei nostri prodotti.`,
        change_password_success:`password cambiata con successo`,
        successful_login_title:`Accesso riuscito`,
        product_page:`Pagina del prodotto>>`,
        successful_login_info:`Accesso completato. Chiudi la pagina corrente e torna alla scheda originale per continuare con il seguente processo. La pagina corrente verrà chiusa automaticamente entro 5 secondi. Se la chiusura automatica o la chiusura facendo clic sul pulsante "Fine" non riesce, chiudere direttamente questa scheda.`,
        successful_login_info_firefox:`Accesso completato. Chiudi la pagina corrente e torna alla scheda originale per continuare con il seguente processo.`,
        my_account:`Il mio account`,
        my_history:`La mia storia`,
        remove_watermark:`Rimuovi filigrana`,
        no_history:`Nessuna storia`,
        history_all:`Seleziona tutto`,
        history_open:`Aprire`,
        history_down:`Scarica`,
        history_delete:`Eliminare`,
        history_clear:`Cancella non valido`,
        images:`immagini)`,
        use_this_function:`Usa questa funzione>>`,
        hd_downloading:`Vantaggi del download di immagini originali HD:`,
        lifetimeRemaining:`Rimanente a vita`,
        Remaining:`Residuo`,
        email_verification:`verifica email`,
        email_verification_info:`Abbiamo inviato il messaggio di verifica alla tua e-mail <span class="email"></span> e completa la verifica. Dopo la verifica, i vantaggi verranno sincronizzati automaticamente.`,
        wrong_email:`Indirizzo email sbagliato?`,
        click_here_to_modify:`Clicca qui per modificare`,
        get_more_help:`Ottenere più aiuto?`,
        click_here:`Clicca qui`,
        email_verification_info_success:`Congratulazioni per aver verificato il tuo account di posta elettronica.`,
        email_verification_info_error:`Verifica non riuscita a causa del collegamento scaduto.`,
        registration_succeeded:`Registrazione riuscita`,
        registration_succeeded_info_1:`Congratulazioni! Hai completato la registrazione con successo. Abbiamo inviato il messaggio di verifica alla tua email <span class="email"></span> e completa la verifica per utilizzare i vantaggi di questa email.`,
        registration_succeeded_info_2:`Fare clic su "Fatto" per tornare alla home page e utilizzare questo prodotto.`,
        registration_succeeded_info_3:`Fare clic su "Fatto" per chiudere la pagina corrente e tornare alla Home page per le seguenti operazioni. Se la chiusura non riesce, chiudere manualmente la scheda.`,        
        verify_email:`Verifica Email`,
        registered_email_not_verified:`L'e-mail registrata non è stata verificata, verificala immediatamente.`,
        email_verification_time_1:`Non hai ricevuto un'email di verifica?`,
        email_verification_time_2:`A seguito di`,
        email_verification_time_3:`Clicca qui per inviarlo nuovamente`,
        error_26301:`Codice di errore: 26301, contattaci`,
        error_26302:`Codice di errore: 26302, contattaci`,
        error_26303:`Errore nel formato dell'e-mail (codice di errore: 26303). Si prega di inserirlo di nuovo`,
        error_26304:`Si consiglia una password superiore a 8 caratteri (codice di errore: 26304)`,
        error_26305:`Reuqest non riuscito (codice di errore: 26305). Si prega di riprovare`,
        error_26306:`L'email è stata registrata, per favore <a href="/login">accedi</a>`,
        error_26307:`Reuqest non riuscito (codice di errore: 26307). Si prega di riprovare`,
        error_26308:`Reuqest non riuscito (codice di errore: 26308). Si prega di riprovare`,
        error_26401:`Codice di errore: 26401, riprovare`,
        error_26402:`L'email è stata verificata (codice di errore: 26402), riprova`,
        error_26403:`Reuqest non riuscito (codice di errore: 26403). Si prega di riprovare`,
        error_26404:`Richiesta non riuscita (codice errore: 26404). Si prega di riprovare`,
        error_26501:`Codice di errore: 26501, contattaci`,
        error_26502:`Codice di errore: 26502, contattaci`,
        error_26503:`Errore formato email (codice errore: 26503). Si prega di inserirlo di nuovo`,
        error_26504:`Reuqest non riuscito (codice di errore: 26504). Si prega di riprovare`,
        error_26505:`L'email non è stata registrata, per favore <a href="/register">registrala prima</a>`,
        error_26506:`L'e-mail è stata verificata.`,
        error_26507:`Reuqest non riuscito (codice di errore: 26507). Si prega di riprovare`,
        error_26508:`Verifica fallita (codice di errore: 26508), riprova`,
        error_26509:`Richiesta non riuscita (codice di errore: 26509), riprovare`,
        error_26510:`Codice di errore: 26510, contattaci`,
        error_26601:`Codice di errore: 26601, contattaci`,
        error_26602:`Codice di errore: 26602, contattaci`,
        error_26603:`Reuqest non riuscito (codice di errore: 26603). Si prega di riprovare`,
        error_26604:`Codice di errore: 26604, contattaci`,
        error_26605:`Codice di errore: 26605, contattaci`,
        error_26701:`Codice di errore: 26701, contattaci`,
        error_26702:`Reuqest non riuscito (codice di errore: 26702). Si prega di riprovare`,
        error_26703:`Codice di errore: 26703, contattaci`,
        error_26704:`Codice di errore: 26704, contattaci`,
        error_26705:`Attendi l'accesso (codice di errore: 26705). Si prega di riprovare`,
        no_cookie:`Hai attivato la funzione Blocca tutti i cookie nel tuo browser, quindi non puoi accedere. Vai su Impostazioni per selezionare la casella di Consenti tutti i cookie.`,
        error_26801:`Codice di errore: 26801, contattaci`,
        error_26802:`Codice di errore: 26802, contattaci`,
        error_26803:`Richiesta non riuscita (codice errore: 26803). Si prega di riprovare`,
        error_26804:`Richiesta non riuscita (codice errore: 26804). Si prega di riprovare`,
        error_order:`Richiesta non riuscita (codice di errore: 27098), riprova!`,
        error_order1:`La query dell'ordine è incompleta（codice di errore:`,
        error_order2:`）, aggiorna e riprova.`,
        modify_email_title:`Modifica e-mail`,
        modify_email_info:`Puoi utilizzare l'e-mail modificata per accedere al tuo account.`,
        images_per:`Immagini per`,
        error_26101:`Errore: 26101. Contattaci`,
        error_26102:`Errore: 26102. Contattaci`,
        error_26103:`Richiesta non riuscita (codice di errore:26103). Per favore, riprova`,
        error_26104:`Codice di errore: 26104, riprovare`,
        error_26105:`Codice di errore: 26105, riprovare`,
        error_26106:`Eliminazione non riuscita (codice di errore: 26106). Si prega di riprovare`,
        error_26201:`Errore: 26201. Contattaci`,
        error_26202:`Richiesta non riuscita (codice di errore:26202). Per favore, riprova`,
        error_26001:`Errore: 26001. Contattaci`,
        error_26002:`Errore: 26002. Contattaci`,
        error_26003:`Errore: 26003. Contattaci`,
        error_26004:`Errore: 26004. Contattaci`,
        error_26005:`Richiesta non riuscita (codice di errore:26005). Per favore, riprova`,
        error_26006:`Codice di errore: 26006, riprovare`,
        error_26008:`Errore: 26008. Contattaci`,
        go_to_the_home_page:`Vai alla home page`,
        error_27101:`Richiesta non riuscita (codice errore: 27101). Si prega di riprovare ist`,
        error_27201:`Codice di errore: 27201, contattaci`,
        error_27202:`Codice di errore: 27202, riprovare`,
        error_27203:`Richiesta non riuscita (codice errore: 27203). Si prega di riprovare`,
        error_27204:`Codice non valido (codice errore: 27204).`,
        error_27205:`Richiesta non riuscita (codice errore: 27205). Si prega di riprovare`,
        error_27206:`Richiesta non riuscita (codice errore: 27206). Si prega di riprovare`,
        error_27207:`Richiesta non riuscita (codice errore: 27207). Si prega di riprovare`,
        no_history_found:`Non hai utilizzato alcuno strumento! <a href="/">Aggiorna</a> o <a href="https://www.eassiy.com/it/">Vai al sito web ufficiale</a>`,
        error_25301:`Errore: 25301. Contattaci`,
        error_25302:`Errore: 25302. Contattaci`,
        error_25303:`Richiesta non riuscita (codice di errore: 25303). Si prega di riprovare`,
        error_25304:`Richiesta non riuscita (codice di errore: 25304). Si prega di riprovare`,
        error_25305:`Richiesta non riuscita (codice di errore: 25305). Si prega di riprovare`,
        error_25306:`Richiesta non riuscita (codice di errore: 25306). Si prega di riprovare`,
        image_upscaler_p:`Vantaggi del download di immagini senza filigrana:`,
    },
    ja:{
        overtime_1:`エラーコード：`,
        overtime_2:`再試行してください`,
        isnetwork:`インターネットエラー。確認して再試行してください`,
        email_placeholder:`Eメール`,
        email_empty:`メールアドレスを入力してください`,
        email_not_valid:`メールが無効です`,
        email_not_valid_1:`メールアドレスを入力してください`,
        email_not_valid_2:`メールは無効です。メールを変えて再度入力してください。`,
        email_not_valid_3:`メール入力なし`,
        password_placeholder:`パスワード`,
        password_empty:`パスワードを入力してください`,
        password_not_valid:`間違えたメールまたはパスワードです`,
        password_not_valid_1:`8文字以上のパスワードが必要です`,
        password_not_valid_2:`パスワードを作成してください`,
        password_placeholder_1:`パスワードを作成してください`,
        password_placeholder_2:`パスワードを確認しましょう`,
        password_placeholder_3:`新しいパスワードを作成します`,
        password_placeholder_4:`新しいパスワードを確認します`,
        password_placeholder_5:`古いパスワードを入力してください`,
        copy_password_empty:`パスワードを確認してください`,
        copy_password_not_valid:`パスワードを確認してください`,
        copy_passwords_inconsistent:`パスワードが一致しません`,
        code_empty:`検証コードを入力してください`,
        code_not_valid:`無効な検証コード`,
        code_placeholder:`検証コード`,
        not_received_code:`メールボックスが検証コードを長期間受信していない場合は、検証コーを再度取得してください。`,
        get_first_code:`検証コーを取得してください。`,
        connect_your_email_placeholder:`メールを接続する`,
        last_name_placeholder:`姓を入力してください`,
        first_name_placeholder:`名を入力してください`,
        address_placeholder:`住所を入力してください`,
        no_code_text:`検証コードを送信しました。コードを入力してください。<span class='tips'>コードを受け取りませんでしたか？`,
        no_code_text_1:`1.メールアドレスが有効で、メールを受信できることを確認してください。`,
        no_code_text_2:`2.メールはシステムによって自動的に送信されるため、スパムまたはジャンクメールとして認識する場合があります。 メールがゴミ箱フォルダにあるかどうかを確認してください。`,
        no_code_text_3:`3.問題を解決できませんか？`,
        no_code_text_3_span:`ここをクリックしてこちらへ連絡してください。`,
        order_no:`ご購入履歴があります。ご不明な点がございましたら、<a href="https://www.eassiy.com/ja/buy/background-remover.html">ストア</a>または<a href="https://www.eassiy.com/ja/support/">お問い合わせ</a>までご連絡してください。`,
        error_24901:`現在のアカウントにはリンクされたメールがなく、注文を見つけることができません。メールで連絡してください。`,
        user_guide:`ユーザーガイド>>`,
        download:`ダウンロード`,
        order_number:`注文番号`,
        Refund:`返金`,
        Disabled:`無効`,
        Normal:`正常`,
        Modify:`変更`,
        Modify_1:`変更>>`,
        Connect:`接続`,
        unlink_success:`正常にリンク解除`,
        connect_success:`正常に接続します`,
        feedback_title:`ご意見をいただきありがとうございます。問題を残してください。24時間以内に対応いたします。`,
        feedback_thank_you:`ありがとうございます！<br/>フィードバックは送信されました。`,
        feedback_email:`メールアドレスをここに入力してください！`,
        feedback_content:`ここでアドバイスとか問題とかを残してください。`,
        feedback_submit:`提出`,
        form_contents:`内容が入力されません。 内容を入力してもう一度提出してください。`,
        old_password:`古いパスワードを入力してください`,
        new_password:`新しいパスワードを作成してください`,
        old_new_password:`新しいパスワードは古いパスワードと同じにすることはできません`,
        incorrect_password:`間違ったパスワード`,
        delete_no:`今すぐ削除`,
        Caps:`CapsLockがオンになっています`,
        Get:`取得する`,
        Done:`終わり`,
        error_20001:`エラー：20001。再度ログインしてください。`,
        error_20002:`エラー：20002。再度ログインしてください。`,
        error_20003:`エラー：20003。再度ログインしてください。`,
        error_20004:`請求が失敗しました (エラーコード: 20004)。再試行してください。`,
        error_20005:`ログインセッションの有効期限が切れました（エラー：20005）。もう一度ログインしてください。`,
        error_20006:`請求が失敗しました（エラーコード：20006）。もう一度お試しください。`,
        error_20007:`ログインセッションの有効期限が切れました（エラー：20007）。もう一度ログインしてください。`,
        error_20008:`ログインセッションの有効期限が切れました（エラー：20008）。もう一度ログインしてください。`,
        error_20009:`ログインセッションの有効期限が切れました（エラー：20009）。もう一度ログインしてください。`,
        error_20101:`メールアドレスを入力してください（エラーコード：20101）`,
        error_20102:`メールが無効です（エラーコード：20102）`,
        error_20103:`請求が失敗しました（エラーコード：20103）。もう一度お試しください`,
        error_20104:`メールはすでに使用されています。<a href="/login">ログイン</a>するか、新しいメールに登録してください`,
        error_20105:`請求が失敗しました（エラーコード：20105）。もう一度お試しください`,
        error_20106:`メールの送信に失敗しました。再試行してください`,
        error_20201:`メールアドレスを入力してください（エラーコード：20201）`,
        error_20202:`パスワードを入力してください（エラーコード：20202）`,
        error_20203:`検証コードを入力してください（エラーコード：20203）`,
        error_20204:`メールが無効です（エラーコード：20204）`,
        error_20205:`8文字以上のパスワードが必要です（エラーコード：20205）`,
        error_20206:`請求が失敗しました（エラーコード：20206）。もう一度お試しください`,
        error_20207:`無効な検証コード`,
        error_20208:`請求が失敗しました（エラーコード：20208）。もう一度お試しください`,
        error_20209:`請求トが失敗しました（エラーコード：20209）。もう一度お試しください`,
        error_20301:`メールアドレスを入力してください（エラーコード：20301）`,
        error_20302:`エラー：20302。お問い合わせください`,
        error_20303:`メールが無効です（エラーコード：20303）`,
        error_20304:`請求が失敗しました（エラーコード：20304）。もう一度お試しください`,
        error_20305:`アカウントが存在しません。再度入力するか、<a href="/register">作成する</a>してください。`,
        error_20306:`パスワードはまだありません。 <a href="/passwordless-login">パスワードなしのログイン</a>または<a href="/create-password">パスワードの設定</a>を使用してログインします。`,
        error_20308:`請求が失敗しました（エラーコード：20308）。もう一度お試しください`,
        error_20401:`ログアウトに失敗しました（エラーコード：20401）。もう一度お試しください`,
        error_20501:`メールアドレスを入力してください（エラーコード：20501）`,
        error_20502:`メールが無効です（エラーコード：20502）`,
        error_20503:`請求が失敗しました（エラーコード：20503）。もう一度お試しください`,
        error_20504:`メール送信に失敗しました。もう一度お試しください。`,
        error_20601:`メールアドレスを入力してください（エラーコード：20601）`,
        error_20602:`検証コード（エラーコード：20602）を入力してください`,
        error_20603:`メールが無効です（エラーコード：20603）`,
        error_20604:`請求が失敗しました（エラーコード：20604）。もう一度お試しください`,
        error_20606:`請求が失敗しました（エラーコード：20606）。もう一度お試しください`,
        error_20607:`請求が失敗しました（エラーコード：20607）。もう一度お試しください`,
        error_20608:`請求が失敗しました（エラーコード：20608）。もう一度お試しください`,
        error_20701:`メールアドレスを入力してください（エラーコード：20701）`,
        error_20702:`メールが無効です（エラーコード：20702）`,
        error_20703:`請求が失敗しました（エラーコード：20703）。もう一度お試しください`,
        error_20704:`アカウントが存在しません。再度入力するか、<a href="/register">作成する</a>してください。`,
        error_20705:`請求が失敗しました（エラーコード：20705）。もう一度お試しください`,
        error_20706:`メール送信に失敗しました。もう一度お試しください`,
        error_20801:`メールアドレスを入力してください（エラーコード：20801）`,
        error_20802:`エラー：20802。お問い合わせください`,
        error_20803:`検証コード（エラーコード：20803）を入力してください`,
        error_20804:`メールが無効です（エラーコード：20804）`,
        error_20805:`8文字以上のパスワードが必要です（エラーコード：20805）`,
        error_20806:`請求が失敗しました（エラーコード：20806）。もう一度お試しください`,
        error_20808:`請求が失敗しました（エラーコード：20808）。もう一度お試しください`,
        error_20901:`請求が失敗しました（エラーコード：20901）。もう一度お試しください`,
        error_20902:`請求が失敗しました（エラーコード：20902）。もう一度お試しください`,
        error_21000:`変更が保存されます`,
        error_21001:`内容は送信されません（エラーコード：21001）`,
        error_21002:`請求が失敗しました（エラーコード：21002）。もう一度お試しください`,
        error_21101:`メールアドレスを入力してください（エラーコード：21101）`,
        error_21102:`メールが無効です（エラーコード：21102）`,
        error_21103:`請求が失敗しました（エラーコード：21103）、もう一度やり直してください`,
        error_21104:`このメールは使用された状態です。新しいメールを使用してください`,
        error_21105:`請求が失敗しました（エラーコード：21105）、もう一度やり直してください`,
        error_21106:`メール送信に失敗しました。もう一度お試しください`,
        error_21201:`メールアドレスを入力してください（エラーコード：21201）`,
        error_21202:`検証コード（エラーコード：21202）を入力してください`,
        error_21203:`メールが無効です（エラーコード：21203）`,
        error_21204:`エラー：21204。お問い合わせください`,
        error_21205:`エラー：21205。お問い合わせください`,
        error_21206:`8文字以上のパスワードが必要です（エラーコード：21206）`,
        error_21207:`請求が失敗しました（エラーコード：21207）。もう一度お試しください`,
        error_21209:`請求が失敗しました（エラーコード：21209）。もう一度お試しください`,
        error_21301:`古いパスワードを入力してください（エラーコード：21301）`,
        error_21302:`新しいパスワードを作成してください（エラーコード：21302）`,
        error_21303:`新しいパスワードは古いパスワードと同じにすることはできません。 （エラー：21303）`,
        error_21304:`8文字以上のパスワードが必要です（エラーコード：21304）`,
        error_21306:`請求が失敗しました（エラーコード：21306）。もう一度お試しください`,
        error_21402:`請求が失敗しました（エラーコード：21402）。もう一度お試しください`,
        error_21403:`検証コードは送信に失敗しました。再度送信してください`,
        error_21500:`アカウントが削除されました`,
        error_21501:`検証コード（エラーコード：21501）を入力してください`,
        error_21502:`ログインセッションの有効期限が切れました（エラー：21502）。もう一度ログインしてください。`,
        error_21503:`請求が失敗しました（エラーコード：21503）。もう一度お試しください`,
        error_21505:`請求が失敗しました（エラーコード：21505）、もう一度やり直してください`,
        error_21601:`エラー：20601。お問い合わせください`,
        error_21602:`無効な検証（エラー：20602）。もう一度お試しください。`,
        error_21603:`エラー：20603。もう一度お試しください`,
        error_21604:`請求が失敗しました（エラーコード：21604）。もう一度お試しください`,
        error_21606:`請求が失敗しました（エラーコード：21606）。もう一度お試しください`,
        error_21611:`請求が失敗しました（エラーコード：21611）。もう一度お試しください`,
        error_21801:`エラー：21801。お問い合わせください`,
        error_21802:`請求が失敗しました（エラー：21802）。もう一度お試しください`,
        error_21803:`エラー：21803。もう一度お試しください`,
        error_21804:`請求が失敗しました（エラーコード：21804）。もう一度お試しください`,
        error_21806:`エラー：21806。もう一度お試しください`,
        error_21807:`エラー：21807。お問い合わせください`,
        error_21808:`エラー：21808。お問い合わせください`,
        error_21809:`エラー：21809。お問い合わせください`,
        error_21810:`エラー：21810。お問い合わせください`,
        error_21811:`エラー：21811。お問い合わせください`,
        error_21812:`エラー：21812。お問い合わせください`,
        error_21813:`請求が失敗しました（エラーコード：21813）。もう一度お試しください`,
        error_21814:`エラー：21814。お問い合わせください`,
        error_21815:`請求が失敗しました（エラーコード：21815）。もう一度お試しください`,
        error_21816:`エラー：21816。お問い合わせください`,
        error_21817:`エラー：21817。お問い合わせください`,
        error_21818:`エラー：21818。お問い合わせください`,
        error_21819:`請求が失敗しました（エラーコード：21819）。もう一度お試しください`,
        error_21820:`エラー：21820。お問い合わせください`,
        error_21821:`エラー：21821。お問い合わせください`,
        error_21822:`エラー：21822。お問い合わせください`,
        error_21823:`請求が失敗しました（エラーコード：21823）。もう一度お試しください`,
        error_21824:`請求が失敗しました（エラーコード：21824）。もう一度お試しください`,
        error_21825:`請求が失敗しました（エラーコード：21825）。もう一度お試しください`,
        error_21826:`請求が失敗しました（エラーコード：21826）。もう一度お試しください`,
        error_21901:`エラー：21901。お問い合わせください`,
        error_21902:`請求が失敗しました（エラーコード：21902）。もう一度お試しください`,
        error_21903:`アカウント状態が変更されました（エラーコード：21903）。ページを更新してもう一度お試しください`,
        error_21904:`エラー：21904。もう一度お試しください`,
        error_21905:`エラー：21905。もう一度お試しください`,
        error_21906:`請求が失敗しました（エラーコード：21906）。もう一度お試しください`,
        error_21907:`Googleアカウントは他のアカウントにリンクされています`,
        error_21908:`請求が失敗しました（エラーコード：21908）。もう一度お試しください`,
        error_22001:`請求が失敗しました（エラーコード：22001）。もう一度お試しください`,
        error_22002:`ログインなしでリンク解除に失敗しました`,
        error_22003:`請求が失敗しました（エラーコード：22003）。もう一度お試しください`,
        error_22101:`エラー：22101。お問い合わせください`,
        error_22102:`アカウントの状態が変更されました（エラーコード：22102）。ページを更新して再試行してください`,
        error_22103:`請求が失敗しました（エラーコード：22103）。もう一度お試しください`,
        error_22104:`アカウントの状態が変更されました（エラーコード：22104）。ページを更新してもう一度お試しください`,
        error_22105:`エラー：22105。もう一度お試しください`,
        error_22106:`エラー：22106。もう一度お試しください`,
        error_22107:`エラー：22107。お問い合わせください`,
        error_22108:`請求が失敗しました（エラーコード：22108）。もう一度お試しください`,
        error_22201:`エラー：22201。お問い合わせください`,
        error_22202:`無効な検証（エラー：22202）。もう一度お試しください。`,
        error_22203:`エラー：22203。もう一度お試しください。」`,
        error_22204:`請求が失敗しました（エラーコード：22204）。もう一度お試しください`,
        error_22206:`請求が失敗しました（エラーコード：22206）。もう一度お試しください`,
        error_22401:`エラー：22401。お問い合わせください`,
        error_22402:`無効な検証（エラー：22402）。もう一度お試しください。`,
        error_22403:`エラー：22403。もう一度お試しください`,
        error_22404:`請求が失敗しました（エラーコード：22404）。もう一度お試しください`,
        error_22405:`Facebookアカウントは他のメールにリンクされています`,
        error_22406:`エラー：22406。もう一度お試しください`,
        error_22407:`エラー：22407。お問い合わせください`,
        error_22408:`エラー：22408。お問い合わせください`,
        error_22409:`エラー：22409。お問い合わせください`,
        error_22410:`エラー：224010。お問い合わせください`,
        error_22411:`エラー：224011。お問い合わせください`,
        error_22412:`エラー：224012。お問い合わせください`,
        error_22413:`エラー：22413。お問い合わせください`,
        error_22414:`請求が失敗しました（エラーコード：22414）。もう一度お試しください`,
        error_22415:`エラー：22415。お問い合わせください`,
        error_22416:`エラー：22416。お問い合わせください`,
        error_22417:`エラー：22417。お問い合わせください`,
        error_22418:`請求が失敗しました（エラーコード：22418）。もう一度お試しください`,
        error_22419:`エラー：22419。お問い合わせください`,
        error_22420:`エラー：22420。お問い合わせください`,
        error_22421:`エラー：22421。お問い合わせください`,
        error_22422:`請求が失敗しました（エラーコード：22422）。もう一度お試しください`,
        error_22423:`請求が失敗しました（エラーコード：22423）。もう一度お試しください`,
        error_22424:`請求が失敗しました（エラーコード：22424）。もう一度お試しください`,
        error_22425:`請求が失敗しました（エラーコード：22425）。もう一度お試しください`,
        error_20098:`エラーコード：20098。プライベートモードで閲覧している場合は、通常モードに切り替えて再試行してください。`,
        error_22298:`Googleの請求に失敗しました（エラー：22298）。もう一度お試しください。`,
        error_22498:`Facebookの請求に失敗しました（エラー：22498）。もう一度お試しください。`,
        error_24902:`請求が失敗しました（エラーコード：24902）。もう一度お試しください`,
        error_24903:`請求が失敗しました（エラーコード：24903）。もう一度お試しください`,
        error_24904:`請求が失敗しました（エラーコード：24904）。もう一度お試しください`,
        error_24905:`請求が失敗しました（エラーコード：24905）。もう一度お試しください`,
        login_title:`Aiseesoftにログインします`,
        password_placeholder:`パスワード`,
        log_in:`ログインする`,
        no_account:`アカウントがありませんか？`,
        create_it:`作成する`,
        or_log_in_with:`またはログイン`,
        passwordless_login:`パスワード不要のログイン`,
        log_in_done:`ログイン完了`,
        three_rd_account_connect_info:`おめでとう！成功にログインしました。これで、ログイン用の電子メールアカウントに接続できます。`,
        connect_now:`今すぐ接続`,
        see_my_account:`アカウントを確認`,
        three_rd_login_merge_account:`サードパーティアカウントの電子メールアドレスがサインアップされました。この電子メールアドレスに直接接続してログインしますか？`,
        connect_log_in:`接続してログイン`,
        create_an_account:`アカウントを作成する`,
        connect_account:`アカウントを接続する`,
        connect_account_info:`接続後、このアカウントでログインできます。`,
        back_to_log_in:`ログインに戻る`,
        create_password:`パスワードの作成`,
        create_now:`今すぐ作成`,
        password_login_subtitle:`スワードなしメールでパログイン`,
        account_login:`アカウントログイン`,
        rights:`このアカウントを作成する場合、<a href="https://www.eassiy.com/ja/terms.html">利用規約</a>と<a href="https://www.eassiy.com/ja/privacy.html">プライバシーポリシー</a>を同意します`,
        passwordless_login_done:`パスワードなしログインしました`,
        passwordless_login_info:`おめでとうございます。パスワードなしログインしました。アカウントのパスワードを作成し、そのアカウントとパスワードでログインすることができます。 <a href="/create-password" style="display:initial;">今すぐ作成</a>`,
        sign_up:`サインアップ`,
        register_info:`アカウントを作成`,
        reset_now:`今すぐリセット`,
        forgot_password:`パスワードをお忘れですか`,
        reset_password_subtitle:`アカウントのメールアドレスを使用してパスワードをリセットします`,
        plan_products:`プランと製品`,
        nick_name:`ニックネーム`,
        email:`Eメール`,
        my_products:`私の製品`,
        my_orders:`私の注文`,
        unlink:`リンクを解除する`,
        link:`リンク`,
        connected_accounts:`接続されたアカウント`,
        last_name:`名`,
        first_name:`姓`,
        Gender:`性別`,
        Birth:`誕生日`,
        Month:`月`,
        Year:`年`,
        Country_Region:`国/地域`,
        Address:`住所`,
        Save:`保存`,
        Date:`日付け`,
        Male:`男`,
        Female:`女`,
        Unspecified:`詳細不明`,
        Security:`安全＆プライバシー`,
        change_password:`パスワードを変更する`,
        change_now:`今すぐ変更`,
        connect_email:`メールを接続する`,
        delete_account:`アカウントを削除する`,
        delete_account_info:`アカウントを削除すると、関連されているプランと注文情報のデータがすべて削除され、復元するのはできません。アカウントを削除してもよろしいでしょうか?`,
        Delete:`削除`,
        Logout:`ログアウト`,
        my_profile:`プロフィール`,
        guides_faqs:`ガイドとFAQ`,
        More:`もっと`,
        guides:`ガイド`,
        register:`登録`,
        hot_faq:`ホットFAQ`,
        Contents:`コンテンツ：`,
        contact_us:`お問い合わせ>>`,
        plan:`プラン`,
        unregistered:`未登録`,
        buy_more:`もっと買う`,
        buy_again:`もう一度購入する`,
        buy_now:`今すぐ購入`,
        free_no_limit:`無料＆無制限`,
        expired:`期限切れ`,
        lifetime:`一生`,
        remain:`残る`,
        day_s:`日々）`,
        error_24801:`リクエストが失敗しました（エラーコード：24801）。もう一度お試しください`,
        no_app_found:`ソフトリストがありません。<a href="/">更新</a>または<a href="https://www.eassiy.com/ja">公式ウェブサイト</a>にアクセスしてください。`,
        
        get_more:`もっと見る>>`,
        edit_photo:`写真を編集する`,
        select_photo:`写真を選択`,
        change_photo:`写真を変更する`,
        cancel:`キャンセル`,
        hide_password:`パスワードを隠す`,
        show_password:`パスワードを表示`,
        zoom_in:`ズームイン`,
        zoom_out:`ズームアウトする`,
        rotate:`回転する`,
        horizontal_flip:`水平フリップ`,
        vertical_flip:`垂直フリップ`,
        country:`国`,
        country_1:`国/地域を選択してください`,
        country_2:`オーランド島`,
        country_3:`アフガニスタン`,
        country_4:`アルバニア`,
        country_5:`アルジェリア`,
        country_6:`アメリカ領サモア`,
        country_7:`アンドラ`,
        country_8:`アンゴラ`,
        country_9:`アンギラ`,
        country_10:`南極大陸`,
        country_11:`アンティグアバーブーダ`,
        country_12:`アルゼンチン`,
        country_13:`アルメニア`,
        country_14:`アルバ`,
        country_15:`オーストラリア`,
        country_16:`オーストリア`,
        country_17:`アゼルバイジャン`,
        country_18:`バーレーン`,
        country_19:`バングラデシュ`,
        country_20:`バルバドス`,
        country_21:`ベラルーシ`,
        country_22:`ベルギー`,
        country_23:`ベリーズ`,
        country_24:`ベナン`,
        country_25:`バミューダ`,
        country_26:`ブータン`,
        country_27:`ボリビア`,
        country_28:`ボスニア・ヘルツェゴビナ`,
        country_29:`ボツワナ`,
        country_30:`ブーベ島`,
        country_31:`ブラジル`,
        country_32:`イギリス領インド洋地域`,
        country_33:`イギリス領バージン諸島`,
        country_34:`ブルネイ`,
        country_35:`ブルガリア`,
        country_36:`ブルキナファソ`,
        country_37:`ブルンジ`,
        country_38:`カンボジア`,
        country_39:`カメルーン`,
        country_40:`カナダ`,
        country_41:`カーボベルデ`,
        country_42:`カリブ海オランダ`,
        country_43:`ケイマン諸島`,
        country_44:`中央アフリカ共和国`,
        country_45:`チャド`,
        country_46:`チリ`,
        country_47:`中国`,
        country_48:`クリスマス島`,
        country_49:`ココス（キーリング）諸島`,
        country_50:`コロンビア`,
        country_51:`クック諸島`,
        country_52:`コスタリカ`,
        country_53:`「コートジボワール」`,
        country_54:`クロアチア`,
        country_55:`キューバ`,
        country_56:`キプロス`,
        country_57:`チェコ共和国`,
        country_58:`コンゴ民主共和国`,
        country_59:`デンマーク`,
        country_60:`ジブチ`,
        country_61:`ドミニカ`,
        country_62:`ドミニカ共和国`,
        country_63:`エクアドル`,
        country_64:`エジプト`,
        country_65:`エルサルバドル`,
        country_66:`赤道ギニア`,
        country_67:`エリトリア`,
        country_68:`エストニア`,
        country_69:`エチオピア`,
        country_70:`フォークランド諸島`,
        country_71:`フェロー諸島`,
        country_72:`ミクロネシア連邦`,
        country_73:`フィジー`,
        country_74:`フィンランド`,
        country_75:`フランス`,
        country_76:`フランス領ギアナ`,
        country_77:`フランス領ポリネシア`,
        country_78:`フランス領南方南方領土`,
        country_79:`ガボン`,
        country_80:`ガンビア`,
        country_81:`ジョージア`,
        country_82:`ドイツ`,
        country_83:`ガーナ`,
        country_84:`ジブラルタル`,
        country_85:`イギリス（イギリス;イギリス）`,
        country_86:`ギリシャ`,
        country_87:`グリーンランド`,
        country_88:`グレナダ`,
        country_89:`グアドループ`,
        country_90:`グアム`,
        country_91:`グアテマラ`,
        country_92:`ガーンジー`,
        country_93:`ギニア`,
        country_94:`ギニアビサウ`,
        country_95:`ガイアナ`,
        country_96:`ハイチ`,
        country_97:`ハード島とマクドナルド諸島`,
        country_98:`ホンジュラス`,
        country_99:`香港`,
        country_100:`ハンガリー`,
        country_101:`アイスランド`,
        country_102:`インド`,
        country_103:`インドネシア`,
        country_104:`イラン`,
        country_105:`イラク`,
        country_106:`アイルランド`,
        country_107:`マン島`,
        country_108:`イスラエル`,
        country_109:`イタリア`,
        country_110:`ジャマイカ`,
        country_111:`日本`,
        country_112:`ジャージー`,
        country_113:`ヨルダン`,
        country_114:`カザフスタン`,
        country_115:`ケニア`,
        country_116:`キリバス`,
        country_117:`クウェート`,
        country_118:`キルギスタン`,
        country_119:`ラオス`,
        country_120:`ラトビア`,
        country_121:`レバノン`,
        country_122:`レソト`,
        country_123:`リベリア`,
        country_124:`リビア`,
        country_125:`リヒテンシュタイン`,
        country_126:`リトアニア`,
        country_127:`ルクセンブルク`,
        country_128:`マカオ`,
        country_129:`マダガスカル`,
        country_130:`マラウイ`,
        country_131:`マレーシア`,
        country_132:`モルディブ`,
        country_133:`マリ`,
        country_134:`マルタ`,
        country_135:`マーシャル諸島`,
        country_136:`マルティニーク`,
        country_137:`モーリタニア`,
        country_138:`モーリシャス`,
        country_139:`マヨット`,
        country_140:`メキシコ`,
        country_141:`モルドバ`,
        country_142:`モナコ`,
        country_143:`モンゴル`,
        country_144:`モンテネグロ`,
        country_145:`モントセラト`,
        country_146:`モロッコ`,
        country_147:`モザンビーク`,
        country_148:`ミャンマー（ビルマ）`,
        country_149:`ナミビア`,
        country_150:`ナウル`,
        country_151:`ネパール`,
        country_152:`オランダ`,
        country_153:`ニューカレドニア`,
        country_154:`ニュージーランド`,
        country_155:`ニカラグア`,
        country_156:`ニジェール`,
        country_157:`ナイジェリア`,
        country_158:`ニウエ`,
        country_159:`ノーフォーク島`,
        country_160:`北朝鮮`,
        country_161:`北マリアナ諸島`,
        country_162:`ノルウェー`,
        country_163:`オマーン`,
        country_164:`パキスタン`,
        country_165:`パラオ`,
        country_166:`パレスチナ領土`,
        country_167:`パナマ`,
        country_168:`パプアニューギニア`,
        country_169:`パラグアイ`,
        country_170:`ペルー`,
        country_171:`ピトケアン諸島`,
        country_172:`ポーランド`,
        country_173:`ポルトガル`,
        country_174:`プエルトリコ`,
        country_175:`カタール`,
        country_176:`マケドニア共和国（FYROM）`,
        country_177:`コンゴ共和国`,
        country_178:`再会`,
        country_179:`ルーマニア`,
        country_180:`ロシア連邦`,
        country_181:`ルワンダ`,
        country_182:`サンバルテルミー`,
        country_183:`サンマルタン（フランス）`,
        country_184:`サンピエール島とミクロン島`,
        country_185:`サモア`,
        country_186:`サンマリノ`,
        country_187:`サントメ・プリンシペ`,
        country_188:`サウジアラビア`,
        country_189:`セネガル`,
        country_190:`セルビア`,
        country_191:`セイシェル`,
        country_192:`シエラレオネ`,
        country_193:`シンガポール`,
        country_194:`スロバキア`,
        country_195:`スロベニア`,
        country_196:`ソロモン諸島`,
        country_197:`ソマリア`,
        country_198:`南アフリカ`,
        country_199:`サウスジョージア島とサウスサンドイッチ島`,
        country_200:`韓国`,
        country_201:`南スーダン`,
        country_202:`スペイン`,
        country_203:`スリランカ`,
        country_204:`セントヘレナと依存関係`,
        country_205:`セントクリストファーネイビス`,
        country_206:`セントルシア`,
        country_207:`セントビンセントおよびグレナディーン諸島`,
        country_208:`スーダン`,
        country_209:`スリナム`,
        country_210:`スワジランド`,
        country_211:`スウェーデン`,
        country_212:`スイス`,
        country_213:`シリア`,
        country_214:`台湾`,
        country_215:`タジキスタン`,
        country_216:`タンザニア`,
        country_217:`テンプレート：国データSJMスバールバル`,
        country_218:`タイ`,
        country_219:`バハマ`,
        country_220:`コモロ`,
        country_221:`フィリピン`,
        country_222:`東ティモール（東ティモール）`,
        country_223:`トーゴ`,
        country_224:`トケラウ`,
        country_225:`トンガ`,
        country_226:`トリニダード・トバゴ`,
        country_227:`チュニジア`,
        country_228:`七面鳥`,
        country_229:`トルクメニスタン`,
        country_230:`タークス・カイコス諸島`,
        country_231:`ツバル`,
        country_232:`ウガンダ`,
        country_233:`ウクライナ`,
        country_234:`アラブ首長国連邦`,
        country_235:`合衆国領有小島`,
        country_236:`アメリカ合衆国（USA）`,
        country_237:`アメリカ領バージン諸島`,
        country_238:`ウルグアイ`,
        country_239:`ウズベキスタン`,
        country_240:`バヌアツ`,
        country_241:`バチカン市国（聖座）`,
        country_242:`ベネズエラ`,
        country_243:`ベトナム`,
        country_244:`ウォリス・フツナ`,
        country_245:`西サハラ`,
        country_246:`イエメン`,
        country_247:`ザンビア`,
        country_248:`ジンバブエ`,
        google_login:`Googleでサインイン`,
        footer:`Copyright©2023AiseesoftStudio。全著作権所有。`,
        State:`州`,
        Activation_code:`アクティベーションコード`,
        Question:`登録したソフトを表示します`,
        Copy_complete:`コピー完了`,


        store:`店`,
        resource:`リソース`,
        support:`サポート`,
        video_converter:`ビデオコンバーター`,
        multimedia:`マルチメディア`,
        data_recovery:`データ復旧`,
        utility:`効用`,
        free_tools:`無料ツール`,
        for_windows:`Windowsの場合`,
        for_mac:`Mac用`,
        pdf_solutions:`PDFソリューション`,
        data_manager:`データ管理者`,
        other_tools:`その他のツール`,
        free_online_solutions:`無料のオンラインソリューション`,
        free_online_tools:`無料のオンラインツール`,
        desktop_freeware:`デスクトップフリーウェア`,
        product_1:`ビデオコンバーターアルティメット`,
        product_2:`トータルビデオコンバータ`,
        product_3:`MTSコンバーター`,
        product_4:`4Kコンバーター`,
        product_5:`MXFコンバーター`,
        product_6:`3Dコンバーター`,
        product_7:`Mac Video Converter Ultimate`,
        product_8:`Mac用のビデオコンバーター`,
        product_9:`Mac用のMTSコンバーター`,
        product_10:`Mac用4Kコンバーター`,
        product_11:`Mac用MXFコンバーター`,
        product_12:`Mac用の3Dコンバーター`,
        product_13:`ブルーレイプレイヤー`,
        product_14:`ビデオエンハンサー`,
        product_15:`動画編集者`,
        product_16:`スライドショークリエーター`,
        product_17:`ブルーレイクリエーター`,
        product_18:`Burnova - DVD 作成プロ`,
        product_19:`DVDクリエーター`,
        product_20:`Macブルーレイプレーヤー`,
        product_21:`Macビデオエンハンサー`,
        product_22:`Mac用のビデオエディタ`,
        product_23:`Mac用DVDクリエーター`,
        product_24:`Mac用MP4コンバーター`,
        product_25:`Windowsデータ回復`,
        product_26:`FoneLabiPhoneデータリカバリ`,
        product_27:`FoneLabiOSシステムリカバリ`,
        product_28:`FoneLab iOSデータバックアップ＆amp;戻す`,
        product_29:`FoneLabAndroidデータリカバリ`,
        product_30:`壊れたAndroidデータの抽出`,
        product_31:`Androidデータのバックアップと戻す`,
        product_32:`Macのデータ回復`,
        product_33:`Mac FoneLabiPhoneデータリカバリ`,
        product_34:`Mac FoneLabiOSシステムリカバリ`,
        product_35:`Mac FoneLab iOSデータバックアップ＆amp;戻す`,
        product_36:`Mac FoneLabAndroidデータリカバリ`,
        product_37:`Mac Androidデータバックアップ＆amp;戻す`,
        product_38:`PDFコンバーターアルティメット`,
        product_39:`Mac用PDFコンバーターアルティメット`,
        product_40:`PDF合併`,
        product_41:`MacPDFからePubへのコンバーター`,
        product_42:`Macクリーナー`,
        product_43:`FoneTrans`,
        product_44:`iOS用のWhatsApp転送`,
        product_45:`MobieSync`,
        product_46:`FoneEraser`,
        product_47:`iPhoneクリーナー`,
        product_48:`PPTからビデオへのコンバーター`,
        product_49:`HEICコンバーター`,
        product_50:`iPhone Unlocker`,
        product_51:`無料のビデオコンバーターオンライン`,
        product_52:`無料のオーディオコンバータオンライン`,
        product_53:`無料のビデオコンプレッサーオンライン`,
        product_54:`オンライン無料ビデオ合併`,
        product_55:`無料のHEICコンバーター`,
        product_56:`無料の画像圧縮機`,
        product_57:`無料のウォーターマークリムーバー`,
        product_58:`無料のPDFコンプレッサーオンライン`,
        product_59:`無料画像アップスケーラーオンライン`,
        product_60:`無料のバックグラウンドリムーバーオンライン`,
        product_61:`無料の画像コンバーターオンライン`,
        product_62:`無料のPDFPNGコンバーターオンライン`,
        product_63:`無料のビデオコンバーター`,
        product_64:`無料のビデオからGIFへのコンバーター`,
        product_65:`無料のAndroidデータリカバリ`,
        product_66:`スライドショーメーカー`,
        product_67:`無料のビデオエディタ`,
        product_68:`無料のPDFビューア`,
        product_69:`スクリーンレコーダー`,
        product_70:`Macスクリーンレコーダー`,
        product_71:`オンライン無料スクリーンレコーダー`,
        product_72:`無料のオーディオレコーダーオンライン`,
        product_73:`電話ミラー`,
        product_74:`AnyCoord - ロケーションチェンジャー`,
        product_75:`iPhone パスワード マネージャー`,
        product_76:`無料のオーディオコンプレッサーオンライン`,
        product_77:`無料のビデオ トリマー オンライン`,
        product_78:`無料のビデオクロッパーオンライン`,
        product_79:`無料のビデオローテーターオンライン`,
        product_80:`無料ビデオ ルーパー オンライン`,
        product_81:`無料の PDF JPG コンバーター オンライン`,
        product_81:`無料の JPG Word コンバーター オンライン`,
        faq_title_1:`1. 4104エラーを修正するにはどうすればよいですか？`,
        faq_title_2:`2.返金リクエストが承認された後、いつ返金できますか？`,
        faq_title_3:`3.「1年間のサブスクリプション」とは何ですか？「自動更新サービス」をオフにする方法は？`,
        faq_title_4:`4. Aiseesoft製品を注文した後、注文をキャンセルできますか？`,
        faq_title_5:`5.レビューを送信するにはどうすればよいですか？`,
        faq_info_1:`これは、ライセンスが制限されているためです。登録コードの違反使用を防止するために、登録コードが盗まれたことに防ぎ、シングルライセンスを採用します。したがって、取得した登録コードは、1台のコンピューターしか使用できません。複数のコンピューターで使用すると、エラー4104が発生します。リセットするまで使用できません。`,
        faq_info_2:`コンピュータを変更または再インストールした場合は、リセットできます。 <a href="mailto:support@eassiy.com"> support@eassiy.com </a> のカスタマーサービスに連絡して、登録コードのリセットをリクエストしてください。`,
        faq_info_3:`払い戻しリクエストが承認されてから、通常、クレジットがアカウントに表示されるまで3〜7営業日かかります。休日の場合はもっと時間がかかることもありますが、10日以上かかることはありません。承認後20日以内に払い戻しがない場合は、<a href="mailto:support@eassiy.com"> support@eassiy.com </a>に連絡して払い戻しを確認してください。`,
        faq_info_4:`自動更新サービスとは、1年間のサブスクリプションを購入した場合、製品ライセンスの有効期限が切れると、支払いプラットフォームが自動的に金額（該当する税金を含む）を請求することを意味します。ただし、支払いフラットフォーム（Swregまたは2Checkout）から、注文の有効期限が切れる7日前に通知が届きます。電子メールには、「自動更新サービス」をオフにする方法の説明も含まれています。`,
        faq_info_5:`注文は自動的に処理されるため、注文をキャンセルすることはできません。さらに、無料試用版では、Aiseesoftプログラムについて詳しく知り、購入する前にニーズを満たすことができるかどうかを確認できます。`,
        faq_info_6:`本当に払い戻しを希望する場合は、<a href="https://www.eassiy.com/ja/refund-policy.html">払い戻しポリシー</a>を確認してください。`,
        faq_info_7:`レビューを送信していただき、誠にありがとうございます。お客様のレビューを提出するために弊社（<a href="mailto:support@eassiy.com">support@eassiy.com</a>）に連絡してください。私達は私達の製品アップデートのためにお客様のレビューを真剣に検討します。`,
        change_password_success:`パスワードは正常に変更されました`,
        successful_login_title:`ログイン成功`,
        product_page:`製品ページ>>`,
        successful_login_info:`ログインが完了しました。現在のページを閉じて元のタブに戻り、次のプロセスを続行してください。現在のページは5秒後に自動的に閉じられます。自動クローズまたは[完了]ボタンをクリックして閉じることができない場合は、このタブを直接閉じてください。`,
        successful_login_info_firefox:`ログインが完了しました。現在のページを閉じて元のタブに戻り、次のプロセスを続行してください。`,
        my_account:`マイアカウント`,
        my_history:`私の歴史`,
        remove_watermark:`ウォーターマークを削除する`,
        no_history:`履歴はありません`,
        history_all:`すべて選択`,
        history_open:`開ける`,
        history_down:`ダウンロード`,
        history_delete:`消去`,
        history_clear:`無効をクリア`,
        images:`画像`,
        use_this_function:`この関数を使用する>>`,
        hd_downloading:`HDオリジナル画像のダウンロードのメリット：`,
        lifetimeRemaining:`生涯-残り`,
        Remaining:`残り`,
        email_verification:`メールによる確認`,
        email_verification_info:`確認メッセージをメール<spanclass = "email"> </ span>に送信しましたので、確認を完了してください。確認後、メリットは自動的に同期されます。`,
        wrong_email:`間違ったメールアドレス？`,
        click_here_to_modify:`変更するには、ここをクリックしてください`,
        get_more_help:`さらにヘルプが必要ですか？`,
        click_here:`ここをクリック`,
        email_verification_info_success:`メールアカウントを確認していただき、ありがとうございます。`,
        email_verification_info_error:`リンクの有効期限が切れているため、検証に失敗しました。`,
        registration_succeeded:`登録に成功しました`,
        registration_succeeded_info_1:`おめでとう！あなたが正常に登録されています。確認メッセージをメール<spanclass = "email"> </ span>に送信しました。このメールのメリットを利用するには、確認を完了してください。`,
        registration_succeeded_info_2:`「完了」をクリックしてホームページに戻り、この製品を使用してください。`,
        registration_succeeded_info_3:`「完了」をクリックして現在のページを閉じ、ホームページに戻って次の操作を行います。閉じられない場合は、手動でタブを閉じてください。`,
        verify_email:`Eメールを確認します`,
        registered_email_not_verified:`登録されたメールアドレスは確認されていません。すぐに確認してください。`,
        email_verification_time_1:`確認メールが届きませんでしたか？`,
        email_verification_time_2:`後`,
        email_verification_time_3:`ここをクリックして再送してください`,
        error_26301:`エラーコード：26301、お問い合わせください`,
        error_26302:`エラーコード：26302、お問い合わせください`,
        error_26303:`メールフォーマットエラー（エラーコード：26303）。もう一度入力してください`,
        error_26304:`8文字を超えるパスワードをお勧めします（エラーコード：26304）`,
        error_26305:`Reuqestが失敗しました（エラーコード：26305）。再試行してください`,
        error_26306:`メールが登録されました。<a href="/login">ログインしてください</a>`,
        error_26307:`Reuqestが失敗しました（エラーコード：26307）。再試行してください`,
        error_26308:`Reuqestが失敗しました（エラーコード：26308）。再試行してください`,
        error_26401:`エラーコード：26401、再試行してください`,
        error_26402:`メールが確認されました（エラーコード：26402）。再試行してください`,
        error_26403:`Reuqestが失敗しました（エラーコード：26403）。再試行してください`,
        error_26404:`リクエストが失敗しました（エラーコード：26404）。再試行してください`,
        error_26501:`エラーコード：26501、お問い合わせください`,
        error_26502:`エラーコード：26502、お問い合わせください`,
        error_26503:`メールフォーマットエラー（エラーコード：26503）。もう一度入力してください`,
        error_26504:`Reuqestが失敗しました（エラーコード：26504）。再試行してください`,
        error_26505:`メールはまだ登録されていません。<a href="/register">最初に登録</a>してください`,
        error_26506:`メールが確認されました。`,
        error_26507:`Reuqestが失敗しました（エラーコード：26507）。再試行してください`,
        error_26508:`検証が失敗しました（エラーコード：26508）、再試行してください`,
        error_26509:`リクエストに失敗しました（エラーコード：26509）。再試行してください`,
        error_26510:`エラーコード：26510、お問い合わせください`,
        error_26601:`エラーコード：26601、お問い合わせください`,
        error_26602:`エラーコード：26602、お問い合わせください`,
        error_26603:`Reuqestが失敗しました（エラーコード：26603）。再試行してください`,
        error_26604:`エラーコード：26604、お問い合わせください`,
        error_26605:`エラーコード：26605、お問い合わせください`,
        error_26701:`エラーコード：26701、お問い合わせください`,
        error_26702:`Reuqestが失敗しました（エラーコード：26702）。再試行してください`,
        error_26703:`エラーコード：26703、お問い合わせください`,
        error_26704:`エラーコード：26704、お問い合わせください`,
        error_26705:`ログインを待ちます（エラーコード：26705）。再試行してください`,
        no_cookie:`ブラウザで[すべてのCookieをブロックする]機能をオンにしているため、ログインできません。[設定]に移動して、[すべてのCookieを許可する]チェックボックスをオンにしてください。`,
        error_26801:`エラーコード：26801、お問い合わせください`,
        error_26802:`エラーコード：26802、お問い合わせください`,
        error_26803:`リクエストが失敗しました（エラーコード：26803）。再試行してください`,
        error_26804:`リクエストが失敗しました（エラーコード：26804）。再試行してください`,
        error_order:`リクエストに失敗しました（エラーコード：27098）。もう一度やり直してください。`,
        error_order1:`注文クエリが不完全です（エラーコード：`,
        error_order2:`）、更新してもう一度お試しください。`,
        modify_email_title:`メールを変更する`,
        modify_email_info:`変更したメールを使用して、アカウントにログインできます。`,
        images_per:`あたりの画像`,
        error_26101:`エラー：26101。お問い合わせください`,
        error_26102:`エラー：26102。お問い合わせください`,
        error_26103:`リクエストが失敗しました（エラーコード：26103）。もう一度お試しください`,
        error_26104:`エラーコード：26104、plesase再試行してください`,
        error_26105:`エラーコード：26105、もう一度やり直してください`,
        error_26106:`削除に失敗しました（エラーコード：26106）。もう一度お試しください`,
        error_26201:`エラー：26201。お問い合わせください`,
        error_26202:`リクエストが失敗しました（エラーコード：26202）。もう一度お試しください`,
        error_26001:`エラー：26001。お問い合わせください`,
        error_26002:`エラー：26002。お問い合わせください`,
        error_26003:`エラー：26003。お問い合わせください`,
        error_26004:`エラー：26004。お問い合わせください`,
        error_26005:`リクエストが失敗しました（エラーコード：26005）。もう一度お試しください`,
        error_26006:`エラーコード：26006、もう一度やり直してください`,
        error_26008:`エラー：26008。お問い合わせください`,
        go_to_the_home_page:`ホームページに移動します`,
        error_27101:`リクエストが失敗しました（エラーコード：27101）。 istを再試行してください`,
        error_27201:`エラーコード：27201、お問い合わせください`,
        error_27202:`エラーコード：27202、再試行してください`,
        error_27203:`リクエストが失敗しました（エラーコード：27203）。再試行してください`,
        error_27204:`無効なコード（エラーコード：27204）。`,
        error_27205:`リクエストが失敗しました（エラーコード：27205）。再試行してください`,
        error_27206:`リクエストが失敗しました（エラーコード：27206）。再試行してください`,
        error_27207:`リクエストが失敗しました（エラーコード：27207）。再試行してください`,
        no_history_found:`あなたはどんな道具も使ったことがありません！<a href="/">更新</a>または<a href="https://www.eassiy.com/ja">公式ウェブサイト</a>にアクセスしてください。`,
        error_25301:`エラー：25301。お問い合わせください`,
        error_25302:`エラー：25302。お問い合わせください`,
        error_25303:`リクエストが失敗しました（エラーコード：25303）。もう一度お試しください`,
        error_25304:`リクエストが失敗しました（エラーコード：25304）。もう一度お試しください`,
        error_25305:`リクエストが失敗しました（エラーコード：25305）。もう一度お試しください`,
        error_25306:`リクエストが失敗しました（エラーコード：25306）。もう一度お試しください`,
        image_upscaler_p:`透かしなし画像ダウンロードのメリット:`,
    },
    es:{
        overtime_1:`Código de error:`,
        overtime_2:`, por favor vuelve a intentarlo`,
        isnetwork:`Error de Internet. Por favor verifique y vuelva a intentarlo`,
        email_placeholder:`Correo electrónico`,
        email_empty:`Por favor ingrese el correo electrónico`,
        email_not_valid:`El correo no es válido`,
        email_not_valid_1:`Por favor ingrese su correo electrónico`,
        email_not_valid_2:`El correo electrónico no es válido, utilice una dirección diferente.`,
        email_not_valid_3:`Sin entrada de correo electrónico`,
        password_placeholder:`Contraseña`,
        password_empty:`Por favor ingrese la contraseña`,
        password_not_valid:`Cuenta o contraseña incorrecta`,
        password_not_valid_1:`Se requiere una contraseña de más de 8 caracteres`,
        password_not_valid_2:`Por favor crea una contraseña`,
        password_placeholder_1:`Crea tu contraseña`,
        password_placeholder_2:`Confirmar la contraseña`,
        password_placeholder_3:`Crear nueva contraseña`,
        password_placeholder_4:`Confirmar nueva contraseña`,
        password_placeholder_5:`Ingrese la contraseña anterior`,
        copy_password_empty:`Por favor confirme la contraseña`,
        copy_password_not_valid:`Por favor, confirme su contraseña`,
        copy_passwords_inconsistent:`Su confirmación de contraseña no coincide`,
        code_empty:`Por favor ingrese el código de verificación`,
        code_not_valid:`código de verificación invalido`,
        code_placeholder:`Código de verificación`,
        not_received_code:`Si su buzón no ha recibido el código de verificación durante mucho tiempo, obtenga el código de verificación nuevamente.`,
        get_first_code:`Primero obtenga el código de verificación.`,
        connect_your_email_placeholder:`Conecta tu correo electrónico`,
        last_name_placeholder:`Por favor ingrese su apellido`,
        first_name_placeholder:`Por favor, introduzca su nombre de pila`,
        address_placeholder:`Por favor ingrese su dirección`,
        no_code_text:`Hemos enviado un código de verificación. Por favor, introduzca su código. <span class='tips'>¿No recibió un código?`,
        no_code_text_1:`1. Asegúrese de que la dirección de correo electrónico sea válida y pueda recibir correos electrónicos.`,
        no_code_text_2:`2. Para que el sistema envíe el correo electrónico automáticamente, se puede marcar como spam o correo no deseado. Compruebe si el correo electrónico está en la carpeta Papelera.`,
        no_code_text_3:`3. ¿No puede resolver su problema?`,
        no_code_text_3_span:`Entonces haz clic aquí para contactarnos.`,
        order_no:`No has comprado ningún producto, <a href="https://www.eassiy.com/es/buy/background-remover.html">¡compra ahora</a>! Si tiene alguna pregunta, <a href="https://www.eassiy.com/es/support/">contáctenos</a>.`,
        error_24901:`La cuenta actual no tiene un correo electrónico vinculado y no puede encontrar los pedidos. Por favor conecte un correo electrónico.`,
        user_guide:`Guía del usuario>>`,
        download:`Descargar`,
        order_number:`Número de orden:`,
        Refund:`Reembolso`,
        Disabled:`Desactivado`,
        Normal:`Normal`,
        Modify:`Modificar`,
        Modify_1:`Modificar>>`,
        Connect:`Conectar`,
        unlink_success:`Desvincular con éxito`,
        connect_success:`Conectar con éxito`,
        feedback_title:`Gracias por tus comentarios. Deje su problema y le responderemos dentro de las 24 horas.`,
        feedback_thank_you:`¡Gracias!<br />Sus comentarios se han enviado correctamente.`,
        feedback_email:`¡Introduzca su correo electrónico aquí!`,
        feedback_content:`Deje cualquier problema o sugerencia que haya encontrado aquí.`,
        feedback_submit:`Entregar`,
        form_contents:`No has introducido ninguna descripción. Por favor, introdúzcalo y envíelo de nuevo.`,
        old_password:`Por favor ingrese la contraseña anterior`,
        new_password:`Por favor crea una nueva contraseña`,
        old_new_password:`La nueva contraseña no puede ser la misma que la anterior`,
        incorrect_password:`Contraseña incorrecta`,
        delete_no:`Elimina ahora`,
        Caps:`Bloqueo de mayúsculas está activado`,
        Get:`Conseguir`,
        Done:`Hecho`,
        error_20001:`Error: 20001. Vuelva a iniciar sesión.`,
        error_20002:`Error: 20002. Vuelva a iniciar sesión.`,
        error_20003:`Error: 20003. Vuelva a iniciar sesión.`,
        error_20004:`Solicitud fallida (código de error: 20004). Inténtalo de nuevo.`,
        error_20005:`La sesión de inicio de sesión ha caducado (Error: 20005). Inicie sesión de nuevo.`,
        error_20006:`Solicitud fallida (código de error: 20006). Inténtalo de nuevo.`,
        error_20007:`La sesión de inicio de sesión ha caducado (Error: 20007). Inicie sesión de nuevo.`,
        error_20008:`La sesión de inicio de sesión ha caducado (Error: 20008). Inicie sesión de nuevo.`,
        error_20009:`La sesión de inicio de sesión ha caducado (Error: 20009). Inicie sesión de nuevo.`,
        error_20101:`Ingrese su correo electrónico (código de error: 20101)`,
        error_20102:`El correo electrónico no es válido (código de error: 20102)`,
        error_20103:`Solicitud fallida (código de error: 20103). Inténtalo de nuevo`,
        error_20104:`El correo electrónico ya está en uso, por favor <a href="/login">inicie sesión</a> o regístrese con uno nuevo`,
        error_20105:`Solicitud fallida (código de error: 20105). Inténtalo de nuevo`,
        error_20106:`No se pudo enviar el correo electrónico, por favor vuelva a intentarlo`,
        error_20201:`Ingrese su correo electrónico (código de error: 20201)`,
        error_20202:`Ingrese su contraseña (código de error: 20202)`,
        error_20203:`Ingrese el código de verificación (código de error: 20203)`,
        error_20204:`El correo electrónico no es válido (código de error: 20204)`,
        error_20205:`Se requiere una contraseña de más de 8 caracteres (código de error: 20205)`,
        error_20206:`Solicitud fallida (código de error: 20206). Inténtalo de nuevo`,
        error_20207:`código de verificación invalido`,
        error_20208:`Solicitud fallida (código de error: 20208). Inténtalo de nuevo`,
        error_20209:`Solicitud fallida (código de error: 20209). Inténtalo de nuevo`,
        error_20301:`Ingrese su correo electrónico (código de error: 20301)`,
        error_20302:`Error: 20302. Por favor contáctenos`,
        error_20303:`El correo electrónico no es válido (código de error: 20303)`,
        error_20304:`Solicitud fallida (código de error: 20304). Inténtalo de nuevo`,
        error_20305:`La cuenta no existe. Vuelva a ingresar o <a href="/register">Créelo</a> primero.`,
        error_20306:`Sin contraseña todavía. Use <a href="/passwordless-login">Inicio de sesión sin contraseña</a> o <a href="/create-password">establezca una contraseña</a> e inicie sesión.`,
        error_20308:`Solicitud fallida (código de error: 20308). Inténtalo de nuevo`,
        error_20401:`Error al cerrar sesión (código de error: 20401). Inténtalo de nuevo`,
        error_20501:`Ingrese su correo electrónico (código de error: 20501)`,
        error_20502:`El correo electrónico no es válido (código de error: 20502)`,
        error_20503:`Solicitud fallida (código de error: 20503). Inténtalo de nuevo`,
        error_20504:`No se pudo enviar el correo electrónico. Inténtalo de nuevo.`,
        error_20601:`Ingrese su correo electrónico (código de error: 20601)`,
        error_20602:`Ingrese el código de verificación (código de error: 20602)`,
        error_20603:`El correo electrónico no es válido (código de error: 20603)`,
        error_20604:`Solicitud fallida (código de error: 20604). Inténtalo de nuevo`,
        error_20606:`Solicitud fallida (código de error: 20606). Inténtalo de nuevo`,
        error_20607:`Solicitud fallida (código de error: 20607). Inténtalo de nuevo`,
        error_20608:`Solicitud fallida (código de error: 20608). Inténtalo de nuevo`,
        error_20701:`Ingrese su correo electrónico (código de error: 20701)`,
        error_20702:`El correo electrónico no es válido (código de error: 20702)`,
        error_20703:`Solicitud fallida (código de error: 20703). Inténtalo de nuevo`,
        error_20704:`La cuenta no existe. Vuelva a ingresar o <a href="/register">Créelo</a> primero.`,
        error_20705:`Solicitud fallida (código de error: 20705). Inténtalo de nuevo`,
        error_20706:`No se pudo enviar el correo electrónico. Inténtalo de nuevo`,
        error_20801:`Ingrese su correo electrónico (código de error: 20801)`,
        error_20802:`Error: 20802. Por favor contáctenos`,
        error_20803:`Ingrese el código de verificación (código de error: 20803)`,
        error_20804:`El correo electrónico no es válido (código de error: 20804)`,
        error_20805:`Se requiere una contraseña de más de 8 caracteres (código de error: 20805)`,
        error_20806:`Solicitud fallida (código de error: 20806). Inténtalo de nuevo`,
        error_20808:`Solicitud fallida (código de error: 20808). Inténtalo de nuevo`,
        error_20901:`Solicitud fallida (código de error: 20901). Inténtalo de nuevo`,
        error_20902:`Solicitud fallida (código de error: 20902). Inténtalo de nuevo`,
        error_21000:`Los cambios se guardan`,
        error_21001:`No se envía información (código de error: 21001)`,
        error_21002:`Solicitud fallida (código de error: 21002). Inténtalo de nuevo`,
        error_21101:`Ingrese su correo electrónico (código de error: 21101)`,
        error_21102:`El correo electrónico no es válido (código de error: 21102)`,
        error_21103:`Solicitud fallida (código de error: 21103), por favor vuelva a intentarlo`,
        error_21104:`El correo electrónico ya está conectado, utilice uno nuevo`,
        error_21105:`Solicitud fallida (código de error: 21105), por favor vuelva a intentarlo`,
        error_21106:`No se pudo enviar el correo electrónico. Inténtalo de nuevo`,
        error_21201:`Ingrese su correo electrónico (código de error: 21201)`,
        error_21202:`Ingrese el código de verificación (código de error: 21202)`,
        error_21203:`El correo electrónico no es válido (código de error: 21203)`,
        error_21204:`Error: 21204. Por favor contáctenos`,
        error_21205:`Error: 21205. Por favor contáctenos`,
        error_21206:`Se requiere una contraseña de más de 8 caracteres (código de error: 21206)`,
        error_21207:`Solicitud fallida (código de error: 21207). Inténtalo de nuevo`,
        error_21209:`Solicitud fallida (código de error: 21209). Inténtalo de nuevo`,
        error_21301:`Ingrese la contraseña anterior (código de error: 21301)`,
        error_21302:`Cree una nueva contraseña (código de error: 21302)`,
        error_21303:`La nueva contraseña no puede ser la misma que la anterior. (Error: 21303)`,
        error_21304:`Se requiere una contraseña de más de 8 caracteres (código de error: 21304)`,
        error_21306:`Solicitud fallida (código de error: 21306). Inténtalo de nuevo`,
        error_21402:`Solicitud fallida (código de error: 21402). Inténtalo de nuevo`,
        error_21403:`No se pudo enviar el código de verificación. por favor reenvialo`,
        error_21500:`La cuenta ha sido eliminada`,
        error_21501:`Ingrese el código de verificación (código de error: 21501)`,
        error_21502:`La sesión de inicio de sesión ha caducado (Error: 21502). Inicie sesión de nuevo.`,
        error_21503:`Solicitud fallida (código de error: 21503). Inténtalo de nuevo`,
        error_21505:`Solicitud fallida (código de error: 21505), por favor vuelva a intentarlo`,
        error_21601:`Error: 20601. Por favor contáctenos`,
        error_21602:`Verificación no válida (Error: 20602). Inténtalo de nuevo.`,
        error_21603:`Error: 20603. Vuelva a intentarlo.`,
        error_21604:`Solicitud fallida (código de error: 21604). Inténtalo de nuevo`,
        error_21606:`Solicitud fallida (código de error: 21606). Inténtalo de nuevo`,
        error_21611:`Solicitud fallida (código de error: 21611). Inténtalo de nuevo`,
        error_21801:`Error: 21801. Por favor contáctenos`,
        error_21802:`Solicitud fallida (Error: 21802). Inténtalo de nuevo`,
        error_21803:`Error: 21803. Vuelva a intentarlo.`,
        error_21804:`Solicitud fallida (código de error: 21804). Inténtalo de nuevo`,
        error_21806:`Error: 21806. Vuelva a intentarlo.`,
        error_21807:`Error: 21807. Por favor contáctenos`,
        error_21808:`Error: 21808. Por favor contáctenos`,
        error_21809:`Error: 21809. Por favor contáctenos`,
        error_21810:`Error: 21810. Por favor contáctenos`,
        error_21811:`Error: 21811. Por favor contáctenos`,
        error_21812:`Error: 21812. Por favor contáctenos`,
        error_21813:`Solicitud fallida (código de error: 21813). Inténtalo de nuevo`,
        error_21814:`Error: 21814. Por favor contáctenos`,
        error_21815:`Solicitud fallida (código de error: 21815). Inténtalo de nuevo`,
        error_21816:`Error: 21816. Por favor contáctenos`,
        error_21817:`Error: 21817. Por favor contáctenos`,
        error_21818:`Error: 21818. Por favor contáctenos`,
        error_21819:`Solicitud fallida (código de error: 21819). Inténtalo de nuevo`,
        error_21820:`Error: 21820. Por favor contáctenos`,
        error_21821:`Error: 21821. Por favor contáctenos`,
        error_21822:`Error: 21822. Por favor contáctenos`,
        error_21823:`Solicitud fallida (código de error: 21823). Inténtalo de nuevo`,
        error_21824:`Solicitud fallida (código de error: 21824). Inténtalo de nuevo`,
        error_21825:`Solicitud fallida (código de error: 21825). Inténtalo de nuevo`,
        error_21826:`Solicitud fallida (código de error: 21826). Inténtalo de nuevo`,
        error_21901:`Error: 21901. Por favor contáctenos`,
        error_21902:`Solicitud fallida (código de error: 21902). Inténtalo de nuevo`,
        error_21903:`El estado de la cuenta ha cambiado (código de error: 21903), actualice la página e intente nuevamente`,
        error_21904:`Error: 21904. Vuelva a intentarlo.`,
        error_21905:`Error: 21905. Vuelva a intentarlo.`,
        error_21906:`Solicitud fallida (código de error: 21906). Inténtalo de nuevo`,
        error_21907:`La cuenta de Google se ha vinculado a otra cuenta`,
        error_21908:`Solicitud fallida (código de error: 21908). Inténtalo de nuevo`,
        error_22001:`Solicitud fallida (código de error: 22001). Inténtalo de nuevo`,
        error_22002:`Error al desvincular sin inicio de sesión adicional`,
        error_22003:`Solicitud fallida (código de error: 22003). Inténtalo de nuevo`,
        error_22101:`Error: 22101. Por favor contáctenos`,
        error_22102:`El estado de la cuenta ha cambiado (código de error: 22102), actualice la página e intente nuevamente`,
        error_22103:`Solicitud fallida (código de error: 22103). Inténtalo de nuevo`,
        error_22104:`El estado de la cuenta ha cambiado (código de error: 22104), actualice la página e intente nuevamente`,
        error_22105:`Error: 22105. Vuelva a intentarlo.`,
        error_22106:`Error: 22106. Vuelva a intentarlo.`,
        error_22107:`Error: 22107. Por favor contáctenos`,
        error_22108:`Solicitud fallida (código de error: 22108). Inténtalo de nuevo`,
        error_22201:`Error: 22201. Por favor contáctenos`,
        error_22202:`Verificación no válida (Error: 22202). Inténtalo de nuevo.`,
        error_22203:`Error: 22203. Vuelve a intentarlo"`,
        error_22204:`Solicitud fallida (código de error: 22204). Inténtalo de nuevo`,
        error_22206:`Solicitud fallida (código de error: 22206). Inténtalo de nuevo`,
        error_22401:`Error: 22401. Por favor contáctenos`,
        error_22402:`Verificación no válida (Error: 22402). Inténtalo de nuevo.`,
        error_22403:`Error: 22403. Vuelva a intentarlo.`,
        error_22404:`Solicitud fallida (código de error: 22404). Inténtalo de nuevo`,
        error_22405:`La cuenta de Facebook se ha vinculado a otro correo electrónico`,
        error_22406:`Error: 22406. Vuelva a intentarlo.`,
        error_22407:`Error: 22407. Por favor contáctenos`,
        error_22408:`Error: 22408. Por favor contáctenos`,
        error_22409:`Error: 22409. Por favor contáctenos`,
        error_22410:`Error: 224010. Por favor contáctenos`,
        error_22411:`Error: 224011. Por favor contáctenos`,
        error_22412:`Error: 224012. Por favor contáctenos`,
        error_22413:`Error: 22413. Por favor contáctenos`,
        error_22414:`Solicitud fallida (código de error: 22414). Inténtalo de nuevo`,
        error_22415:`Error: 22415. Por favor contáctenos`,
        error_22416:`Error: 22416. Por favor contáctenos`,
        error_22417:`Error: 22417. Por favor contáctenos`,
        error_22418:`Solicitud fallida (código de error: 22418). Inténtalo de nuevo`,
        error_22419:`Error: 22419. Por favor contáctenos`,
        error_22420:`Error: 22420. Por favor contáctenos`,
        error_22421:`Error: 22421. Por favor contáctenos`,
        error_22422:`Solicitud fallida (código de error: 22422). Inténtalo de nuevo`,
        error_22423:`Solicitud fallida (código de error: 22423). Inténtalo de nuevo`,
        error_22424:`Solicitud fallida (código de error: 22424). Inténtalo de nuevo`,
        error_22425:`Solicitud fallida (código de error: 22425). Inténtalo de nuevo`,
        error_20098:`Código de error: 20098. Si está navegando en modo Privado, cambie al modo Normal y vuelva a intentarlo.`,
        error_22298:`La solicitud de Google falló (Error: 22298). Inténtalo de nuevo.`,
        error_22498:`La solicitud de Facebook falló (Error: 22498). Inténtalo de nuevo.`,
        error_24902:`Solicitud fallida (código de error: 24902). Inténtalo de nuevo`,
        error_24903:`Solicitud fallida (código de error: 24903). Inténtalo de nuevo`,
        error_24904:`Solicitud fallida (código de error: 24904). Inténtalo de nuevo`,
        error_24905:`Solicitud fallida (código de error: 24905). Inténtalo de nuevo`,
        login_title:`Iniciar sesión en Aiseesoft`,
        password_placeholder:`Contraseña`,
        log_in:`Acceso`,
        no_account:`¿Sin cuenta?`,
        create_it:`créalo`,
        or_log_in_with:`O inicia sesión con`,
        passwordless_login:`Inicio de sesión sin contraseña`,
        log_in_done:`Iniciar sesión Listo`,
        three_rd_account_connect_info:`¡Felicidades! Has iniciado sesión correctamente. Ahora puede conectar una cuenta de correo electrónico que se utiliza para iniciar sesión en el futuro`,
        connect_now:`Conecte ahora`,
        see_my_account:`ver mi cuenta`,
        three_rd_login_merge_account:`La dirección de correo electrónico de la cuenta de terceros se ha registrado, ¿desea conectarse e iniciar sesión con esta dirección de correo electrónico directamente?`,
        connect_log_in:`Conectar e iniciar sesión`,
        create_an_account:`Crea una cuenta`,
        connect_account:`Conectar cuenta`,
        connect_account_info:`Después de conectarse, puede iniciar sesión con esta cuenta.`,
        back_to_log_in:`Atrás para iniciar sesión`,
        create_password:`Crear contraseña`,
        create_now:`Crea ahora`,
        password_login_subtitle:`Inicio de sesión sin contraseña con correo electrónico`,
        account_login:`Cuenta de Ingreso`,
        rights:`Al crear esta cuenta, acepta los <a href="https://www.eassiy.com/es/terms.html">Términos de servicio</a> y <a href="https://www.eassiy.com/es/privacy.html">Política de privacidad</ahref=>`,
        passwordless_login_done:`Inicio de sesión sin contraseña realizado`,
        passwordless_login_info:`Enhorabuena, ha completado correctamente el inicio de sesión sin contraseña. Puede crear una contraseña para esta cuenta e iniciar sesión con la cuenta y la contraseña en el futuro. <a href="/create-password" style="display: initial;">Crear ahora</a>`,
        sign_up:`Inscribirse`,
        register_info:`Crea tu cuenta`,
        reset_now:`Restablecer ahora`,
        forgot_password:`Has olvidado tu contraseña`,
        reset_password_subtitle:`Use el correo electrónico de su cuenta para restablecer la contraseña`,
        plan_products:`Planes y productos`,
        nick_name:`Nombre de usuario:`,
        email:`Correo electrónico:`,
        my_products:`Mis productos`,
        my_orders:`Mis ordenes`,
        unlink:`Desconectar`,
        link:`Enlace`,
        connected_accounts:`Cuentas conectadas`,
        last_name:`Apellido:`,
        first_name:`Nombre de pila:`,
        Gender:`Género:`,
        Birth:`Nacimiento:`,
        Month:`Mes`,
        Year:`Año`,
        Country_Region:`País/Región:`,
        Address:`DIRECCIÓN:`,
        Save:`Ahorrar`,
        Date:`Fecha`,
        Male:`Masculino`,
        Female:`Femenino`,
        Unspecified:`sin especificar`,
        Security:`Seguridad`,
        change_password:`Cambiar la contraseña`,
        change_now:`Cambia ahora`,
        connect_email:`Conectar correo electrónico`,
        delete_account:`Borrar cuenta`,
        delete_account_info:`Cuando se elimine su cuenta, todos los datos en Plan y productos asociados con su cuenta se eliminarán de forma permanente y es posible que no pueda recuperarlos. Te recomendamos andar con cautela.`,
        Delete:`Borrar`,
        Logout:`Cerrar sesión`,
        my_profile:`Mi perfil`,
        guides_faqs:`Guías y preguntas frecuentes`,
        More:`Más`,
        guides:`Guías`,
        register:`registro`,
        hot_faq:`Preguntas más frecuentes`,
        Contents:`Contenido:`,
        contact_us:`Contáctenos>>`,
        plan:`Plan`,
        unregistered:`No registrado`,
        buy_more:`Compra más`,
        buy_again:`Compra otra vez`,
        buy_now:`Comprar ahora`,
        free_no_limit:`Gratis y sin límite`,
        expired:`Venció`,
        lifetime:`Toda la vida`,
        remain:`Permanecer`,
        day_s:`Días)`,
        error_24801:`Solicitud fallida (código de error: 24801). Inténtalo de nuevo`,
        no_app_found:`¡No se encontró ninguna aplicación! <a href="/">Actualizar</a> o <a href="https://www.eassiy.com/es/">Ir al sitio web oficial</a>`,
        get_more:`Obtener más >>`,
        edit_photo:`Editar foto`,
        select_photo:`Seleccione Foto`,
        change_photo:`Cambiar foto`,
        cancel:`Cancelar`,
        hide_password:`Contraseña oculta`,
        show_password:`Mostrar contraseña`,
        zoom_in:`Acercarse`,
        zoom_out:`Disminuir el zoom`,
        rotate:`Girar`,
        horizontal_flip:`Volteo horizontal`,
        vertical_flip:`Volteo vertical`,
        country:`País`,
        country_1:`Seleccione su país/región`,
        country_2:`Isla de Åaland`,
        country_3:`Afganistán`,
        country_4:`Albania`,
        country_5:`Argelia`,
        country_6:`Samoa Americana`,
        country_7:`Andorra`,
        country_8:`angola`,
        country_9:`anguila`,
        country_10:`Antártida`,
        country_11:`Antigua y Barbuda`,
        country_12:`Argentina`,
        country_13:`Armenia`,
        country_14:`Aruba`,
        country_15:`Australia`,
        country_16:`Austria`,
        country_17:`Azerbaiyán`,
        country_18:`Baréin`,
        country_19:`bangladesh`,
        country_20:`barbados`,
        country_21:`Bielorrusia`,
        country_22:`Bélgica`,
        country_23:`Belice`,
        country_24:`Benín`,
        country_25:`islas Bermudas`,
        country_26:`Bután`,
        country_27:`Bolivia`,
        country_28:`Bosnia y Herzegovina`,
        country_29:`Botsuana`,
        country_30:`Isla Bouvet`,
        country_31:`Brasil`,
        country_32:`Territorio Británico del Océano Índico`,
        country_33:`Islas Vírgenes Británicas`,
        country_34:`Brunéi`,
        country_35:`Bulgaria`,
        country_36:`Burkina Faso`,
        country_37:`Burundi`,
        country_38:`Camboya`,
        country_39:`Camerún`,
        country_40:`Canadá`,
        country_41:`Cabo Verde`,
        country_42:`Caribe Países Bajos`,
        country_43:`Islas Caimán`,
        country_44:`República Centroafricana`,
        country_45:`Chad`,
        country_46:`Chile`,
        country_47:`Porcelana`,
        country_48:`Isla de Navidad`,
        country_49:`Islas Cocos (Keeling)`,
        country_50:`Colombia`,
        country_51:`Islas Cook`,
        country_52:`Costa Rica`,
        country_53:`Costa de Marfil`,
        country_54:`Croacia`,
        country_55:`Cuba`,
        country_56:`Chipre`,
        country_57:`República Checa`,
        country_58:`República Democrática del Congo`,
        country_59:`Dinamarca`,
        country_60:`Yibuti`,
        country_61:`Dominica`,
        country_62:`República Dominicana`,
        country_63:`Ecuador`,
        country_64:`Egipto`,
        country_65:`El Salvador`,
        country_66:`Guinea Ecuatorial`,
        country_67:`Eritrea`,
        country_68:`Estonia`,
        country_69:`Etiopía`,
        country_70:`Islas Malvinas`,
        country_71:`Islas Faroe`,
        country_72:`Estados Federados de Micronesia`,
        country_73:`Fiyi`,
        country_74:`Finlandia`,
        country_75:`Francia`,
        country_76:`Guayana Francesa`,
        country_77:`Polinesia francés`,
        country_78:`Territorios Franceses del Sur`,
        country_79:`Gabón`,
        country_80:`Gambia`,
        country_81:`Georgia`,
        country_82:`Alemania`,
        country_83:`Ghana`,
        country_84:`Gibraltar`,
        country_85:`Gran Bretaña (Reino Unido; Inglaterra)`,
        country_86:`Grecia`,
        country_87:`Groenlandia`,
        country_88:`Granada`,
        country_89:`Guadalupe`,
        country_90:`Guam`,
        country_91:`Guatemala`,
        country_92:`Guernesey`,
        country_93:`Guinea`,
        country_94:`Guinea-Bisáu`,
        country_95:`Guayana`,
        country_96:`Haití`,
        country_97:`Isla Heard e Islas McDonald`,
        country_98:`Honduras`,
        country_99:`Hong Kong`,
        country_100:`Hungría`,
        country_101:`Islandia`,
        country_102:`India`,
        country_103:`Indonesia`,
        country_104:`Irán`,
        country_105:`Irak`,
        country_106:`Irlanda`,
        country_107:`Isla del hombre`,
        country_108:`Israel`,
        country_109:`Italia`,
        country_110:`Jamaica`,
        country_111:`Japón`,
        country_112:`Jersey`,
        country_113:`Jordán`,
        country_114:`Kazajstán`,
        country_115:`Kenia`,
        country_116:`Kiribati`,
        country_117:`Kuwait`,
        country_118:`Kirguistán`,
        country_119:`Laos`,
        country_120:`letonia`,
        country_121:`Líbano`,
        country_122:`Lesoto`,
        country_123:`Liberia`,
        country_124:`Libia`,
        country_125:`Liechtenstein`,
        country_126:`Lituania`,
        country_127:`luxemburgo`,
        country_128:`Macao`,
        country_129:`Madagascar`,
        country_130:`Malaui`,
        country_131:`Malasia`,
        country_132:`Maldivas`,
        country_133:`Malí`,
        country_134:`Malta`,
        country_135:`Islas Marshall`,
        country_136:`Martinica`,
        country_137:`Mauritania`,
        country_138:`Mauricio`,
        country_139:`Mayotte`,
        country_140:`México`,
        country_141:`Moldavia`,
        country_142:`Mónaco`,
        country_143:`Mongolia`,
        country_144:`montenegro`,
        country_145:`Montserrat`,
        country_146:`Marruecos`,
        country_147:`Mozambique`,
        country_148:`Myanmar (Birmania)`,
        country_149:`Namibia`,
        country_150:`Nauru`,
        country_151:`Nepal`,
        country_152:`Países Bajos`,
        country_153:`Nueva Caledonia`,
        country_154:`Nueva Zelanda`,
        country_155:`Nicaragua`,
        country_156:`Níger`,
        country_157:`Nigeria`,
        country_158:`Niue`,
        country_159:`Isla Norfolk`,
        country_160:`Corea del Norte`,
        country_161:`Islas Marianas del Norte`,
        country_162:`Noruega`,
        country_163:`Omán`,
        country_164:`Pakistán`,
        country_165:`palaos`,
        country_166:`Territorios palestinos`,
        country_167:`Panamá`,
        country_168:`Papúa Nueva Guinea`,
        country_169:`Paraguay`,
        country_170:`Perú`,
        country_171:`Islas Pitcairn`,
        country_172:`Polonia`,
        country_173:`Portugal`,
        country_174:`Puerto Rico`,
        country_175:`Katar`,
        country_176:`República de Macedonia (FYROM)`,
        country_177:`República del Congo`,
        country_178:`Reunión`,
        country_179:`Rumania`,
        country_180:`Federación Rusa`,
        country_181:`Ruanda`,
        country_182:`San Bartolomé`,
        country_183:`San Martín (Francia)`,
        country_184:`San Pedro y Miquelón`,
        country_185:`samoa`,
        country_186:`San Marino`,
        country_187:`Santo Tomé y Príncipe`,
        country_188:`Arabia Saudita`,
        country_189:`Senegal`,
        country_190:`Serbia`,
        country_191:`Seychelles`,
        country_192:`Sierra Leona`,
        country_193:`Singapur`,
        country_194:`Eslovaquia`,
        country_195:`Eslovenia`,
        country_196:`Islas Salomón`,
        country_197:`Somalia`,
        country_198:`Sudáfrica`,
        country_199:`Georgia del Sur y la Isla Sándwich del Sur`,
        country_200:`Corea del Sur`,
        country_201:`Sudán del Sur`,
        country_202:`España`,
        country_203:`Sri Lanka`,
        country_204:`Santa Elena y Dependencias`,
        country_205:`San Cristóbal y Nieves`,
        country_206:`Santa Lucía`,
        country_207:`San Vicente y las Granadinas`,
        country_208:`Sudán`,
        country_209:`Surinam`,
        country_210:`Suazilandia`,
        country_211:`Suecia`,
        country_212:`Suiza`,
        country_213:`Siria`,
        country_214:`Taiwán`,
        country_215:`Tayikistán`,
        country_216:`Tanzania`,
        country_217:`Plantilla: datos del país SJM Svalbard`,
        country_218:`Tailandia`,
        country_219:`Las Bahamas`,
        country_220:`Las Comoras`,
        country_221:`Las Filipinas`,
        country_222:`Timor-Leste (Timor Oriental)`,
        country_223:`Ir`,
        country_224:`Tokelau`,
        country_225:`tonga`,
        country_226:`Trinidad y Tobago`,
        country_227:`Túnez`,
        country_228:`Pavo`,
        country_229:`turkmenistán`,
        country_230:`Islas Turcas y Caicos`,
        country_231:`Tuvalu`,
        country_232:`Uganda`,
        country_233:`Ucrania`,
        country_234:`Emiratos Árabes Unidos`,
        country_235:`Islas menores alejadas de los Estados Unidos`,
        country_236:`Estados Unidos de América (EE. UU.)`,
        country_237:`Islas Vírgenes de los Estados Unidos`,
        country_238:`Uruguay`,
        country_239:`Uzbekistán`,
        country_240:`Vanuatu`,
        country_241:`Ciudad del Vaticano (La Santa Sede)`,
        country_242:`Venezuela`,
        country_243:`Vietnam`,
        country_244:`Wallis y Futuna`,
        country_245:`Sahara Occidental`,
        country_246:`Yemen`,
        country_247:`Zambia`,
        country_248:`Zimbabue`,
        google_login:`Inicia sesión con Google`,
        State:`Estado`,
        Activation_code:`Código de activación`,
        Question:`Enumera todas las aplicaciones en las que has iniciado sesión`,
        Copy_complete:`copia completa`,
        footer:`Copyright © 2023 Aiseesoft Estudio. Reservados todos los derechos.`,
        store:`Almacenar`,
        resource:`Recurso`,
        support:`apoyo`,
        video_converter:`Convertidor de video`,
        multimedia:`Multimedia`,
        data_recovery:`Recuperación de datos`,
        utility:`Utilidad`,
        free_tools:`Herramientas gratuitas`,
        for_windows:`Para ventanas`,
        for_mac:`Para Mac`,
        pdf_solutions:`Soluciones PDF`,
        data_manager:`Administrador de datos`,
        other_tools:`Otras herramientas`,
        free_online_solutions:`Soluciones en línea gratuitas`,
        free_online_tools:`Herramientas gratuitas en línea`,
        desktop_freeware:`Programa gratuito de escritorio`,
        product_1:`Convertidor de video último`,
        product_2:`Convertidor de vídeo total`,
        product_3:`Convertidor MTS`,
        product_4:`Convertidor 4K`,
        product_5:`Convertidor MXF`,
        product_6:`Convertidor 3D`,
        product_7:`Mac Video Converter último`,
        product_8:`Convertidor de vídeo para Mac`,
        product_9:`Convertidor MTS para Mac`,
        product_10:`Convertidor 4K para Mac`,
        product_11:`Convertidor MXF para Mac`,
        product_12:`Convertidor 3D para Mac`,
        product_13:`Reproductor Blu-ray`,
        product_14:`Mejorador de video`,
        product_15:`Editor de video`,
        product_16:`Creador de presentaciones de diapositivas`,
        product_17:`Creador de Blu-ray`,
        product_18:`Burnova - Creador de DVD Pro`,
        product_19:`Creador de DVD`,
        product_20:`Reproductor de Blu-ray Mac`,
        product_21:`Mejorador de video para Mac`,
        product_22:`Editor de vídeo para Mac`,
        product_23:`Creador de DVD para Mac`,
        product_24:`Convertidor MP4 para Mac`,
        product_25:`Recuperación de datos de Windows`,
        product_26:`Recuperación de datos de iPhone FoneLab`,
        product_27:`Recuperación del sistema FoneLab iOS`,
        product_28:`Copia de seguridad de datos de FoneLab iOS &amp; Restaurar`,
        product_29:`Recuperación de datos de Android FoneLab`,
        product_30:`Extracción de datos de Android rotos`,
        product_31:`Copia de seguridad de datos de Android y Restaurar`,
        product_32:`Recuperación de datos de Mac`,
        product_33:`Recuperación de datos de iPhone de Mac FoneLab`,
        product_34:`Recuperación del sistema Mac FoneLab iOS`,
        product_35:`Mac FoneLab iOS Copia de seguridad de datos y amp; Restaurar`,
        product_36:`Recuperación de datos de Android Mac FoneLab`,
        product_37:`Mac Android Copia de seguridad de datos y amp; Restaurar`,
        product_38:`Convertidor de PDF último`,
        product_39:`PDF Converter Ultimate para Mac`,
        product_40:`Fusión de PDF`,
        product_41:`Convertidor de PDF a ePub para Mac`,
        product_42:`Limpiador de Mac`,
        product_43:`FoneTrans`,
        product_44:`Transferencia de WhatsApp para iOS`,
        product_45:`MobieSync`,
        product_46:`FoneEraser`,
        product_47:`Limpiador de iPhone`,
        product_48:`Convertidor de PPT a vídeo`,
        product_49:`Convertidor HEIC`,
        product_50:`Desbloqueador de iPhone`,
        product_51:`Convertidor de vídeo gratuito en línea`,
        product_52:`Convertidor de audio gratuito en línea`,
        product_53:`Compresor de video gratis en línea`,
        product_54:`Fusión de video gratis en línea`,
        product_55:`Convertidor HEIC gratuito`,
        product_56:`Compresor de imagen gratis`,
        product_57:`Eliminador de marcas de agua gratis`,
        product_58:`Compresor de PDF gratuito en línea`,
        product_59:`Mejorador de imagen gratuito en línea`,
        product_60:`Removedor de fondo gratis en línea`,
        product_61:`Convertidor de imágenes gratuito en línea`,
        product_62:`Convertidor PDF PNG gratuito en línea`,
        product_63:`Convertidor de vídeo gratuito`,
        product_64:`Convertidor de video a GIF gratuito`,
        product_65:`Recuperación de datos de Android gratis`,
        product_66:`Creador de presentaciones de diapositivas`,
        product_67:`Editor de vídeo gratuito`,
        product_68:`Visor de PDF gratuito`,
        product_69:`Grabador de pantalla`,
        product_70:`Grabador de pantalla de Mac`,
        product_71:`Grabador de pantalla gratuito en línea`,
        product_72:`Grabador de audio gratis en línea`,
        product_73:`Espejo de teléfono`,
        product_74:`AnyCoord - Cambiador de ubicación`,
        product_75:`Administrador de contraseñas de iPhone`,
        product_76:`Compresor de audio gratis en línea`,
        product_77:`Video Trimmer gratis en línea`,
        product_78:`Recortador de video gratis en línea`,
        product_79:`Rotador de video gratis en línea`,
        product_80:`Looper de video gratis en línea`,
        product_81:`Convertidor PDF JPG gratuito en línea`,
        product_81:`Convertidor de JPG gratuito en línea`,
        category_1:`Edición de fotos`,
        category_2:`Edición de video`,
        category_3:`Borrador`,
        category_4:`Grabadora multimedia`,
        posted_by:`publicado por`,
        to:`a`,
        author_1:`ashley mae`,
        author_2:`jenny ryan`,
        author_3:`nicole jones`,
        author_4:`amanda marrón`,
        article_1:`Cómo quitar texto de las imágenes de manera eficiente`,
        article_2:`Cómo aumentar el tamaño de la imagen en KB a MB sin dañar la calidad`,
        article_3:`Cómo quitar la marca de agua de Getty Images en Windows y Mac`,
        article_4:`El mejor método para convertir imágenes de baja resolución a alta resolución en línea de forma gratuita`,
        article_5:`Guía TikTok | Aquí se explica cómo hacer un collage de video en TikTok`,
        article_6:`5 formas sencillas de combinar archivos VOB que debe conocer`,
        article_7:`Cómo mejorar el video y obtener una resolución más alta fácilmente`,
        article_8:`Cómo combinar archivos MP4 en MacBook Pro/iMac/MacBook y más`,
        article_9:`2 formas simples de eliminar el historial de compras en iPhone [resuelto]`,
        article_10:`Cómo eliminar imágenes de Snapchat de forma permanente del chat y los recuerdos`,
        article_11:`Cómo eliminar las sugerencias de búsqueda y el historial de Spotlight en iPhone/iPad`,
        article_12:`[Resuelto] Cómo borrar el caché de Instagram en iPhone con 3 formas fáciles`,
        article_13:`[Resuelto] Cómo arreglar QuickTime Player que no funciona en Mac`,
        article_14:`Cómo grabar audio del sistema en Mac (Big Sur/Catalina/Mojave...)`,
        article_15:`Maneras efectivas de arreglar la grabación de pantalla de PowerPoint que no funciona`,
        article_16:`Cómo Grabar Audio con VLC [Resuelto]`,
        faq_title_1:`1. ¿Cómo reparar el error 4104?`,
        faq_title_2:`2. ¿Cuándo puedo recuperar mi dinero después de que se apruebe la solicitud de reembolso?`,
        faq_title_3:`3. ¿Qué es la "Suscripción de 1 año" y cómo desactivar el "Servicio de renovación automática"?`,
        faq_title_4:`4. ¿Puedo cancelar mi pedido después de colocar el producto Aiseesoft?`,
        faq_title_5:`5. ¿Cómo enviarle una reseña?`,
        faq_info_1:`Esto se debe a la licencia limitada que obtuvo. Adoptamos la Licencia Única en caso de que el código de registro sea robado y evitamos la violación del uso del código de registro. Entonces, el código de registro que obtuvo es una licencia única que solo se puede usar en una computadora. Si lo usa en más de una computadora, aparecerá el error 4104. No puede usarlo hasta que lo restablezcamos por usted.`,
        faq_info_2:`Si cambia o reinstala su computadora, podemos restablecerla por usted. Póngase en contacto con el servicio de atención al cliente: <a href="mailto:support@eassiy.com">support@eassiy.com</a> para solicitar que se restablezca el código de registro.`,
        faq_info_3:`Una vez que se aprueba su solicitud de reembolso, normalmente tomará de 3 a 7 días hábiles para que el crédito aparezca en su cuenta. A veces, durante las vacaciones, puede llevar más tiempo, pero no se espera que tarde más de 10 días. Si no ha recibido el reembolso 20 días después de la aprobación, comuníquese con nosotros a <a href="mailto:support@eassiy.com">support@eassiy.com</a> para verificar el reembolso.`,
        faq_info_4:`El Servicio de Renovación Automática significa que si compra una Suscripción de 1 año, la plataforma de pago cargará el monto automáticamente (incluidos los impuestos aplicables) cuando expire la licencia del producto. Pero la plataforma de pago (Swreg o 2Checkout) le enviará una carta para recordarle que el pedido vencerá 7 días antes del vencimiento. El correo electrónico también incluirá instrucciones sobre cómo desactivar el "Servicio de renovación automática".`,
        faq_info_5:`Dado que el pedido se procesa automáticamente, no se permite cancelar el pedido. Además, la versión de prueba gratuita le permitirá saber más sobre el programa Aiseesoft y verificar si puede satisfacer sus necesidades antes de comprarlo.`,
        faq_info_6:`Si realmente desea obtener un reembolso, consulte nuestra <a href="https://www.eassiy.com/es/refund-policy.html">Política de reembolso</a>.`,
        faq_info_7:`Agradecemos su tiempo y atención para enviarnos una reseña. Comuníquese con nosotros (<a href="mailto:support@eassiy.com">support@eassiy.com</a>) para enviar su revisión y consideraremos seriamente su revisión para nuestras actualizaciones de productos.`,
        change_password_success:`Contraseña cambiada con éxito`,
        successful_login_title:`Acceso exitoso`,
        product_page:`Página del producto>>`,
        successful_login_info:`Inicio de sesión completado. Cierre la página actual y vuelva a la pestaña original para continuar con el siguiente proceso. La página actual se cerrará automáticamente en 5 segundos. Si falla el cierre automático o el cierre haciendo clic en el botón "Listo", cierre esta pestaña directamente.`,
        successful_login_info_firefox:`Inicio de sesión completado. Cierre la página actual y vuelva a la pestaña original para continuar con el siguiente proceso.`,
        my_account:`Mi cuenta`,
        my_history:`Mi historia`,
        remove_watermark:`Quitar marca de agua`,
        no_history:`No historia`,
        history_all:`Seleccionar todo`,
        history_open:`Abierto`,
        history_down:`Descargar`,
        history_delete:`Borrar`,
        history_clear:`Borrar inválido`,
        images:`imagen(es)`,
        use_this_function:`Utilice este producto>>`,
        hd_downloading:`Beneficios de la descarga de imágenes originales en HD:`,
        lifetimeRemaining:`Vida útil restante`,
        Remaining:`Restante`,
        email_verification:`verificacion de email`,
        email_verification_info:`Hemos enviado el mensaje de verificación a su correo electrónico <span class="email"></span> y finalice la verificación. Después de verificar, los beneficios se sincronizarán automáticamente.`,
        wrong_email:`¿Dirección de correo errónea?`,
        click_here_to_modify:`Haga clic aquí para modificar`,
        get_more_help:`¿Obtener más ayuda?`,
        click_here:`haga clic aquí`,
        email_verification_info_success:`Felicitaciones por verificar su cuenta de correo electrónico.`,
        email_verification_info_error:`La verificación falló debido a que el enlace caducó.`,
        registration_succeeded:`Registro Exitoso`,
        registration_succeeded_info_1:`¡Felicidades! Te has registrado exitosamente. Hemos enviado el mensaje de verificación a su correo electrónico <span class="email"><span>, y termine de verificar para usar los beneficios de este correo electrónico.`,
        registration_succeeded_info_2:`Haga clic en "Listo" para volver a la página de inicio y utilizar este producto.`,
        registration_succeeded_info_3:`Haga clic en "Listo" para cerrar la página actual y volver a la página de inicio para las siguientes operaciones. Si el cierre falla, cierre manualmente la pestaña.`,
        verify_email:`Verificar correo electrónico`,
        registered_email_not_verified:`El correo electrónico registrado no ha sido verificado, por favor verifíquelo de inmediato.`,
        email_verification_time_1:`¿No recibiste un correo electrónico de verificación?`,
        email_verification_time_2:`Después`,
        email_verification_time_3:`Haga clic aquí para volver a enviarlo`,
        error_26301:`Código de error: 26301, contáctenos`,
        error_26302:`Código de error: 26302, contáctenos`,
        error_26303:`Error de formato de correo electrónico (código de error: 26303). Por favor, introdúcelo de nuevo.`,
        error_26304:`Se recomienda una contraseña de más de 8 caracteres (código de error: 26304)`,
        error_26305:`Solicitud fallida (código de error: 26305). Vuelva a intentarlo`,
        error_26306:`El correo electrónico se ha registrado, <a href="/login">vaya a iniciar sesión</a>`,
        error_26307:`Solicitud fallida (código de error: 26307). Vuelva a intentarlo`,
        error_26308:`Solicitud fallida (código de error: 26308). Vuelva a intentarlo`,
        error_26401:`Código de error: 26401, vuelva a intentarlo`,
        error_26402:`El correo electrónico ha sido verificado (código de error: 26402), vuelve a intentarlo`,
        error_26403:`Solicitud fallida (código de error: 26403). Vuelva a intentarlo`,
        error_26404:`Solicitud fallida (código de error: 26404). Vuelva a intentarlo`,
        error_26501:`Código de error: 26501, contáctenos`,
        error_26502:`Código de error: 26502, contáctenos`,
        error_26503:`Error de formato de correo electrónico (código de error: 26503). Por favor, introdúcelo de nuevo.`,
        error_26504:`Solicitud fallida (código de error: 26504). Vuelva a intentarlo`,
        error_26505:`El correo electrónico no se ha registrado, <a href="/register">regístrelo primero</a>`,
        error_26506:`El correo electrónico ha sido verificado.`,
        error_26507:`Solicitud fallida (código de error: 26507). Vuelva a intentarlo`,
        error_26508:`Verificación fallida (código de error: 26508), vuelva a intentarlo`,
        error_26509:`Solicitud fallida (código de error: 26509), vuelva a intentarlo`,
        error_26510:`Código de error: 26510, contáctenos`,
        error_26601:`Código de error: 26601, contáctenos`,
        error_26602:`Código de error: 26602, contáctenos`,
        error_26603:`Solicitud fallida (código de error: 26603). Vuelva a intentarlo`,
        error_26604:`Código de error: 26604, contáctenos`,
        error_26605:`Código de error: 26605, contáctenos`,
        error_26701:`Código de error: 26701, contáctenos`,
        error_26702:`Solicitud fallida (código de error: 26702). Vuelva a intentarlo`,
        error_26703:`Código de error: 26703, contáctenos`,
        error_26704:`Código de error: 26704, contáctenos`,
        error_26705:`Espere a iniciar sesión (código de error: 26705). Vuelva a intentarlo`,
        no_cookie:`Ha activado la función Bloquear todas las cookies en su navegador, por lo que no puede iniciar sesión. Vaya a Configuración para marcar la casilla Permitir todas las cookies.`,
        error_26801:`Código de error: 26801, contáctenos`,
        error_26802:`Código de error: 26802, contáctenos`,
        error_26803:`Solicitud fallida (código de error: 26803). Vuelva a intentarlo`,
        error_26804:`Solicitud fallida (código de error: 26804). Vuelva a intentarlo`,
        error_order:`Solicitud fallida (código de error: 27098), inténtalo de nuevo.`,
        error_order1:`La consulta del pedido está incompleta (código de error:`,
        error_order2:`）, actualice y vuelva a intentarlo.`,
        modify_email_title:`Modificar correo electrónico`,
        modify_email_info:`Puede utilizar el correo electrónico modificado para iniciar sesión en su cuenta.`,
        images_per:`Imágenes por`,
        error_26101:`Error: 26101. Por favor contáctenos`,
        error_26102:`Error: 26102. Por favor contáctenos`,
        error_26103:`Solicitud fallida (código de error: 26103). Por favor, inténtalo de nuevo`,
        error_26104:`Código de error: 26104, vuelva a intentarlo`,
        error_26105:`Código de error: 26105, vuelva a intentarlo`,
        error_26106:`Error al eliminar (código de error: 26106). Inténtalo de nuevo`,
        error_26201:`Error: 26201. Por favor contáctenos`,
        error_26202:`Solicitud fallida (código de error: 26202). Por favor, inténtalo de nuevo`,
        error_26001:`Error: 26001. Por favor contáctenos`,
        error_26002:`Error: 26002. Por favor contáctenos`,
        error_26003:`Error: 26003. Por favor contáctenos`,
        error_26004:`Error: 26004. Por favor contáctenos`,
        error_26005:`Solicitud fallida (código de error: 26005). Por favor, inténtalo de nuevo`,
        error_26006:`Código de error: 26006, vuelva a intentarlo`,
        error_26008:`Error: 26008. Por favor contáctenos`,
        go_to_the_home_page:`Ir a la pagina principal`,
        error_27101:`Solicitud fallida (código de error: 27101). Vuelva a intentarlo`,
        error_27201:`Código de error: 27201, contáctenos`,
        error_27202:`Código de error: 27202, vuelva a intentarlo`,
        error_27203:`Solicitud fallida (código de error: 27203). Vuelva a intentarlo`,
        error_27204:`Código no válido (código de error: 27204).`,
        error_27205:`Solicitud fallida (código de error: 27205). Vuelva a intentarlo`,
        error_27206:`Solicitud fallida (código de error: 27206). Vuelva a intentarlo`,
        error_27207:`Solicitud fallida (código de error: 27207). Vuelva a intentarlo`,
        no_history_found:`¡No has usado ninguna herramienta! <a href="/">Actualizar</a> o <a href="https://www.eassiy.com/es/">Ir al sitio web oficial</a>`,
        error_25301:`Error: 25301. Por favor contáctenos`,
        error_25302:`Error: 25302. Por favor contáctenos`,
        error_25303:`Solicitud fallida (código de error: 25303). Inténtalo de nuevo`,
        error_25304:`Solicitud fallida (código de error: 25304). Inténtalo de nuevo`,
        error_25305:`Solicitud fallida (código de error: 25305). Inténtalo de nuevo`,
        error_25306:`Solicitud fallida (código de error: 25306). Inténtalo de nuevo`,
        image_upscaler_p:`Beneficios de la descarga de imágenes sin marca de agua:`,
    },
    'zh-cn':{
        overtime_1:`错误代码：`,
        overtime_2:`，请重试`,
        isnetwork:`互联网错误。请检查并重试`,
        email_placeholder:`电子邮件`,
        email_empty:`请输入邮箱`,
        email_not_valid:`电子邮件无效`,
        email_not_valid_1:`请输入您的电子邮件`,
        email_not_valid_2:`电子邮件无效，请使用其他地址。`,
        email_not_valid_3:`没有电子邮件输入`,
        password_placeholder:`密码`,
        password_empty:`请输入密码`,
        password_not_valid:`帐户或密码不正确`,
        password_not_valid_1:`需要8个字符以上的密码`,
        password_not_valid_2:`请创建密码`,
        password_placeholder_1:`创建你的密码`,
        password_placeholder_2:`确认你的密码`,
        password_placeholder_3:`创建新密码`,
        password_placeholder_4:`确认新密码`,
        password_placeholder_5:`输入旧密码`,
        copy_password_empty:`请确认密码`,
        copy_password_not_valid:`请确认您的密码`,
        copy_passwords_inconsistent:`您的密码确认不匹配`,
        code_empty:`请输入验证码`,
        code_not_valid:`无效验证码`,
        code_placeholder:`验证码`,
        not_received_code:`如果您的邮箱长时间未收到验证码，请重新获取验证码。`,
        get_first_code:`请先获取验证码。`,
        connect_your_email_placeholder:`连接您的电子邮件`,
        last_name_placeholder:`请输入您的姓氏`,
        first_name_placeholder:`请输入您的名字`,
        address_placeholder:`请输入您的地址`,
        no_code_text:`我们已发送验证码。请输入您的验证码。<span class='tips'>没有收到验证码？`,
        no_code_text_1:`1.请确保邮箱地址有效且可以接收邮件。`,
        no_code_text_2:`2.由于系统自动发送的邮件，可能会被标记为垃圾邮件或垃圾邮件。请检查电子邮件是否在垃圾箱文件夹中。`,
        no_code_text_3:`3.无法解决您的问题？`,
        no_code_text_3_span:`然后点击这里联系我们。`,
        order_no:`您还没有购买任何产品。任何问题，请<a href="https://www.eassiy.com/zh-CN/buy/background-remover.html">立即购买</a>或<a href="https://www.eassiy.com/zh-CN/support/">联系我们</a>`,
        error_24901:`当前帐户没有电子邮件链接，无法找到订单。请连接电子邮件。`,
        user_guide:`使用指南>>`,
        download:`下载`,
        order_number:`订单号`,
        Refund:`退款`,
        Disabled:`已禁用`,
        Normal:`普通的`,
        Modify:`调整`,
        Modify_1:`修改>>`,
        Connect:`连接`,
        unlink_success:`成功解除关联`,
        connect_success:`连接成功`,
        feedback_title:`感谢您的反馈意见。请留下您的问题，我们将在 24 小时内回复您。`,
        feedback_thank_you:`谢谢！<br />您的反馈已成功提交。`,
        feedback_email:`在此处输入您的电子邮件！`,
        feedback_content:`在这里留下您遇到的任何问题或建议。`,
        feedback_submit:`提交`,
        form_contents:`您尚未输入任何说明。请输入并再次提交。`,
        old_password:`请输入旧密码`,
        new_password:`请创建一个新密码`,
        old_new_password:`新密码不能与旧密码相同`,
        incorrect_password:`密码错误`,
        delete_no:`立即删除`,
        Caps:`大写锁定已开启`,
        Get:`得到`,
        Done:`完毕`,
        error_20001:`错误：20001。请重新登录。`,
        error_20002:`错误：20002。请重新登录。`,
        error_20003:`错误：20003。请重新登录。`,
        error_20004:`请求失败（错误代码：20004）。请再试一次。`,
        error_20005:`登录会话已过期（错误：20005）。请重新登录。`,
        error_20006:`请求失败（错误代码：20006）。请再试一次。`,
        error_20007:`登录会话已过期（错误：20007）。请重新登录。`,
        error_20008:`登录会话已过期（错误：20008）。请重新登录。`,
        error_20009:`登录会话已过期（错误：20009）。请重新登录。`,
        error_20101:`请输入您的邮箱（错误代码：20101）`,
        error_20102:`电子邮件无效（错误代码：20102）`,
        error_20103:`请求失败（错误代码：20103）。请再试一次`,
        error_20104:`邮箱已被使用，请<a href="/login">登录</a>或注册新邮箱`,
        error_20105:`请求失败（错误代码：20105）。请再试一次`,
        error_20106:`发送邮件失败，请重试`,
        error_20201:`请输入您的邮箱（错误代码：20201）`,
        error_20202:`请输入您的密码（错误代码：20202）`,
        error_20203:`请输入验证码（错误码：20203）`,
        error_20204:`电子邮件无效（错误代码：20204）`,
        error_20205:`需要8个字符以上的密码（错误代码：20205）`,
        error_20206:`请求失败（错误代码：20206）。请再试一次`,
        error_20207:`无效验证码`,
        error_20208:`请求失败（错误代码：20208）。请再试一次`,
        error_20209:`请求失败（错误代码：20209）。请再试一次`,
        error_20301:`请输入您的邮箱（错误代码：20301）`,
        error_20302:`错误：20302。请联系我们`,
        error_20303:`电子邮件无效（错误代码：20303）`,
        error_20304:`请求失败（错误代码：20304）。请再试一次`,
        error_20305:`帐户不存在。请重新输入或先<a href="/register">创造它</a>。`,
        error_20306:`还没有密码。使用<a href="/passwordless-login">无密码登录</a>或<a href="/create-password">设置密码</a>并登录。`,
        error_20308:`请求失败（错误代码：20308）。请再试一次`,
        error_20401:`注销失败（错误代码：20401）。请再试一次`,
        error_20501:`请输入您的邮箱（错误代码：20501）`,
        error_20502:`电子邮件无效（错误代码：20502）`,
        error_20503:`请求失败（错误代码：20503）。请再试一次`,
        error_20504:`无法发送电子邮件。请再试一次。`,
        error_20601:`请输入您的邮箱（错误代码：20601）`,
        error_20602:`请输入验证码（错误码：20602）`,
        error_20603:`电子邮件无效（错误代码：20603）`,
        error_20604:`请求失败（错误代码：20604）。请再试一次`,
        error_20606:`请求失败（错误代码：20606）。请再试一次`,
        error_20607:`请求失败（错误代码：20607）。请再试一次`,
        error_20608:`请求失败（错误代码：20608）。请再试一次`,
        error_20701:`请输入您的邮箱（错误代码：20701）`,
        error_20702:`电子邮件无效（错误代码：20702）`,
        error_20703:`请求失败（错误代码：20703）。请再试一次`,
        error_20704:`帐户不存在。请重新输入或先<a href="/register">创造它</a>。`,
        error_20705:`请求失败（错误代码：20705）。请再试一次`,
        error_20706:`无法发送电子邮件。请再试一次`,
        error_20801:`请输入您的邮箱（错误代码：20801）`,
        error_20802:`错误：20802。请联系我们`,
        error_20803:`请输入验证码（错误码：20803）`,
        error_20804:`电子邮件无效（错误代码：20804）`,
        error_20805:`需要8个字符以上的密码（错误代码：20805）`,
        error_20806:`请求失败（错误代码：20806）。请再试一次`,
        error_20808:`请求失败（错误代码：20808）。请再试一次`,
        error_20901:`请求失败（错误代码：20901）。请再试一次`,
        error_20902:`请求失败（错误代码：20902）。请再试一次`,
        error_21000:`更改已保存`,
        error_21001:`没有提交信息（错误代码：21001）`,
        error_21002:`请求失败（错误代码：21002）。请再试一次`,
        error_21101:`请输入您的邮箱（错误代码：21101）`,
        error_21102:`电子邮件无效（错误代码：21102）`,
        error_21103:`请求失败（错误代码：21103），请重试`,
        error_21104:`邮箱已连接，请使用新邮箱`,
        error_21105:`请求失败（错误代码：21105），请重试`,
        error_21106:`无法发送电子邮件。请再试一次`,
        error_21201:`请输入您的邮箱（错误代码：21201）`,
        error_21202:`请输入验证码（错误码：21202）`,
        error_21203:`电子邮件无效（错误代码：21203）`,
        error_21204:`错误：21204。请联系我们`,
        error_21205:`错误：21205。请联系我们`,
        error_21206:`需要8位以上的密码（错误代码：21206）`,
        error_21207:`请求失败（错误代码：21207）。请再试一次`,
        error_21209:`请求失败（错误代码：21209）。请再试一次`,
        error_21301:`请输入旧密码（错误代码：21301）`,
        error_21302:`请创建一个新密码（错误代码：21302）`,
        error_21303:`新密码不能与旧密码相同。 （错误：21303）`,
        error_21304:`需要8个字符以上的密码（错误代码：21304）`,
        error_21306:`请求失败（错误代码：21306）。请再试一次`,
        error_21402:`请求失败（错误代码：21402）。请再试一次`,
        error_21403:`发送验证码失败。请重新发送`,
        error_21500:`该帐户已被删除`,
        error_21501:`请输入验证码（错误码：21501）`,
        error_21502:`登录会话已过期（错误：21502）。请重新登录。`,
        error_21503:`请求失败（错误代码：21503）。请再试一次`,
        error_21505:`请求失败（错误代码：21505），请重试`,
        error_21601:`错误：20601。请联系我们`,
        error_21602:`验证无效（错误：20602）。请再试一次。`,
        error_21603:`错误：20603。请重试`,
        error_21604:`请求失败（错误代码：21604）。请再试一次`,
        error_21606:`请求失败（错误代码：21606）。请再试一次`,
        error_21611:`请求失败（错误代码：21611）。请再试一次`,
        error_21801:`错误：21801。请联系我们`,
        error_21802:`请求失败（错误：21802）。请再试一次`,
        error_21803:`错误：21803。请重试`,
        error_21804:`请求失败（错误代码：21804）。请再试一次`,
        error_21806:`错误：21806。请重试`,
        error_21807:`错误：21807。请联系我们`,
        error_21808:`错误：21808。请联系我们`,
        error_21809:`错误：21809。请联系我们`,
        error_21810:`错误：21810。请联系我们`,
        error_21811:`错误：21811。请联系我们`,
        error_21812:`错误：21812。请联系我们`,
        error_21813:`请求失败（错误代码：21813）。请再试一次`,
        error_21814:`错误：21814。请联系我们`,
        error_21815:`请求失败（错误代码：21815）。请再试一次`,
        error_21816:`错误：21816。请联系我们`,
        error_21817:`错误：21817。请联系我们`,
        error_21818:`错误：21818。请联系我们`,
        error_21819:`请求失败（错误代码：21819）。请再试一次`,
        error_21820:`错误：21820。请联系我们`,
        error_21821:`错误：21821。请联系我们`,
        error_21822:`错误：21822。请联系我们`,
        error_21823:`请求失败（错误代码：21823）。请再试一次`,
        error_21824:`请求失败（错误代码：21824）。请再试一次`,
        error_21825:`请求失败（错误代码：21825）。请再试一次`,
        error_21826:`请求失败（错误代码：21826）。请再试一次`,
        error_21901:`错误：21901。请联系我们`,
        error_21902:`请求失败（错误代码：21902）。请再试一次`,
        error_21903:`帐户状态已更改（错误代码：21903），请刷新页面并重试`,
        error_21904:`错误：21904。请重试`,
        error_21905:`错误：21905。请重试`,
        error_21906:`请求失败（错误代码：21906）。请再试一次`,
        error_21907:`Google 帐户已关联到其他帐户`,
        error_21908:`请求失败（错误代码：21908）。请再试一次`,
        error_22001:`请求失败（错误代码：22001）。请再试一次`,
        error_22002:`取消链接失败，没有额外登录`,
        error_22003:`请求失败（错误代码：22003）。请再试一次`,
        error_22101:`错误：22101。请联系我们`,
        error_22102:`帐户状态已更改（错误代码：22102），请刷新页面并重试`,
        error_22103:`请求失败（错误代码：22103）。请再试一次`,
        error_22104:`帐户状态已更改（错误代码：22104），请刷新页面并重试`,
        error_22105:`错误：22105。请重试`,
        error_22106:`错误：22106。请重试`,
        error_22107:`错误：22107。请联系我们`,
        error_22108:`请求失败（错误代码：22108）。请再试一次`,
        error_22201:`错误：22201。请联系我们`,
        error_22202:`验证无效（错误：22202）。请再试一次。`,
        error_22203:`错误：22203。请重试”`,
        error_22204:`请求失败（错误代码：22204）。请再试一次`,
        error_22206:`请求失败（错误代码：22206）。请再试一次`,
        error_22401:`错误：22401。请联系我们`,
        error_22402:`验证无效（错误：22402）。请再试一次。`,
        error_22403:`错误：22403。请重试`,
        error_22404:`请求失败（错误代码：22404）。请再试一次`,
        error_22405:`Facebook 帐户已链接到其他电子邮件`,
        error_22406:`错误：22406。请重试`,
        error_22407:`错误：22407。请联系我们`,
        error_22408:`错误：22408。请联系我们`,
        error_22409:`错误：22409。请联系我们`,
        error_22410:`错误：224010。请联系我们`,
        error_22411:`错误：224011。请联系我们`,
        error_22412:`错误：224012。请联系我们`,
        error_22413:`错误：22413。请联系我们`,
        error_22414:`请求失败（错误代码：22414）。请再试一次`,
        error_22415:`错误：22415。请联系我们`,
        error_22416:`错误：22416。请联系我们`,
        error_22417:`错误：22417。请联系我们`,
        error_22418:`请求失败（错误代码：22418）。请再试一次`,
        error_22419:`错误：22419。请联系我们`,
        error_22420:`错误：22420。请联系我们`,
        error_22421:`错误：22421。请联系我们`,
        error_22422:`请求失败（错误代码：22422）。请再试一次`,
        error_22423:`请求失败（错误代码：22423）。请再试一次`,
        error_22424:`请求失败（错误代码：22424）。请再试一次`,
        error_22425:`请求失败（错误代码：22425）。请再试一次`,
        error_20098:`错误代码：20098。如果您在隐私模式下浏览，请切换到普通模式并重试。`,
        error_22298:`对 Google 的请求失败（错误：22298）。请再试一次。`,
        error_22498:`请求 Facebook 失败（错误：22498）。请再试一次。`,
        error_24902:`请求失败（错误代码：24902）。请再试一次`,
        error_24903:`请求失败（错误代码：24903）。请再试一次`,
        error_24904:`请求失败（错误代码：24904）。请再试一次`,
        error_24905:`请求失败（错误代码：24905）。请再试一次`,
        login_title:`登录 Aiseesoft`,
        password_placeholder:`密码`,
        log_in:`登录`,
        no_account:`没有账户？`,
        create_it:`创造它`,
        or_log_in_with:`或登录`,
        passwordless_login:`无密码登录`,
        log_in_done:`登录完成`,
        three_rd_account_connect_info:`恭喜！您已成功登录。现在您可以连接一个用于将来登录的电子邮件帐户。`,
        connect_now:`立即连接`,
        see_my_account:`查看我的帐户`,
        three_rd_login_merge_account:`第三方账号邮箱已经注册，要直接用这个邮箱连接登录吗？`,
        connect_log_in:`连接和登录`,
        create_an_account:`创建一个帐户`,
        connect_account:`连接帐户`,
        connect_account_info:`连接后，您可以使用此帐户登录。`,
        back_to_log_in:`回到登入`,
        create_password:`创建密码`,
        create_now:`立即创建`,
        password_login_subtitle:`使用电子邮件无密码登录`,
        account_login:`帐号登录`,
        rights:`创建此帐户即表示您同意<a href="https://www.eassiy.com/zh-CN/terms.html">服务条款</a>和<a href="https://www.eassiy.com/zh-CN/privacy.html">隐私政策</a>`,
        passwordless_login_done:`无密码登录完成`,
        passwordless_login_info:`恭喜，您已成功完成无密码登录。您可以为此帐户创建密码，并在以后使用该帐户和密码登录。 <a href="/create-password" style="display: initial;">立即创建</a>`,
        sign_up:`报名`,
        register_info:`创建您的帐户`,
        reset_now:`立即重置`,
        forgot_password:`忘记密码`,
        reset_password_subtitle:`使用您的帐户电子邮件重置密码`,
        plan_products:`计划和产品`,
        nick_name:`用户名`,
        email:`电子邮件`,
        my_products:`我的产品`,
        my_orders:`我的订单`,
        unlink:`取消链接`,
        link:`关联`,
        connected_accounts:`关联账户`,
        last_name:`姓`,
        first_name:`名`,
        Gender:`性别`,
        Birth:`出生`,
        Month:`月`,
        Year:`年`,
        Country_Region:`国家/地区`,
        Address:`地址`,
        Save:`节省`,
        Date:`日期`,
        Male:`男性`,
        Female:`女性`,
        Unspecified:`未指定`,
        Security:`安全`,
        change_password:`更改密码`,
        change_now:`立即更改`,
        connect_email:`连接电子邮件`,
        delete_account:`删除帐户`,
        delete_account_info:`当您的帐户被删除时，与您的帐户相关联的计划和产品中的所有数据都将被永久删除，并且您可能无法恢复这些数据。我们建议您谨慎行事。`,
        Delete:`删除`,
        Logout:`登出`,
        my_profile:`我的简历`,
        guides_faqs:`指南和常见问题`,
        More:`更多的`,
        guides:`指南`,
        register:`登记`,
        hot_faq:`热门常见问题`,
        Contents:`内容：`,
        contact_us:`联系我们>>`,
        plan:`计划`,
        unregistered:`未注册`,
        buy_more:`买多点`,
        buy_again:`再买`,
        buy_now:`立即购买`,
        free_no_limit:`免费且无限制`,
        expired:`已到期`,
        lifetime:`寿命`,
        remain:`保持`,
        day_s:`天）`,
        error_24801:`请求失败（错误代码：24801）。请再试一次`,
        no_app_found:`没有找到应用！<a href="/">刷新</a>或<a href="https://www.eassiy.com/zh-CN/">去官网</a>`,
        get_more:`获取更多>>`,
        edit_photo:`编辑照片`,
        select_photo:`选择照片`,
        change_photo:`更改照片`,
        cancel:`取消`,
        hide_password:`隐藏密码`,
        show_password:`显示密码`,
        zoom_in:`放大`,
        zoom_out:`缩小`,
        rotate:`旋转`,
        horizontal_flip:`水平翻转`,
        vertical_flip:`垂直翻转`,
        country:`国家`,
        country_1:`选择您的国家/地区`,
        country_2:`奥兰岛`,
        country_3:`阿富汗`,
        country_4:`阿尔巴尼亚`,
        country_5:`阿尔及利亚`,
        country_6:`美属萨摩亚`,
        country_7:`安道尔`,
        country_8:`安哥拉`,
        country_9:`安圭拉`,
        country_10:`南极洲`,
        country_11:`安提瓜和巴布达`,
        country_12:`阿根廷`,
        country_13:`亚美尼亚`,
        country_14:`阿鲁巴岛`,
        country_15:`澳大利亚`,
        country_16:`奥地利`,
        country_17:`阿塞拜疆`,
        country_18:`巴林`,
        country_19:`孟加拉国`,
        country_20:`巴巴多斯`,
        country_21:`白俄罗斯`,
        country_22:`比利时`,
        country_23:`伯利兹`,
        country_24:`贝宁`,
        country_25:`百慕大`,
        country_26:`不丹`,
        country_27:`玻利维亚`,
        country_28:`波斯尼亚和黑塞哥维那`,
        country_29:`博茨瓦纳`,
        country_30:`布韦岛`,
        country_31:`巴西`,
        country_32:`英属印度洋领地`,
        country_33:`英属维尔京群岛`,
        country_34:`文莱`,
        country_35:`保加利亚`,
        country_36:`布基纳`,
        country_37:`布隆迪`,
        country_38:`柬埔寨`,
        country_39:`喀麦隆`,
        country_40:`加拿大`,
        country_41:`佛得角`,
        country_42:`加勒比海荷兰`,
        country_43:`开曼群岛`,
        country_44:`中非共和国`,
        country_45:`乍得`,
        country_46:`智利`,
        country_47:`中国`,
        country_48:`圣诞岛`,
        country_49:`科科斯（基林）群岛`,
        country_50:`哥伦比亚`,
        country_51:`库克群岛`,
        country_52:`哥斯达黎加`,
        country_53:`“科特迪瓦”`,
        country_54:`克罗地亚`,
        country_55:`古巴`,
        country_56:`塞浦路斯`,
        country_57:`捷克共和国`,
        country_58:`刚果民主共和国`,
        country_59:`丹麦`,
        country_60:`吉布提`,
        country_61:`多米尼加`,
        country_62:`多明尼加共和国`,
        country_63:`厄瓜多尔`,
        country_64:`埃及`,
        country_65:`萨尔瓦多`,
        country_66:`赤道几内亚`,
        country_67:`厄立特里亚`,
        country_68:`爱沙尼亚`,
        country_69:`埃塞俄比亚`,
        country_70:`福克兰群岛`,
        country_71:`法罗群岛`,
        country_72:`密克罗尼西亚联邦`,
        country_73:`斐济`,
        country_74:`芬兰`,
        country_75:`法国`,
        country_76:`法属圭亚那`,
        country_77:`法属波利尼西亚`,
        country_78:`法属南部领地`,
        country_79:`加蓬`,
        country_80:`冈比亚`,
        country_81:`乔治亚州`,
        country_82:`德国`,
        country_83:`加纳`,
        country_84:`直布罗陀`,
        country_85:`英国（英国；英格兰）`,
        country_86:`希腊`,
        country_87:`格陵兰`,
        country_88:`格林纳达`,
        country_89:`瓜德罗普岛`,
        country_90:`关岛`,
        country_91:`危地马拉`,
        country_92:`根西岛`,
        country_93:`几内亚`,
        country_94:`几内亚比绍`,
        country_95:`圭亚那`,
        country_96:`海地`,
        country_97:`赫德岛和麦克唐纳群岛`,
        country_98:`洪都拉斯`,
        country_99:`香港`,
        country_100:`匈牙利`,
        country_101:`冰岛`,
        country_102:`印度`,
        country_103:`印度尼西亚`,
        country_104:`伊朗`,
        country_105:`伊拉克`,
        country_106:`爱尔兰`,
        country_107:`马恩岛`,
        country_108:`以色列`,
        country_109:`意大利`,
        country_110:`牙买加`,
        country_111:`日本`,
        country_112:`球衣`,
        country_113:`约旦`,
        country_114:`哈萨克斯坦`,
        country_115:`肯尼亚`,
        country_116:`基里巴斯`,
        country_117:`科威特`,
        country_118:`吉尔吉斯斯坦`,
        country_119:`老挝`,
        country_120:`拉脱维亚`,
        country_121:`黎巴嫩`,
        country_122:`莱索托`,
        country_123:`利比里亚`,
        country_124:`利比亚`,
        country_125:`列支敦士登`,
        country_126:`立陶宛`,
        country_127:`卢森堡`,
        country_128:`澳门`,
        country_129:`马达加斯加`,
        country_130:`马拉维`,
        country_131:`马来西亚`,
        country_132:`马尔代夫`,
        country_133:`马里`,
        country_134:`马耳他`,
        country_135:`马绍尔群岛`,
        country_136:`马提尼克`,
        country_137:`毛里塔尼亚`,
        country_138:`毛里求斯`,
        country_139:`马约特岛`,
        country_140:`墨西哥`,
        country_141:`摩尔多瓦`,
        country_142:`摩纳哥`,
        country_143:`蒙古`,
        country_144:`黑山`,
        country_145:`蒙特塞拉特`,
        country_146:`摩洛哥`,
        country_147:`莫桑比克`,
        country_148:`缅甸（缅甸）`,
        country_149:`纳米比亚`,
        country_150:`瑙鲁`,
        country_151:`尼泊尔`,
        country_152:`荷兰`,
        country_153:`新喀里多尼亚`,
        country_154:`新西兰`,
        country_155:`尼加拉瓜`,
        country_156:`尼日尔`,
        country_157:`尼日利亚`,
        country_158:`纽埃`,
        country_159:`诺福克岛`,
        country_160:`北朝鲜`,
        country_161:`北马里亚纳群岛`,
        country_162:`挪威`,
        country_163:`阿曼`,
        country_164:`巴基斯坦`,
        country_165:`帕劳`,
        country_166:`巴勒斯坦领土`,
        country_167:`巴拿马`,
        country_168:`巴布亚新几内亚`,
        country_169:`巴拉圭`,
        country_170:`秘鲁`,
        country_171:`皮特凯恩群岛`,
        country_172:`波兰`,
        country_173:`葡萄牙`,
        country_174:`波多黎各`,
        country_175:`卡塔尔`,
        country_176:`马其顿共和国 (FYROM)`,
        country_177:`刚果共和国`,
        country_178:`团圆`,
        country_179:`罗马尼亚`,
        country_180:`俄罗斯联邦`,
        country_181:`卢旺达`,
        country_182:`圣巴泰勒米`,
        country_183:`圣马丁（法国）`,
        country_184:`圣皮埃尔和密克隆`,
        country_185:`萨摩亚`,
        country_186:`圣马力诺`,
        country_187:`圣多美和普林西比`,
        country_188:`沙特阿拉伯`,
        country_189:`塞内加尔`,
        country_190:`塞尔维亚`,
        country_191:`塞舌尔`,
        country_192:`塞拉利昂`,
        country_193:`新加坡`,
        country_194:`斯洛伐克`,
        country_195:`斯洛文尼亚`,
        country_196:`所罗门群岛`,
        country_197:`索马里`,
        country_198:`南非`,
        country_199:`南乔治亚岛和南桑威奇岛`,
        country_200:`韩国`,
        country_201:`南苏丹`,
        country_202:`西班牙`,
        country_203:`斯里兰卡`,
        country_204:`圣赫勒拿岛和属地`,
        country_205:`圣基茨和尼维斯`,
        country_206:`圣卢西亚`,
        country_207:`圣文森特和格林纳丁斯`,
        country_208:`苏丹`,
        country_209:`苏里南`,
        country_210:`斯威士兰`,
        country_211:`瑞典`,
        country_212:`瑞士`,
        country_213:`叙利亚`,
        country_214:`台湾`,
        country_215:`塔吉克斯坦`,
        country_216:`坦桑尼亚`,
        country_217:`模板：国家数据 SJM Svalbard`,
        country_218:`泰国`,
        country_219:`巴哈马`,
        country_220:`科摩罗`,
        country_221:`菲律宾人`,
        country_222:`东帝汶（东帝汶）`,
        country_223:`多哥`,
        country_224:`托克劳`,
        country_225:`汤加`,
        country_226:`特立尼达和多巴哥`,
        country_227:`突尼斯`,
        country_228:`火鸡`,
        country_229:`土库曼斯坦`,
        country_230:`特克斯和凯科斯群岛`,
        country_231:`图瓦卢`,
        country_232:`乌干达`,
        country_233:`乌克兰`,
        country_234:`阿拉伯联合酋长国`,
        country_235:`美国本土外小岛屿`,
        country_236:`美利坚合众国（美国）`,
        country_237:`美属维尔京群岛`,
        country_238:`乌拉圭`,
        country_239:`乌兹别克斯坦`,
        country_240:`瓦努阿图`,
        country_241:`梵蒂冈城（教廷）`,
        country_242:`委内瑞拉`,
        country_243:`越南`,
        country_244:`瓦利斯和富图纳`,
        country_245:`西撒哈拉`,
        country_246:`也门`,
        country_247:`赞比亚`,
        country_248:`津巴布韦`,
        google_login:`使用 Google 登录`,
        footer:`版权所有 © 2023 Aiseesoft Studio。版权所有。`,
        State:`状态`,
        Activation_code:`激活码`,
        Question:`列出您已登录的所有应用程序`,
        Copy_complete:`复制完成`,
        store:`店铺`,
        resource:`资源`,
        support:`支持`,
        video_converter:`视频转换器`,
        multimedia:`多媒体`,
        data_recovery:`数据恢复`,
        utility:`公用事业`,
        free_tools:`免费工具`,
        for_windows:`对于 Windows`,
        for_mac:`对于 Mac`,
        pdf_solutions:`PDF解决方案`,
        data_manager:`数据管理器`,
        other_tools:`其他工具`,
        free_online_solutions:`免费在线解决方案`,
        free_online_tools:`免费在线工具`,
        desktop_freeware:`桌面免费软件`,
        product_1:`视频转换器终极版`,
        product_2:`总视频转换器`,
        product_3:`MTS 转换器`,
        product_4:`4K转换器`,
        product_5:`MXF 转换器`,
        product_6:`3D转换器`,
        product_7:`Mac Video Converter Ultimate`,
        product_8:`Mac 视频转换器`,
        product_9:`适用于 Mac 的 MTS 转换器`,
        product_10:`适用于 Mac 的 4K 转换器`,
        product_11:`适用于 Mac 的 MXF 转换器`,
        product_12:`适用于 Mac 的 3D 转换器`,
        product_13:`蓝光播放器`,
        product_14:`视频增强器`,
        product_15:`视频编辑`,
        product_16:`幻灯片制作者`,
        product_17:`蓝光创作者`,
        product_18:`Burnova - DVD Creator Pro`,
        product_19:`DVD 创作者`,
        product_20:`Mac 蓝光播放器`,
        product_21:`Mac 视频增强器`,
        product_22:`Mac 视频编辑器`,
        product_23:`适用于 Mac 的 DVD 制作器`,
        product_24:`适用于 Mac 的 MP4 转换器`,
        product_25:`视窗数据恢复`,
        product_26:`FoneLab iPhone 数据恢复`,
        product_27:`FoneLab iOS 系统恢复`,
        product_28:`FoneLab iOS 数据备份 &amp;恢复`,
        product_29:`FoneLab Android 数据恢复`,
        product_30:`破碎的Android数据提取`,
        product_31:`Android 数据备份 &amp;恢复`,
        product_32:`Mac 数据恢复`,
        product_33:`Mac FoneLab iPhone 数据恢复`,
        product_34:`Mac FoneLab iOS 系统恢复`,
        product_35:`Mac FoneLab iOS 数据备份 &amp;恢复`,
        product_36:`Mac FoneLab Android 数据恢复`,
        product_37:`Mac Android 数据备份 &amp;恢复`,
        product_38:`PDF Converter Ultimate`,
        product_39:`适用于 Mac 的 PDF Converter Ultimate`,
        product_40:`PDF合并`,
        product_41:`Mac PDF 到 ePub 转换器`,
        product_42:`Mac 清洁器`,
        product_43:`FoneTrans`,
        product_44:`适用于 iOS 的 WhatsApp 传输`,
        product_45:`手机同步`,
        product_46:`FoneEraser`,
        product_47:`iPhone清洁剂`,
        product_48:`PPT到视频转换器`,
        product_49:`HEIC转换器`,
        product_50:`iPhone 解锁器`,
        product_51:`免费在线视频转换器`,
        product_52:`免费在线音频转换器`,
        product_53:`免费在线视频压缩器`,
        product_54:`免费在线视频合并`,
        product_55:`免费 HEIC 转换器`,
        product_56:`免费图像压缩器`,
        product_57:`免费水印去除剂`,
        product_58:`免费在线 PDF 压缩器`,
        product_59:`免费在线图像升频器`,
        product_60:`在线免费背景卸妆`,
        product_61:`免费在线图像转换器`,
        product_62:`在线免费 PDF PNG 转换器`,
        product_63:`免费视频转换器`,
        product_64:`免费视频转 GIF 转换器`,
        product_65:`免费安卓数据恢复`,
        product_66:`幻灯片制作工具`,
        product_67:`免费视频编辑器`,
        product_68:`免费的 PDF 查看器`,
        product_69:`屏幕录像机`,
        product_70:`Mac 屏幕录像机`,
        product_71:`免费在线屏幕录像机`,
        product_72:`免费在线录音机`,  
        product_73:`手机镜像`,
        product_74:`AnyCoord - 位置转换器`,
        product_75:`iPhone 密码管理器`,
        product_76:`免费在线音频压缩器`,
        product_77:`免费在线视频修剪器`,
        product_78:`免费在线视频裁剪器`,
        product_79:`免费在线视频旋转器`,
        product_80:`免费在线视频 Looper`,
        product_81:`免费 PDF JPG 在线转换器`,
        product_81:`免费的 JPG Word 在线转换器`,      
        category_1:`照片编辑`,
        category_2:`视频编辑`,
        category_3:`橡皮`,
        category_4:`媒体记录器`,
        posted_by:`发表者`,
        to:`到`,
        author_1:`阿什莉·梅`,
        author_2:`珍妮瑞恩`,
        author_3:`妮可琼斯`,
        author_4:`阿曼达·布朗`,
        article_1:`如何有效去除图片中的文字`,
        article_2:`如何在不降低质量的情况下将图像大小（以 KB 为单位）增加到 MB`,
        article_3:`如何在 Windows 和 Mac 上删除 Getty Images 水印`,
        article_4:`免费在线将低分辨率图像转换为高分辨率的最佳方法`,
        article_5:`抖音指南 |这是在 TikTok 上制作视频拼贴画的方法`,
        article_6:`5 种简单的方法来组合你应该知道的 VOB 文件`,
        article_7:`如何轻松升级视频并获得更高的分辨率`,
        article_8:`如何在 MacBook Pro/iMac/MacBook 等设备上合并 MP4 文件`,
        article_9:`在 iPhone 上删除购买历史记录的 2 种简单方法 [已解决]`,
        article_10:`如何从聊天和记忆中永久删除 Snapchat 图片`,
        article_11:`如何在 iPhone/iPad 上删除 Spotlight 搜索建议和历史记录`,
        article_12:`[已解决] 如何使用 3 种简单方法清除 iPhone 上的 Instagram 缓存`,
        article_13:`[已解决] 如何修复 QuickTime Player 在 Mac 上无法运行`,
        article_14:`如何在 Mac 上录制系统音频（Big Sur/Catalina/Mojave ...）`,
        article_15:`修复 PowerPoint 屏幕录制不起作用的有效方法`,
        article_16:`如何使用 VLC 录制音频 [已解决]`,
        faq_title_1:`1.如何修复4104错误？`,
        faq_title_2:`2. 退款申请获得批准后，我什么时候可以拿回我的钱？`,
        faq_title_3:`3. 什么是“1年订阅”以及如何关闭“自动续订服务”？`,
        faq_title_4:`4. Aiseesoft 产品下单后可以取消订单吗？`,
        faq_title_5:`5. 如何向您提交评论？`,
        faq_info_1:`这是由于您获得的许可证有限。我们采用Single License，以防注册码被盗，防止注册码被违规使用。因此，您获得的注册代码是单一许可证，只能在一台计算机上使用。如果您在多台计算机上使用它，则会出现错误 4104。在我们为您重置之前，您无法使用它。`,
        faq_info_2:`如果您更换或重新安装计算机，我们可以为您重置。请联系客服：<a href="mailto:support@eassiy.com">support@eassiy.com</a> 为您申请重置注册码。`,
        faq_info_3:`一旦您的退款请求获得批准，通常需要 3 到 7 个工作日才能在您的帐户中显示退款。有时在假期期间，可能需要更长的时间，但预计不会超过 10 天。如果您在批准后 20 天仍未收到退款，请通过 <a href="mailto:support@eassiy.com">support@eassiy.com</a> 联系我们以查看退款情况。`,
        faq_info_4:`自动续费服务是指，如果您购买了 1 年订阅，则支付平台将在产品许可证到期时自动收取该金额（包括适用税费）。但是付款平台（Swreg 或 2Checkout）会发信提醒您订单将在到期前 7 天到期。该电子邮件还将包含有关如何关闭“自动续订服务”的说明。`,
        faq_info_5:`由于订单是自动处理的，所以不允许取消订单。此外，免费试用版将使您更了解Aiseesoft 程序，并在购买前检查它是否能满足您的需求。`,
        faq_info_6:`如果您确实想获得退款，请查看我们的<a href="https://www.eassiy.com/zh-CN/refund-policy.html">退款政策</a>。`,
        faq_info_7:`感谢您抽出时间和关注向我们提交评论。联系我们 (<a href="mailto:support@eassiy.com">support@eassiy.com</a>) 提交您的评论，我们将认真考虑您对我们产品更新的评论。`,
        change_password_success:`密码修改成功`,
        successful_login_title:`登录成功`,
        product_page:`产品页面>>`,
        successful_login_info:`登录完成。请关闭当前页面并返回原始选项卡以继续以下过程。当前页面将在 5 秒后自动关闭。如果自动关闭或单击“完成”按钮关闭失败，请直接关闭此选项卡。`,
        successful_login_info_firefox:`登录完成。请关闭当前页面并返回原始选项卡以继续以下过程。`,
        my_account:`我的帐户`,
        my_history:`我的历史`,
        remove_watermark:`去除水印`,
        no_history:`没有历史`,
        history_all:`全选`,
        history_open:`打开`,
        history_down:`下载`,
        history_delete:`删除`,
        history_clear:`清除无效`,
        images:`图片）`,
        use_this_function:`使用此功能>>`,
        hd_downloading:`高清原图下载好处：`,
        lifetimeRemaining:`剩余寿命`,
        Remaining:`其余的`,
        email_verification:`电子邮件验证`,
        email_verification_info:`我们已将验证信息发送至您的邮箱<span class="email"></span>，请完成验证。验证后，福利将自动同步。`,
        wrong_email:`错误的电子邮件地址？`,
        click_here_to_modify:`点击这里修改`,
        get_more_help:`获得更多帮助？`,
        click_here:`点击这里`,
        email_verification_info_success:`恭喜您验证了您的电子邮件帐户。`,
        email_verification_info_error:`由于链接过期，验证失败。`,
        registration_succeeded:`注册成功`,
        registration_succeeded_info_1:`恭喜！您已成功注册。我们已将验证消息发送到您的电子邮件 <span class="email"></span>，请完成验证以使用此电子邮件的好处。`,
        registration_succeeded_info_2:`单击“完成”返回主页并使用该产品。`,
        registration_succeeded_info_3:`点击“完成”关闭当前页面，返回首页进行以下操作。如果关闭失败，请手动关闭选项卡。`,
        verify_email:`验证邮件`,
        registered_email_not_verified:`注册邮箱未验证，请立即验证。`,
        email_verification_time_1:`没有收到验证邮件吗？`,
        email_verification_time_2:`后`,
        email_verification_time_3:`点击这里重新发送`,
        error_26301:`错误代码：26301，请联系我们`,
        error_26302:`错误代码：26302，请联系我们`,
        error_26303:`电子邮件格式错误（错误代码：26303）。请重新输入`,
        error_26304:`密码建议8位以上（错误代码：26304）`,
        error_26305:`请求失败（错误代码：26305）。请重试`,
        error_26306:`邮箱已注册，请<a href="/login">去登录</a>`,
        error_26307:`请求失败（错误代码：26307）。请重试`,
        error_26308:`请求失败（错误代码：26308）。请重试`,
        error_26401:`错误代码：26401，请重试`,
        error_26402:`邮箱已验证（错误代码：26402），请重试`,
        error_26403:`请求失败（错误代码：26403）。请重试`,
        error_26404:`请求失败（错误代码：26404）。请重试`,
        error_26501:`错误代码：26501，请联系我们`,
        error_26502:`错误代码：26502，请联系我们`,
        error_26503:`电子邮件格式错误（错误代码：26503）。请重新输入`,
        error_26504:`请求失败（错误代码：26504）。请重试`,
        error_26505:`邮箱尚未注册，请<a href="/register">先注册</a>`,
        error_26506:`电子邮件已通过验证。`,
        error_26507:`请求失败（错误代码：26507）。请重试`,
        error_26508:`验证失败（错误码：26508），请重试`,
        error_26509:`请求失败（错误代码：26509），请重试`,
        error_26510:`错误代码：26510，请联系我们`,
        error_26601:`错误代码：26601，请联系我们`,
        error_26602:`错误代码：26602，请联系我们`,
        error_26603:`请求失败（错误代码：26603）。请重试`,
        error_26604:`错误代码：26604，请联系我们`,
        error_26605:`错误代码：26605，请联系我们`,
        error_26701:`错误代码：26701，请联系我们`,
        error_26702:`请求失败（错误代码：26702）。请重试`,
        error_26703:`错误代码：26703，请联系我们`,
        error_26704:`错误代码：26704，请联系我们`,
        error_26705:`等待登录（错误代码：26705）。请重试`,
        no_cookie:`您在浏览器中开启了阻止所有cookies功能，因此您无法登录。请进入设置，勾选允许所有cookies。`,
        error_26801:`错误代码：26801，请联系我们`,
        error_26802:`错误代码：26802，请联系我们`,
        error_26803:`请求失败（错误代码：26803）。请重试`,
        error_26804:`请求失败（错误代码：26804）。请重试`,
        error_order:`请求失败（错误代码：27098），请重试！`,
        error_order1:`订单查询不完整（错误码：`,
        error_order2:`），请刷新并重试。`,
        modify_email_title:`修改邮箱`,
        modify_email_info:`您可以使用修改后的电子邮件登录您的帐户。`,
        images_per:`每张图片`,
        error_26101:`错误：26101。请联系我们`,
        error_26102:`错误：26102。请联系我们`,
        error_26103:`请求失败（错误代码：26103）。请再试一次`,
        error_26104:`错误代码：26104，请重试`,
        error_26105:`错误代码：26105，请重试`,
        error_26106:`删除失败（错误代码：26106）。请再试一次`,
        error_26201:`错误：26201。请联系我们`,
        error_26202:`请求失败（错误代码：26202）。请再试一次`,
        error_26001:`错误：26001。请联系我们`,
        error_26002:`错误：26002。请联系我们`,
        error_26003:`错误：26003。请联系我们`,
        error_26004:`错误：26004。请联系我们`,
        error_26005:`请求失败（错误代码：26005）。请再试一次`,
        error_26006:`错误代码：26006，请重试`,
        error_26008:`错误：26008。请联系我们`,
        go_to_the_home_page:`转到主页`,
        error_27101:`请求失败（错误代码：27101）。请重试`,
        error_27201:`错误代码：27201，请联系我们`,
        error_27202:`错误代码：27202，请重试`,
        error_27203:`请求失败（错误代码：27203）。请重试`,
        error_27204:`无效代码（错误代码：27204）。`,
        error_27205:`请求失败（错误代码：27205）。请重试`,
        error_27206:`请求失败（错误代码：27206）。请重试`,
        error_27207:`请求失败（错误代码：27207）。请重试`,
        no_history_found:`你还没有使用任何工具！<a href="/">刷新</a>或<a href="https://www.eassiy.com/zh-CN/">去官网</a>`,
        error_25301:`错误：25301。请联系我们`,
        error_25302:`错误：25302。请联系我们`,
        error_25303:`请求失败（错误代码：25303）。请再试一次`,
        error_25304:`请求失败（错误代码：25304）。请再试一次`,
        error_25305:`请求失败（错误代码：25305）。请再试一次`,
        error_25306:`请求失败（错误代码：25306）。请再试一次`,
        image_upscaler_p:`无水印图片下载权益：`,
    },
};